import React, { useState, useRef, useEffect, useMemo } from "react";
import "./RiskContribution.scss";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import TagManager from "react-gtm-module";
import Loader from '../../Components/Loader/Loader';
import { addZeroes } from "../../Utilities/AddZeros";
import RiskContributionGraph from "../RiskContributionGraph/RiskContributionGraph";
import { FetchRiskContribution } from "../../Apis/FetchRiskContribution";
import { handleApiError } from "../../actions/notificationActions";
import { useDispatch, useSelector } from "react-redux";
import { toPng } from "html-to-image";
import { updatePdfData } from "../../actions/pdfDataActions";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import { customColumnSort } from "../../Utilities/CustomColumnSort";

function RiskContribution({
  portfolioId,
  isEmpty,
  isGeneratingPdf,
  activeTabLabel,
}) {
  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const divRef = useRef(null);
  const dispatch = useDispatch();

  const [show, setShow] = useState(true);
  const [bdopen, setBdOpen] = React.useState(true);
  const [riskCOntributionTableData, setRiskCOntributionTableData] =
    React.useState([]);
  const [riskCOntributionGraphData, setRiskCOntributionGraphData] =
    React.useState([]);
  const [riskPortfolioKeyList, setRiskPortfolioKeyList] = React.useState([]);
  const [PortfolioMarketValue, setPortfolioMarketValue] = React.useState(0);
  const [PortfolioRisk, setPortfolioRisk] = React.useState(0);
  const [HistoricalRisk, setHistoricalRisk] = React.useState(0);
  const [IndexRisk, setIndexRisk] = React.useState();
  const [totalIndividualRiskContribution, setTotalIndividualRiskContribution] =
    React.useState(0);
  const [selectedIndex, setSelectedIndex] = React.useState();
  const [selectedVixTicker, setSelectedVixTicker] = React.useState();
  const [maxNormalizedRisk, setMaxNormalizedRisk] = React.useState();
  const [minNormalizedRisk, setMinNormalizedRisk] = React.useState();
  const riskContributionData = useSelector(
    (state) => state.pdfData["Risk Contribution"]
  );

  const data = useMemo(
    () =>
      riskCOntributionTableData.map((value) => ({
        sr: value.sr,
        symbol: value.symbol,
        positionName: value.position_name,
        stockVolatility: value.stock_volatility.toFixed(2),
        marketValue: Number(
          addZeroes(Number(parseFloat(value.market_value).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        marketWeight:
          ((value.market_value / PortfolioMarketValue) * 100).toFixed(2) + "%",
        marketContribution: value.market_contribution,
        idiosyncraticContribution: value.idiosyncratic_portion,
        riskContributions: value.risk_contributions,
        diversifiedRisk: value.isolated_risk,
      })),
    [riskCOntributionTableData, PortfolioMarketValue]
  );

  const totalSums = riskCOntributionTableData.reduce(
    (acc, curr) => {
      acc.marketValue += curr.market_value;
      acc.marketWeight += (curr.market_value / PortfolioMarketValue) * 100;
      acc.marketContribution += curr.market_contribution;
      acc.idiosyncraticContribution += curr.idiosyncratic_portion;
      acc.totalRiskContribution += curr.risk_contributions;
      acc.diversifiedRisk += curr.isolated_risk;
      return acc;
    },
    {
      marketValue: 0,
      marketWeight: 0,
      marketContribution: 0,
      idiosyncraticContribution: 0,
      totalRiskContribution: 0,
      diversifiedRisk: 0,
    }
  );

  const columns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      { accessorKey: "symbol", header: "Symbol", footer: "Portfolio Total:", size: "130"},
      { accessorKey: "positionName", header: "Position Name", footer: "" },
      { accessorKey: "stockVolatility", header: "Stock Volatility", footer: "", size: "200", },
      {
        accessorKey: "marketValue",
        header: "Market Value",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'marketValue'),
        footer: () => 
          Number(
            addZeroes(Number(parseFloat(PortfolioMarketValue).toFixed(2)))
          ).toLocaleString("en-US", currObj),
          size: "170"
      },
      {
        accessorKey: "marketWeight",
        header: "Market Weight",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'marketWeight'),
        footer: "100%",
      },
      {
        accessorKey: "marketContribution",
        header: "Market Contribution",
        footer: totalSums.marketContribution.toFixed(3),
      },
      {
        accessorKey: "idiosyncraticContribution",
        header: "Idiosyncratic Contribution",
        footer: totalSums.idiosyncraticContribution.toFixed(4),
      },
      {
        accessorKey: "riskContributions",
        header: "Total Risk Contribution",
        footer: () => Number(parseFloat(PortfolioRisk).toFixed(3)),
      },
      {
        accessorKey: "diversifiedRisk",
        header: "Diversified Risk",
        footer: totalSums.diversifiedRisk.toFixed(3),
      },
    ],
    [PortfolioMarketValue, totalSums, PortfolioRisk]
  );

  const captureAndDispatchData = async (
    dataFlag,
    data,
    riskcontributiondata,
    marketRisk,
    portfolioRisk,
    totalportfoliovalue,
    vix_data
  ) => {
    if (dataFlag === "Data Available") {
      if (divRef.current) {
        await new Promise((resolve) => setTimeout(resolve, 1000));
        const image = await toPng(document.getElementById("divToPrint"));
        const pdf_data = {
          activeTab: "Risk Contribution",
          riskContriImgData: image,
          riskContributionTableData: riskcontributiondata,
          risk_contribution_headers: [
            "#",
            "SYMBOL",
            "POSITION NAME",
            "STOCK VOLATILITY",
            "MARKET VALUE",
            "MARKET WEIGHT",
            "MARKET CONTRIBUTION",
            "IDIOSYNCRATIC CONTRIBUTION",
            "TOTAL RISK CONTRIBUTION",
          ],
          totalPortfolioValue: totalportfoliovalue,
          marketRisk: marketRisk,
          portfolioRisk: portfolioRisk,
          totalPortfolioHoldings: riskcontributiondata.length,
        };
        dispatch(
          updatePdfData("Risk Contribution", {
            pdf: pdf_data,
            allData: { risk_data: data, vix_data: vix_data },
            portfolioid: portfolioId,
            dataFlag: dataFlag,
          })
        );
      }
    } else {
      dispatch(
        updatePdfData("Risk Contribution", {
          dataFlag: dataFlag,
          portfolioid: portfolioId,
        })
      );
    }
  };

  const setAPiData = (data, vix_all_data) => {
    if (data.status === 200) {
      const vix_data = vix_all_data["vix"]["6mo"]["closeprice"];
      const vix_value = parseFloat(vix_data[vix_data.length - 1]).toFixed(2);
      let lyst = [];
      let i = 1;
      let maxNormalizedRisk = 0;
      let minNormalizedRisk = 0;
      setRiskPortfolioKeyList(Object.keys(data.result));
      for (let [key, value] of Object.entries(
        data.result["risk_contribution"]
      )) {
        const normalizedRisk =
          (parseFloat(
            value["isolated_risk"] + parseFloat(value["risk_contributions"])
          ) /
            data.result.portfolio_risk) *
          100;
        const normalizedMarketRisk = Math.max(
          (parseFloat(value["market_contribution"]) /
            data.result["portfolio_risk"]) *
            100,
          0
        );
        minNormalizedRisk = Math.min(minNormalizedRisk, normalizedMarketRisk);
        maxNormalizedRisk = Math.max(maxNormalizedRisk, normalizedRisk);
        lyst.push({
          sr: i,
          symbol: key,
          position_name: value["Name"],
          market_value: value["Market Value"],
          stock_volatility: value["individual stock_volatility"],
          risk_contributions: parseFloat(value["risk_contributions"]),
          market_contribution: Math.max(
            parseFloat(value["market_contribution"]),
            0
          ),
          isolated_risk: parseFloat(value["isolated_risk"]),
          idiosyncratic_portion: parseFloat(value["idiosyncratic_portion"]),
          vix_value: vix_value,
          TradeDate: value["TradeDate"],
          normalizedMarketRisk: Math.max(
            (parseFloat(value["market_contribution"]) /
              data.result["portfolio_risk"]) *
              100,
            0
          ),
          normalizedIdioRisk:
            (parseFloat(value["risk_contributions"]) /
              data.result["portfolio_risk"]) *
            100,
          normalizedIsoRisk:
            (parseFloat(
              value["isolated_risk"] + parseFloat(value["risk_contributions"])
            ) /
              data.result["portfolio_risk"]) *
            100,
        });
        i++;
      }
      lyst.sort(
        (a, b) =>
          b.risk_contributions +
          b.idiosyncratic_portion -
          (a.risk_contributions + a.idiosyncratic_portion)
      );
      setRiskCOntributionTableData([...lyst]);
      setRiskCOntributionGraphData([...lyst.slice(0, 10)]);
      setMaxNormalizedRisk(maxNormalizedRisk);
      setPortfolioMarketValue(data.result["portfolio_market_value"]);
      setMinNormalizedRisk(minNormalizedRisk);
      setPortfolioRisk(data.result["portfolio_risk"]);
      setHistoricalRisk(data.result["historical_market_risk"]);
      setIndexRisk(vix_value);
      setTotalIndividualRiskContribution(
        data.result["total_individual_risk_contribution"]
      );
      setTimeout(() => {
        captureAndDispatchData(
          "Data Available",
          data,
          lyst,
          data.result["historical_market_risk"],
          data.result["portfolio_risk"],
          data.result["portfolio_market_value"],
          vix_all_data
        );
      }, 2000);
      const formattedVixTicker = `${data.result["vix_ticker"].slice(1)} [${
        data.result["selected_index"]
      } Volatility]`;
      setSelectedIndex(data.result["selected_index"]);
      setSelectedVixTicker(formattedVixTicker);
      TagManager.dataLayer({
        dataLayer: {
          event: "Risk Contribution",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
    } else {
      setTimeout(() => {
        captureAndDispatchData("Data Not Available", data);
      }, 2000);
    }
  };

  useEffect(() => {
    if (
      portfolioId &&
      !isEmpty &&
      (activeTabLabel === "Risk Contribution" || isGeneratingPdf)
    ) {
      setBdOpen(true);
      if (
        riskContributionData &&
        portfolioId === riskContributionData.portfolioid &&
        riskContributionData.dataFlag === "Data Available"
      ) {
        setAPiData(
          riskContributionData.allData.risk_data,
          riskContributionData.allData.vix_data
        );
        setBdOpen(false);
      } else {
        FetchRiskContribution(portfolioId).then((data) => {
          if (data.status === 200) {
            setAPiData(data, data.result["graph_data"]);
            setBdOpen(false);
          } else {
            handleApiError(data, dispatch);
            setAPiData(data);
            setBdOpen(false);
          }
        });
      }
    }
  }, [portfolioId]);

  return (
    <>
      {!isEmpty ? (
        <>
          {bdopen ? (
            <div className="loading-overlay">
              <Loader/>
            </div>
          ) : (
            <div className="drawercontent">
              <div id="divToPrint" className="GraphDiv" ref={divRef}>
                <div className="riskhead" id="graphHeader">
                  <h2>Top 10 Risk Contributions</h2>
                  <div className="riskstate">
                    <h3 id="portfoliorisk">{PortfolioRisk.toFixed(2)}</h3>
                    <div className="lblrisk">
                      Portfolio Volatility
                      <TooltipComponent id={"Portfolio Risk"} />
                    </div>
                  </div>
                  <div className="riskstate">
                    <h3 id="portfoliorisk">{HistoricalRisk.toFixed(2)}</h3>
                    <div className="lblrisk">
                      Index Volatility
                      <>
                        &nbsp;
                        <img
                          src="Assets/risk-info.png"
                          title={`Historical ${selectedIndex} Volatility`}
                        />
                      </>
                    </div>
                  </div>
                </div>
                <div id="xsx" className="riskchart">
                  <p className="chartlbl left">Annualized Volatility</p>
                  {riskCOntributionGraphData && (
                    <RiskContributionGraph
                      data={riskCOntributionGraphData}
                      maxNormalizedRisk={maxNormalizedRisk}
                      totals={{
                        marketContributionTotal: parseFloat(
                          totalSums.marketContribution.toFixed(3)
                        ),
                        idiosyncraticPortionTotal: parseFloat(
                          totalSums.idiosyncraticContribution.toFixed(4)
                        ),
                        isolatedRiskTotal: parseFloat(
                          totalSums.diversifiedRisk.toFixed(3)
                        ),
                      }}
                    />
                  )}
                  <p className="chartlbl right">Pct Of Portfolio Volatility</p>
                </div>
              </div>
              <div className="risk-contribution-table">
                <div className="portfolio-table contributio-table">
                  <div
                    id="portfolioriskdetails"
                    className="table_holder table_head ai-toggle-con m0i"
                  >
                    <h3 onClick={() => setShow(!show)} className="ai-toggle-ttl">
                      {show && <span className="minus">-</span>}{" "}
                      {!show && <span>+</span>}
                      <span className="tgltitle">Portfolio Details</span>                      
                    </h3>
                    {show && (
                      <>
                        <MaterialReactTable
                          columns={columns}
                          data={data}
                          enableStickyHeader
                          enableStickyFooter
                          enableBottomToolbar={false}
                          enableColumnActions={false}
                          enableDensityToggle={false}
                          enableColumnResizing
                          enableColumnOrdering
                          enableRowPinning
                          enablePagination={false}
                          enableFullScreenToggle={false}
                          muiTableContainerProps={{
                            sx: {
                              maxHeight: "400px",
                            },
                          }}
                          initialState={{
                            density: "compact",
                          }}
                          muiTableHeadCellProps={{
                            sx: {
                              backgroundColor: "white",
                              position: "sticky",
                              top: 0,
                              zIndex: 1,
                            },
                          }}
                          muiTableFootCellProps={{
                            sx: {
                              backgroundColor: "white",
                              position: "sticky",
                              bottom: 0,
                              zIndex: 1,
                            },
                          }}
                        />
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="drawercontent">
          <p>Portfolio is Empty. Please add stock to proceed</p>
        </div>
      )}
    </>
  );
}

export default RiskContribution;
