import React, { useState, useEffect } from 'react';
// import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import { useDispatch, useSelector } from 'react-redux';
import './ShieldRecommendation.scss';
import Spinner from '../Spinner/Spinner';
import { addZeroes } from "../../Utilities/AddZeros";
import { findClosest } from "../../Utilities/ClosestPercent";
// import { formatContract } from "../../Utilities/FormatContract";
import { GetMarketShield } from '../../Apis/GetMarketShield';
import { GetMarketShieldPlus } from '../../Apis/GetMarketShieldPlus';
// import { GetQuoteEngineData } from '../../Apis/GetQuoteEngineData';
import { GetAssetAllocationEquivalence } from '../../Apis/GetAssetAllocationEquivalenceData';
import AnalyticsDrawer from "../../Components/AnalyticsDrawer/AnalyticsDrawer";
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
// import TagManager from 'react-gtm-module';
import TooltipComponent from '../TooltipComponent/TooltipComponent';
import ShoppingCart from './ShoppingCart';
import DatePicker from 'react-datepicker';
import moment from 'moment';


function ShieldRecommendation() {

    const DRAWER_COMPONENTS = {
        Analytics: AnalyticsDrawer
    };
    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const portfolioId = drawerData.portfolioId;
    const isEmpty = drawerData.isEmpty;
    const [loadingState, setLoadingState] = useState({
        marketShield: false,
        marketShieldPlus: false,
        guaranteedShield: false
    });

    const [shields, setShields] = useState({
        marketShield: {
            protectionLevel: 90,
            protectionPeriod: moment().add(2, 'month').toDate(),
            assetAllocation: "90-10",
        },
        marketShieldPlus: {
            protectionLevel: 90,
            protectionPeriod: "1 Month",
            assetAllocation: "90-10"
        },
        guaranteedShield: {
            protectionLevel: 90,
            protectionPeriod: "1 Month",
            assetAllocation: "90-10"
        }
    });
    const [apiPeriod, setApiPeriod] = useState("");
    const [marketShieldData, setMarketShieldData] = useState([])
    const [marketShieldPlusData, setMarketShieldPlusData] = useState([])
    const [guaranteedShieldData, setGuaranteedShieldData] = useState([])
    const [marketShieldCart, setMarketShieldCart] = useState([])
    const [marketShieldPlusCart, setMarketShieldPlusCart] = useState([])
    const [shoppingFlag, setShoppingFlag] = useState(false)
    const [shieldPrice, setShieldPrice] = React.useState(0);
    const [selectedPercent, setSelectedPercent] = React.useState(0);
    const [allocationMode, setAllocationMode] = useState(false);
    const [allocationData, setAllocationData] = useState([]);
    const totalPremium = sessionStorage.getItem('totalPremium');
    console.log("totalPremium", totalPremium)
    const totalCost = sessionStorage.getItem('totalCost');
    const portfolioDetails = useSelector(state => state.portfolioDetails[portfolioId].details);
    const [dynamicProtectionLevels, setDynamicProtectionLevels] = useState({});
    



    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const periods_dict = {
        "1 Month": 30,
        "2 Months": 60,
        "3 Months": 91,
        "6 Months": 182,
        "Year": 365,
    };

    const periods_dict_backend = {
        "1 Month": "month",
        "2 Months": "month_2",
        "3 Months": "quarter",
        "6 Months": "half_year",
        "Year": "year",
    };
    const periods_dict_asset_allocation = {
        "1 Month": "Monthly",
        "2 Months": "Bimonthly",
        "3 Months": "Quarterly",
        "6 Months": "Half-yerly",
        "Year": "Yearly",
    };
    const levels_dict_backend = {
        80: "0.8",
        90: "0.9",
        85: "0.85",
        95: "0.95",
        100: "1.0",
    };
    // Generic function to update a shield's key based on shield type
    const updateShield = (shieldType, key, value) => {
        setShields(prevShields => ({
            ...prevShields,
            [shieldType]: {
                ...prevShields[shieldType],
                [key]: value
            }
        }));
    };

    function fetch_price(shield_data) {
        console.log(" shie", shield_data);
        try {
            let percent = findClosest(shield_data[periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]]],
                shields.guaranteedShield.protectionLevel);
            var shieldPercent = addZeroes(Number((100 - percent).toFixed(2).toString()));
            setSelectedPercent(shieldPercent);
            percent = (percent / 100).toFixed(2);
            let shieldprice = (shield_data[periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]]][shieldPercent]["shieldprice"]).toFixed(2);
            setShieldPrice(shieldprice);
        } catch (error) {
            console.log("Error while setting gaurantee shield", error)
        }
    }

    useEffect(() => {
        if (isEmpty) return;
        console.log('Shields state updated:', shields);
    }, [shields]);


    const fetchShieldData = (shieldType, formattedDate) => {
        console.log("called in the api", formattedDate, shieldType);
        if (shieldType === "marketShield") {
            setMarketShieldData([]);
            setLoadingState(prev => ({ ...prev, marketShield: true }));
            GetMarketShield(portfolioId, formattedDate).then((data) => {
                if (data.status === 200) {
                    console.log("api is called with new data", data.result);
                    const periods = Object.keys(data.result.ChoosenOption);
                    const protectionLevels = periods.reduce((acc, period) => ({
                        ...acc,
                        [period]: Object.keys(data.result.ChoosenOption[period])
                            .map(Number)
                            .sort((a, b) => b - a)
                    }), {});
                    setApiPeriod(periods[0]);
                    setDynamicProtectionLevels(prev => ({ ...prev, marketShield: protectionLevels[periods[0]] }));
                    setMarketShieldData(data.result);
                    const closestLevel = protectionLevels[periods[0]].reduce((prev, curr) =>
                        Math.abs(curr - 0.95) < Math.abs(prev - 0.95) ? curr : prev
                    );
                    updateShield("marketShield", "protectionPeriod", new Date(formattedDate));
                    updateShield("marketShield", "protectionLevel", closestLevel);
                } else {
                    handleApiError(data, dispatch);
                }
                setLoadingState(prev => ({ ...prev, marketShield: false }));
            }).catch(error => {
                console.error("Error fetching market shield data:", error);
                handleApiError(error, dispatch);
                setLoadingState(prev => ({ ...prev, marketShield: false }));
            });
        } else if (shieldType === "marketShieldPlus") {
            setMarketShieldPlusData([]);
            setLoadingState(prev => ({ ...prev, marketShieldPlus: true }));
            console.log(" in put for ms plsu", portfolioId, periods_dict[shields.marketShieldPlus.protectionPeriod])
            GetMarketShieldPlus(portfolioId, periods_dict[shields.marketShieldPlus.protectionPeriod]).then((data) => {
                if (data.status === 200) {
                    console.log("api is called with market shield new data", data.result);
                    setMarketShieldPlusData(data.result);
                } else {
                    handleApiError(data, dispatch);
                }
                setLoadingState(prev => ({ ...prev, marketShieldPlus: false }));
            }).catch(error => {
                console.error("Error fetching market shield plus data:", error);
                handleApiError(error, dispatch);
                setLoadingState(prev => ({ ...prev, marketShieldPlus: false }));
            });
        }
    };
    
    useEffect(() => {
        if (!isEmpty && portfolioId) {
            const formattedDate = moment(shields.marketShield.protectionPeriod).format('YYYY-MM-DD');
            fetchShieldData("marketShield", formattedDate);
        }
    }, [isEmpty, portfolioId]);

    useEffect(() => {
        if (!isEmpty && portfolioId) {
            fetchShieldData("marketShieldPlus", shields.marketShieldPlus.protectionPeriod);
        }
    }, [isEmpty, portfolioId, shields.marketShieldPlus.protectionPeriod]);
    

    useEffect(() => {
        if (isEmpty) return;
        if (guaranteedShieldData.length === 0) {
            setLoadingState(prev => ({ ...prev, guaranteedShield: true }));
            // GetQuoteEngineData(portfolioId).then((quote_data) => {
            //     if (quote_data.status === 200) {
            //         console.log(" quote data", quote_data.result);
            //         setGuaranteedShieldData(quote_data.result);
            //         fetch_price(quote_data.result);
            //         setLoadingState(prev => ({ ...prev, guaranteedShield: false }));
            //     } else {
                    //handleApiError(quote_data, dispatch);
            setLoadingState(prev => ({ ...prev, guaranteedShield: false }));
            //     }
            // });
        }
        else if (shields.guaranteedShield.protectionLevel || shields.guaranteedShield.protectionPeriod) {
            fetch_price(guaranteedShieldData);
        }
    }, [isEmpty, shields.guaranteedShield.protectionLevel, shields.guaranteedShield.protectionPeriod]);
    useEffect(() => {
        if (isEmpty) return;
        GetAssetAllocationEquivalence(portfolioId).then((data) => {
            console.log("Asset", data)
            if (data.status === 200) {
                setAllocationData(data.result);
            } else {
                handleApiError(data, dispatch);
            }
        })
    }, [isEmpty, portfolioId]);


    const createShoppingCartData = (shieldType, period, level) => {
        console.log(" inside createe card", shieldType, period, level, shields, marketShieldData)
        let data = shieldType === "marketShield" ? marketShieldData : marketShieldPlusData;
    
        try {
            if (!data || !data.ChoosenOption) return [];
            
            let chosenData;
            if (shieldType === "marketShield") {
                chosenData = data.ChoosenOption[apiPeriod] && data.ChoosenOption[apiPeriod][level];
            } else {
                chosenData = data.ChoosenOption[period] && data.ChoosenOption[period][level];
            }
    
            if (!chosenData) return [];
    
            let contracts = chosenData["SecDesc"] || [];
            let quantities = chosenData["ConQty"] || [];
    
            return contracts.map((contract, index) => ({
                contract: contract,
                quantity: quantities[index] || '-',
                protectionLevel: shieldType === "marketShield" ? level : undefined // Only add protectionLevel for marketShield
            }));
        } catch (err) {
            console.error("Error in createShoppingCartData:", err);
            return [];
        }
    };

    while (marketShieldCart.length < marketShieldPlusCart.length) {
        marketShieldCart.push({ contract: '-', quantity: '-' });
    }
    while (marketShieldPlusCart.length < marketShieldCart.length) {
        marketShieldPlusCart.push({ contract: '-', quantity: '-' });
    }
    console.log(" marketShieldCart", marketShieldPlusCart, marketShieldCart)
    
    const calculateEquivalentProtectionLevel = (shieldType, assetAllocation) => {
        const protectionPeriodKey = periods_dict_asset_allocation[shields[shieldType]?.protectionPeriod];
    
        // If protectionPeriodKey or the necessary data is missing, return an empty string
        if (!protectionPeriodKey || !allocationData?.ProtectionPeriod?.[protectionPeriodKey]?.Allocation?.[assetAllocation]) {
            return "";
        }
    
        // Safely retrieve and calculate the equivalent protection level
        const protectionLevel = allocationData.ProtectionPeriod[protectionPeriodKey].Allocation[assetAllocation]?.ProtLevelFloat;
        
        return protectionLevel ? parseFloat(protectionLevel).toFixed(4) : "";
    };
    
    // const calculateEquivalentProtectionLevel = (shieldType, assetAllocation) => {
    //     if (!allocationData || !allocationData.ProtectionPeriod) {
    //         console.warn("Missing allocation data or protection period in allocation data.");
    //         return "";
    //     }
    
    //     const periodData = allocationData.ProtectionPeriod[periods_dict_asset_allocation[shields[shieldType]["protectionPeriod"]]];
    
    //     if (!periodData || !periodData.Allocation || !periodData.Allocation[assetAllocation]) {
    //         console.warn(`Missing allocation or protection level data for ${shieldType}.`);
    //         return "";
    //     }
    
    //     const roundedValue = `${parseFloat(parseFloat(allocationData["ProtectionPeriod"][periods_dict_asset_allocation[shields[shieldType]["protectionPeriod"]]]["Allocation"][assetAllocation]["ProtLevelFloat"]).toFixed(4))}`;
    //     return roundedValue;
    // };
    
    // const calculateEquivalentProtectionLevel = (shieldType, assetAllocation) => {

    //     const roundedValue = `${parseFloat(parseFloat(allocationData["ProtectionPeriod"][periods_dict_asset_allocation[shields[shieldType]["protectionPeriod"]]]["Allocation"][assetAllocation]["ProtLevelFloat"]).toFixed(4))}`;
    //     return roundedValue

    // }

    const cardData = [
        {
            id: 1,
            title: 'Market Shield',
            key: "marketShield",
            protection_period: shields["marketShield"]["protectionPeriod"],
            protection_level: shields["marketShield"]["protectionLevel"],
            allocation: shields["marketShield"]["assetAllocation"],
            equivalentProtectionLevel: Object.keys(allocationData).length > 0 ? calculateEquivalentProtectionLevel("marketShield", shields["marketShield"]["assetAllocation"]) : " ",
            trackingError: marketShieldData["TrackingError"] * 100 || null,
            closestMatchingIndex: marketShieldData["PrimaryHolding"] || null,
            rSquare: marketShieldData["RSq"] || null,
            beta: marketShieldData["Beta"] || null,
            price: (marketShieldData && marketShieldData["ChoosenOption"] && apiPeriod && shields["marketShield"]["protectionLevel"] &&
                marketShieldData["ChoosenOption"][apiPeriod] &&
                marketShieldData["ChoosenOption"][apiPeriod][[shields["marketShield"]["protectionLevel"]]] &&
                marketShieldData["ChoosenOption"][apiPeriod][[shields["marketShield"]["protectionLevel"]]]["Cost"]) || "-",
            concentratedHolding: "-",
            check: "",
            totalPremium: totalPremium,
            totalCost: totalCost,
            netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`
        },
        {
            id: 2,
            title: 'Market Shield Plus',
            key: 'marketShieldPlus',
            protection_period: shields["marketShieldPlus"]["protectionPeriod"],
            protection_level: shields["marketShieldPlus"]["protectionLevel"],
            allocation: shields["marketShieldPlus"]["assetAllocation"],
            equivalentProtectionLevel: Object.keys(allocationData).length > 0 ? calculateEquivalentProtectionLevel("marketShieldPlus", shields["marketShieldPlus"]["assetAllocation"]) : "",
            trackingError: marketShieldPlusData["TrackingError"] * 100 || null,
            closestMatchingIndex: marketShieldPlusData["PrimaryHolding"],
            rSquare: marketShieldPlusData["RSq"] || null,
            beta: marketShieldPlusData["Beta"] || null,
            price: marketShieldPlusData?.ChoosenOption?.[periods_dict_backend[shields["marketShieldPlus"]["protectionPeriod"]]]?.[
                allocationMode
                    ? calculateEquivalentProtectionLevel("marketShieldPlus", shields["marketShieldPlus"]["assetAllocation"])
                    : levels_dict_backend[shields["marketShieldPlus"]["protectionLevel"]]
            ]?.Cost || "-",
            // price: (Object.keys(marketShieldPlusData).length > 0) ? (Object.keys(marketShieldPlusData["ChoosenOption"]).length > 0) ? marketShieldPlusData["ChoosenOption"][periods_dict_backend[shields["marketShieldPlus"]["protectionPeriod"]]][allocationMode ? calculateEquivalentProtectionLevel("marketShieldPlus", shields["marketShieldPlus"]["assetAllocation"]) : levels_dict_backend[shields["marketShieldPlus"]["protectionLevel"]]]["Cost"] : "-" : "-",
            concentratedHolding: marketShieldPlusData["OtherHolding"] || null,
            check: periods_dict_backend[shields["marketShieldPlus"]["protectionPeriod"]],
            totalPremium: totalPremium,
            totalCost: totalCost,
            netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`
        },
        // {
        //     id: 3,
        //     title: 'Portfolio Shield',
        //     key: "guaranteedShield",
        //     protection_period: shields["guaranteedShield"]["protectionPeriod"],
        //     protection_level: shields["guaranteedShield"]["protectionLevel"],
        //     allocation: shields["guaranteedShield"]["assetAllocation"],
        //     equivalentProtectionLevel: "",
        //     trackingError: 0,
        //     closestMatchingIndex: "-",
        //     rSquare: "1%",
        //     beta: "-",
        //     price: shieldPrice ? shieldPrice : "-",
        //     concentratedHolding: "-",
        //     check: periods_dict_backend[shields["guaranteedShield"]["protectionPeriod"]],
        //     totalPremium: totalPremium,
        //     totalCost: totalCost,
        //     netCost: `${totalPremium - totalCost < 0 ? '-' : ''}${Number(addZeroes(Number(parseFloat(Math.abs(totalPremium - totalCost)).toFixed(2)))).toLocaleString("en-US", currObj)} (${((totalPremium - totalCost) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`


        // },
    ];
    console.log("cardData", cardData, marketShieldPlusData, allocationData)
    const handleProtectionLevelChange = (shieldType, value) => {
        updateShield(shieldType, "protectionLevel", value);
    };


    useEffect(() => {
        
        console.log(" shields in caert", shields)
        const marketShieldCart = createShoppingCartData(
            "marketShield",
            shields["marketShield"]["protectionPeriod"],
            shields["marketShield"]["protectionLevel"]
        );
        console.log(" ms cart ", marketShieldCart)
        setMarketShieldCart(marketShieldCart);
        sessionStorage.setItem('marketShieldCart', JSON.stringify(marketShieldCart));

    }, [
        shields.marketShield.protectionLevel,
        shields.marketShield.protectionPeriod,
        marketShieldData,
        shields.marketShield.assetAllocation,
        allocationMode
    ]);


    useEffect(() => {
        var msPlusLevel = allocationMode === true ? calculateEquivalentProtectionLevel("marketShieldPlus", shields["marketShieldPlus"]["assetAllocation"]) : levels_dict_backend[shields["marketShieldPlus"]["protectionLevel"]]
        console.log("in ms plus change", levels_dict_backend[shields["marketShieldPlus"]["protectionLevel"]])
        // Create a new shopping cart data based on the updated shield data
        console.log("shields.marketShieldPlus.protectionLevel", shields.marketShieldPlus.protectionLevel)
        const marketShieldCart = createShoppingCartData(
            "marketShieldPlus",
            periods_dict_backend[shields["marketShieldPlus"]["protectionPeriod"]],
            msPlusLevel
        );
        setMarketShieldPlusCart(marketShieldCart);
        sessionStorage.setItem('marketShieldPlusCart', JSON.stringify(marketShieldCart));
    }, [
        shields.marketShieldPlus.protectionPeriod,
        marketShieldPlusData,
        shields.marketShieldPlus.protectionLevel,
        shields.marketShieldPlus.assetAllocation,
        allocationMode
    ]);


    const handleProtectionPeriodChange = (shieldType, valueOrDate) => {
        if (shieldType === "marketShield") {
            if (!valueOrDate || isNaN(valueOrDate)) return;
            const formattedDate = moment(valueOrDate).format('YYYY-MM-DD');
            updateShield(shieldType, "protectionPeriod", valueOrDate);
            fetchShieldData(shieldType, formattedDate);
        } else {
            updateShield(shieldType, "protectionPeriod", valueOrDate);
            fetchShieldData(shieldType, valueOrDate);
        }
    };

    const handleAssetAllocationChange = (shieldType, value) => {

        updateShield(shieldType, "assetAllocation", value);

    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawercontent">
                {(!shoppingFlag) ? (
                    <div className='shieldrecc'>
                        <div className="card-container">
                            <div className="header-card">
                                <div>Protection Type</div>
                                <div>Protection Period</div>
                                {/* <div> */}
                                <div className="slider-container" onClick={() => { setAllocationMode(!allocationMode) }}>
                                    {/* <span className={`background ${allocationMode ? 'left' : 'right'}`}></span> */}
                                    <div className={`pill ${allocationMode ? '' : 'active'}`}>Protection Level</div>
                                    <div className={`pill ${allocationMode ? 'active' : ''}`}>Asset Allocation <TooltipComponent id={'Asset Allocation'}/></div>
                                </div>


                                <div>Portfolio Analytics</div>
                                <div>Protection Price</div>
                                <div>Premium Covered Calls</div>
                                <div>Cost Individual Puts</div>
                                <div>Net Cost/Income</div>
                                
                            </div>
                            <div className='cardscontainer'>
                            {cardData.map(card => (
                                <div key={card.id} className="card">
                                    {loadingState[card.key] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/> 
                                        </div> :
                                        <>
                                            <div><h4 className="card-title">{card.title}</h4></div>
                                            <div>
                                                {card.key === "marketShield" ? (
                                                    <DatePicker
                                                        selected={shields[card.key].protectionPeriod}
                                                        onChange={(date) => handleProtectionPeriodChange(card.key, date)}
                                                        dateFormat="yyyy-MM-dd"
                                                    />
                                                ) : (
                                                    <select 
                                                        className="dropdown" 
                                                        value={shields[card.key].protectionPeriod} 
                                                        onChange={(e) => handleProtectionPeriodChange(card.key, e.target.value)}>
                                                        {Object.entries(periods_dict).map(([key, value]) => (
                                                            <option key={value} value={key}>{key}</option>
                                                        ))}
                                                    </select>
                                                )}
                                            </div>

                                            {/* <div>
                                            {console.log("shields[card.key].protectionPeriod", shields, shields[card.key], shields[card.key].protectionPeriod)}
                                            <DatePicker
                                                selected={shields[card.key].protectionPeriod}
                                                onChange={(date) => handleProtectionPeriodChange(card.key, date)}
                                                dateFormat="yyyy-MM-dd"
                                            />
                                            {/* <select className="dropdown" value={periods_dict[card.protection_period]} onChange={(e) => handleProtectionPeriodChange(card.key, e.target.value)}>
                                                {Object.entries(periods_dict).map(([key, value]) => (
                                                    <option key={value} value={value}>{key}</option>
                                                ))}
                                            </select> */}
                                            {/* </div>  */}
                                            <div>
                                                {allocationMode ? (
                                                    <>
                                                        <div flex-col>
                                                            <select className="dropdown" value={card.allocation} onChange={(e) => handleAssetAllocationChange(card.key, e.target.value)}>
                                                                {["100-0", "90-10", "80-20", "70-30", "60-40", "50-50", "40-60", "30-70", "20-80", "10-90", "0-100"].map(option => (
                                                                    <option key={option} value={option}>{option}</option>
                                                                ))}
                                                            </select>
                                                            {card.equivalentProtectionLevel !== "" ? <div style={{ "font-size": 11 }}>Equivalent protection level {parseFloat(card.equivalentProtectionLevel).toFixed(2) * 100}% </div> : null}

                                                        </div>
                                                    </>
                                                ) : (
                                            <div>
                                                <select className="dropdown" value={shields[card.key].protectionLevel} onChange={(e) => handleProtectionLevelChange(card.key, e.target.value)}>
                                                    {console.log(" in console ", card.key, shields)}
                                                    {card.key === "marketShieldPlus" ? (
                                                        [80, 85, 90, 95, 100].map(option => (
                                                            <option key={option} value={option}>
                                                                {option}%
                                                            </option>
                                                        ))
                                                    ) : (
                                                        dynamicProtectionLevels[card.key]?.map(option => (
                                                            <option key={option} value={option}>
                                                                {(option * 100).toFixed(2)}%
                                                            </option>
                                                        ))
                                                    )}
                                                </select>
                                            </div>
                                                // <div>
                                                //     <select className="dropdown" value={card.protection_level} onChange={(e) => handleProtectionLevelChange(card.key, e.target.value)}>
                                                //         {dynamicProtectionLevels[card.key]?.map(option => (
                                                //             <option key={option} value={option}>
                                                //                 {(option * 100).toFixed(2)}%
                                                //             </option>
                                                //         ))}
                                                //     </select>
                                                // </div>

                                                )}
                                            </div>

                                            <div><div className="tracking-error">Tracking Error {card.trackingError?parseFloat(card.trackingError).toFixed(2)+' %':'NA'} </div></div>
                                            <div><h3 className="price">
                                                {card.price === '-' ? "No Price Available" : Number(
                                                    addZeroes(Number(parseFloat(card.price).toFixed(2)))
                                                ).toLocaleString("en-US", currObj)+` (${((card.price) / portfolioDetails.TotalPortfolioValue * 100).toFixed(2)}%)`}</h3></div>
                                            <div>${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(card.totalPremium) || 0)}</div>
                                            <div>${new Intl.NumberFormat('en-US', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(parseFloat(card.totalCost) || 0)}</div>
                                            <div>{card.netCost}</div>
                                        </>
                                    }
                                </div>
                            ))}
                            </div>
                        </div>

                        <div className="center-button-container">
                            <button className="ai-btn line primary" onClick={() => {
                                setShoppingFlag(true);
                            }}><img src="Assets/shopping-cart.svg" alt="toggle" /> Show Shopping Cart</button>
                        </div>

                        <div className="card-container">
                            <div className="header-card">
                                <div>Protection Type</div>
                                <div>Closest Matching Index <TooltipComponent id={'Closest Matching Index'}/></div>
                                <div>Goodness of fit (R-sq)</div>
                                <div>Tracking Error</div>
                                <div>Beta</div>
                                <div>Concentrated Holdings<TooltipComponent id={'Concentrated Holdings'}/></div>
                            </div>
                            <div className='cardscontainer'>
                            {cardData.map(card => (
                                <div key={card.id} className="card">
                                    {loadingState[card.key] ?
                                        <div className="spinner-container">
                                            <Spinner variant="dark" size="big"/>
                                        </div> :
                                        <>
                                            <div className="card-title">{card.title}</div>
                                            <div className="closest-matching-index">{card.closestMatchingIndex}</div>
                                            <div className="r-square">{card.rSquare}</div>
                                            <div className="tracking-error">{card.trackingError?parseFloat(card.trackingError).toFixed(2)+' %':'NA'}</div>
                                            <div className="beta">{card.beta}</div>
                                            <div className="concentrated-holding">
                                                {Array.isArray(card.concentratedHolding) ? card.concentratedHolding.join(', ') : card.concentratedHolding}
                                            </div>
                                        </>}

                                </div>
                            ))}
                            </div>
                        </div>
                    </div>) : (
                    <>
                        <div className="shopping-cart">
                            <button className="ai-btn line secondary"
                                onClick={() => setShoppingFlag(false)}> Back To Shield Recommendations</button>
                                <br></br>
                                <br></br>
                            <h3> Shopping Cart</h3>
                            <br></br>
                                <br></br>
                            <div className="shopping-card-container">
                                <ShoppingCart />
                            </div>
                        </div>
                    </>
                )
                }
            </div >
        </>
    );
};

export default ShieldRecommendation;
