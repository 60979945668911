import React, { useState, useMemo } from "react";
import { Tooltip } from "react-tooltip";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableFooter from "@mui/material/TableFooter";
import { TabContainer, TabPane, TabContent } from "react-bootstrap";
import { addZeroes } from "../../Utilities/AddZeros";
import { useSelector } from "react-redux";
import TooltipComponent from "../TooltipComponent/TooltipComponent";
import { MaterialReactTable } from "material-react-table";
import { customColumnSort } from "../../Utilities/CustomColumnSort";
var currObj = {
  style: "currency",
  currency: "USD",
};

const BackTestV2Table = ({
  SimulationDetailsTable,
  showPortfolioShield,
  PayoutDetailsTable,
  FinalBacktestPortfolioValue,
  ProtectionLevel,
}) => {
  const drawerData = useSelector((state) => state.drawer.data);
  const portfolioId = drawerData.portfolioId;
  const portfolioDetails = useSelector(
    (state) => state.portfolioDetails[portfolioId].details
  );
  const [show2, setShow2] = useState(true);
  const ResultsTableHeaders = [
    { title: "Date" },
    { title: "Shield Cost($)" },
    { title: "Compounded Shield Cost($)" },
    { title: "Shield Payout($)" },
    { title: "Compounded Shield Payout($)" },
    { title: "Portfolio Value($)" },
    { title: "Portfolio Value At Shield Expiration" },
    { title: "Protected Value" },
    { title: "Protection Level" },
    { title: "Shield Cost(%)" },
    { title: "Payout(%)" },
    { title: "Portfolio Implied Downside Volatility (without Shield)" },
    { title: "Portfolio Implied Downside Volatility(with Shield)" },
  ];

  const HedgeData = useMemo(
    () =>
      portfolioDetails.PortfolioLineItem.Hedgeable.map((value, index) => ({
        sr: index + 1,
        symbol: value.Ticker,
        company: value.CompanyName,
        quantity: Number(
          addZeroes(Number(parseFloat(value.Quantity).toFixed(2)))
        ).toLocaleString("en-US"),
        closingprice: Number(
          addZeroes(Number(parseFloat(value.ClosePrice).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        marketvalue: Number(
          addZeroes(Number(parseFloat(value.TotalValue).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        weight:
          (
            (value.TotalValue / portfolioDetails.TotalPortfolioValue) *
            100
          ).toFixed(2) + "%",
      })),
    [portfolioDetails]
  );

  const HedgeableColumns = useMemo(
    () => [
      { accessorKey: "sr", header: "#", footer: "", size: "70" },
      {
        accessorKey: "symbol",
        header: "Symbol",
        footer: "Portfolio Total:",
        size: "130",
      },
      { accessorKey: "company", header: "Position Name", footer: "" },
      { 
        accessorKey: "quantity", 
        header: "Quantity", 
        footer: "",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'quantity'),
       },
      {
        accessorKey: "closingprice",
        header: "Closing Price",
        footer: "",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'closingprice'),
      },
      {
        accessorKey: "marketvalue",
        header: "Market Value",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'marketvalue'),
        footer: Number(
          addZeroes(
            Number(parseFloat(portfolioDetails.TotalPortfolioValue).toFixed(2))
          )
        ).toLocaleString("en-US", currObj),
        size: "170",
      },
      {
        accessorKey: "weight",
        header: "Weight",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'weight'),
        footer: "100%",
      },
    ],
    [portfolioDetails]
  );

  const [tabletabvalue, setTableTabValue] = React.useState("Portfolio Details");

  function handleTableTabChange(tabletabvalue) {
    setTableTabValue(tabletabvalue);
  }
  const totalShieldCost = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.shield_cost),
    0
  );
  const totalShieldPayout = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.shield_payoff),
    0
  );
  const totalCompoundedPayout = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.compounded_shield_payoff),
    0
  );
  const totalCompoundedShieldCost = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.compounded_shield_cost),
    0
  );
  const averageVolatilityWithoutShield =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc +
        parseFloat(curr.portfolio_implied_downside_volatility_without_shield) *
          100,
      0
    ) / SimulationDetailsTable.length;
  const averageVolatilityWithShield =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc +
        parseFloat(curr.portfolio_implied_downside_volatility_with_shield) *
          100,
      0
    ) / SimulationDetailsTable.length;
  const averageCostPercent =
    SimulationDetailsTable.reduce(
      (acc, curr) => acc + parseFloat(curr.shield_cost_percent) * 100,
      0
    ) / SimulationDetailsTable.length;
  const averagePayoutPercent =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc + parseFloat(curr.shield_payoff / curr.protected_value) * 100,
      0
    ) / SimulationDetailsTable.length;

  const totalMSShieldCost = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.market_shield_costs),
    0
  );
  const totalMSShieldPayout = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.ms_shield_payoff),
    0
  );
  const totalMSCompoundedPayout = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.compounded_market_shield_payoff),
    0
  );
  const totalMSCompoundedShieldCost = SimulationDetailsTable.reduce(
    (acc, curr) => acc + parseFloat(curr.compounded_market_shield_cost),
    0
  );
  const averageMSVolatilityWithoutShield =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc +
        parseFloat(
          curr.portfolio_implied_downside_volatility_without_market_shield
        ) *
          100,
      0
    ) / SimulationDetailsTable.length;
  const averageMSVolatilityWithShield =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc +
        parseFloat(
          curr.portfolio_implied_downside_volatility_with_market_shield
        ) *
          100,
      0
    ) / SimulationDetailsTable.length;
  const averageMSCostPercent =
    SimulationDetailsTable.reduce(
      (acc, curr) => acc + parseFloat(curr.market_shield_cost_percent) * 100,
      0
    ) / SimulationDetailsTable.length;
  const averageMSPayoutPercent =
    SimulationDetailsTable.reduce(
      (acc, curr) =>
        acc + parseFloat(curr.ms_shield_payoff / curr.ms_protected_value) * 100,
      0
    ) / SimulationDetailsTable.length;
  const SimulationData = useMemo(
    () =>
      SimulationDetailsTable.map((value) => ({
        date: value.date,
        shieldcostval: Number(
          addZeroes(Number(parseFloat(value.market_shield_costs).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        compoundedshieldcost: Number(
          addZeroes(
            Number(parseFloat(value.compounded_market_shield_cost).toFixed(2))
          )
        ).toLocaleString("en-US", currObj),
        shieldpayout: Number(
          addZeroes(Number(parseFloat(value.ms_shield_payoff).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        compoundedshieldpayout: Number(
          addZeroes(
            Number(parseFloat(value.compounded_market_shield_payoff).toFixed(2))
          )
        ).toLocaleString("en-US", currObj),
        portfoliovalue: Number(
          addZeroes(Number(parseFloat(value.ms_portfolio_value).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        portfoliovalueatshieldexpiration: Number(
          addZeroes(
            Number(
              parseFloat(value.simulation_end_ms_portfolio_values).toFixed(2)
            )
          )
        ).toLocaleString("en-US", currObj),
        protectedvalue: Number(
          addZeroes(Number(parseFloat(value.ms_protected_value).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        protectionlevel:
          Number(
            addZeroes(Number(parseFloat(value.ms_protection_level).toFixed(2)))
          ) + "%",
        shieldcostpercent:
          Number(
            addZeroes(
              Number(
                parseFloat(value.market_shield_cost_percent * 100).toFixed(2)
              )
            )
          ) + "%",
        payout: Number(
          addZeroes(
            Number(
              parseFloat(
                (value.ms_shield_payoff / value.ms_protected_value) * 100
              ).toFixed(2)
            )
          )
        ).toLocaleString("en-US", currObj),
        portfolioimplieddownsidevolatilitywoshield: Number(
          addZeroes(
            Number(
              parseFloat(
                value.portfolio_implied_downside_volatility_without_market_shield *
                  100
              ).toFixed(2)
            )
          )
        ).toLocaleString("en-US", currObj),
        portfolioimplieddownsidevolatilitywshield: Number(
          addZeroes(
            Number(
              parseFloat(
                value.portfolio_implied_downside_volatility_with_market_shield *
                  100
              ).toFixed(2)
            )
          )
        ).toLocaleString("en-US", currObj),
      })),
    [SimulationDetailsTable]
  );

  const SimulationDetailsColumns = useMemo(
    () => [
      { accessorKey: "date", header: "Date", footer: "Total:", size: "70" },
      {
        accessorKey: "shieldcostval",
        header: "Shield Cost($)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'shieldcostval'),
        footer: Number(
          addZeroes(Number(parseFloat(totalMSShieldCost).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        size: "130",
      },
      {
        accessorKey: "compoundedshieldcost",
        header: "Compounded Shield Cost($)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'compoundedshieldcost'),
        footer: Number(
          addZeroes(Number(parseFloat(totalMSCompoundedShieldCost).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        size: "130",
      },
      {
        accessorKey: "shieldpayout",
        header: "Shield Payout($)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'shieldpayout'),
        footer: Number(
          addZeroes(Number(parseFloat(totalMSShieldPayout).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        size: "130",
      },
      {
        accessorKey: "compoundedshieldpayout",
        header: "Compounded Shield Payout($)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'compoundedshieldpayout'),
        footer: Number(
          addZeroes(Number(parseFloat(totalMSCompoundedPayout).toFixed(2)))
        ).toLocaleString("en-US", currObj),
        size: "130",
      },
      {
        accessorKey: "portfoliovalue",
        header: "Portfolio Value($)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'portfoliovalue'),
        footer: "",
      },
      {
        accessorKey: "portfoliovalueatshieldexpiration",
        header: "Portfolio Value At Shield Expiration",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'portfoliovalueatshieldexpiration'),
        footer: "",
      },
      { 
        accessorKey: "protectedvalue", 
        header: "Protected Value", 
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'protectedvalue'),
        footer: "" 
      },
      {
        accessorKey: "protectionlevel",
        header: "Protection Level",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'protectionlevel'),
        footer: "",
      },
      {
        accessorKey: "shieldcostpercent",
        header: "Shield Cost(%)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'shieldcostpercent'),
        footer: "",
      },
      { 
        accessorKey: "payout", 
        header: "Payout(%)", 
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'payout'),
        footer: "" 
      },
      {
        accessorKey: "portfolioimplieddownsidevolatilitywoshield",
        header: "Portfolio Implied Downside Volatility (without Shield)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'portfolioimplieddownsidevolatilitywoshield'),
        footer: "",
      },
      {
        accessorKey: "portfolioimplieddownsidevolatilitywshield",
        header: "Portfolio Implied Downside Volatility(with Shield)",
        sorting: true,
        sortingFn: (a,b) => customColumnSort(a,b,'portfolioimplieddownsidevolatilitywshield')
      },
    ],
    [SimulationDetailsTable]
  );
  return (
    <div className="portfolio-table contributio-table nogap factortbl">
      <div className="table_holder table_head ai-toggle-con m0i">
        <h3 onClick={() => setShow2(!show2)} className="ai-toggle-ttl">
          {show2 && <span className="minus">-</span>} {!show2 && <span>+</span>}
          <span className="tgltitle">Portfolio Details</span>
        </h3>
        {show2 && (
          <>
            <ul className="subnavbar-menu navtab">
              <li>
                <a
                  id="portfoliodetailstab"
                  className={
                    tabletabvalue === "Portfolio Details"
                      ? "SUBITEM-selected "
                      : "SUBITEM"
                  }
                  eventKey="Portfolio Details"
                  onClick={() => handleTableTabChange("Portfolio Details")}
                >
                  {" "}
                  Portfolio Details{" "}
                  <Tooltip content="">
                    <img src="Assets/risk-info.png"></img>
                  </Tooltip>
                </a>
              </li>
              <li>
                <a
                  id="marketshieldtable"
                  className={
                    tabletabvalue === "Market Shield Results"
                      ? "SUBITEM-selected"
                      : "SUBITEM"
                  }
                  eventKey="Market Shield Results"
                  onClick={() => handleTableTabChange("Market Shield Results")}
                >
                  Market Shield Results{" "}
                  <Tooltip content="">
                    <img src="Assets/risk-info.png"></img>
                  </Tooltip>
                </a>
              </li>
              {showPortfolioShield && (
                <li>
                  <a
                    id="portfolioshieldtable"
                    className={
                      tabletabvalue === "Portfolio Shield Results"
                        ? "SUBITEM-selected"
                        : "SUBITEM"
                    }
                    eventKey="Portfolio Shield Results"
                    onClick={() =>
                      handleTableTabChange("Portfolio Shield Results")
                    }
                  >
                    Portfolio Shield Results{" "}
                    <Tooltip content="">
                      <img src="Assets/risk-info.png"></img>
                    </Tooltip>
                  </a>
                </li>
              )}
              {/* <li>
                            <a id="shieldpayoutstab" className={tabletabvalue === "Simulation Details" ? "SUBITEM-selected" : "SUBITEM"} eventKey="Simulation Details" onClick={() => handleTableTabChange('Simulation Details')}>Simulation Details <Tooltip content=""><img src="Assets/risk-info.png"></img></Tooltip></a>
                        </li> */}
            </ul>
            <TabContainer
              defaultActiveKey={tabletabvalue}
            >
              <TabContent className="table_height tabbody">
                <TabPane
                  eventKey="Portfolio Details"
                  active={tabletabvalue === "Portfolio Details"}
                >
                  <TableContainer>
                    <>
                      <MaterialReactTable
                        columns={HedgeableColumns}
                        data={HedgeData}
                        enableStickyHeader
                        enableStickyFooter
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableDensityToggle={false}
                        enableColumnResizing
                        enableColumnOrdering
                        enableRowPinning
                        enablePagination={false}
                        enableFullScreenToggle={false}
                        muiTableContainerProps={{
                          sx: {
                            maxHeight: "400px",
                          },
                        }}
                        initialState={{
                          density: "compact",
                        }}
                        muiTableHeadCellProps={{
                          sx: {
                            backgroundColor: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          },
                        }}
                        muiTableFootCellProps={{
                          sx: {
                            backgroundColor: "white",
                            position: "sticky",
                            bottom: 0,
                            zIndex: 1,
                          },
                        }}
                      />
                    </>
                  </TableContainer>
                </TabPane>
                <TabPane
                  eventKey="Market Shield Results"
                  active={tabletabvalue === "Market Shield Results"}
                >
                  <TableContainer className="table_height">
                    <>
                      <MaterialReactTable
                        columns={SimulationDetailsColumns}
                        data={SimulationData}
                        enableStickyHeader
                        enableStickyFooter
                        enableBottomToolbar={false}
                        enableColumnActions={false}
                        enableDensityToggle={false}
                        enableColumnResizing
                        enableColumnOrdering
                        enableRowPinning
                        enablePagination={false}
                        enableFullScreenToggle={false}
                        muiTableContainerProps={{
                          sx: {
                            maxHeight: "400px",
                          },
                        }}
                        initialState={{
                          density: "compact",
                        }}
                        muiTableHeadCellProps={{
                          sx: {
                            backgroundColor: "white",
                            position: "sticky",
                            top: 0,
                            zIndex: 1,
                          },
                        }}
                        muiTableFootCellProps={{
                          sx: {
                            backgroundColor: "white",
                            position: "sticky",
                            bottom: 0,
                            zIndex: 1,
                          },
                        }}
                      />
                    </>
                  </TableContainer>
                </TabPane>
                {/* {showPortfolioShield && (
              <TabPane
                eventKey="Portfolio Shield Results"
                active={tabletabvalue === "Portfolio Shield Results"}
              >
                <TableContainer className="table_height">
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        {ResultsTableHeaders.map((header) => (
                          <TableCell>
                            <div
                              id="marketshieldtablele"
                              className="stck_table_heading"
                            >
                              {header.title}
                              <TooltipComponent id={header.title} />
                            </div>
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(SimulationDetailsTable).map(
                        ([key, value]) => (
                          <TableRow>
                            <TableCell>{value.date}</TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(value.shield_cost).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(
                                      value.compounded_shield_cost
                                    ).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(value.shield_payoff).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(
                                      value.compounded_shield_payoff
                                    ).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(value.portfolio_value).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(
                                      value.simulation_end_portfolio_values
                                    ).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>
                              {Number(
                                addZeroes(
                                  Number(
                                    parseFloat(
                                      (value.protected_value *
                                        value.protection_level) /
                                        100
                                    ).toFixed(2)
                                  )
                                )
                              ).toLocaleString("en-US", currObj)}
                            </TableCell>
                            <TableCell>{value.protection_level}%</TableCell>

                            <TableCell>
                              {parseFloat(
                                value.shield_cost_percent * 100
                              ).toFixed(2)}
                              %
                            </TableCell>
                            <TableCell>
                              {parseFloat(
                                (value.shield_payoff / value.protected_value) *
                                  100
                              ).toFixed(2)}
                              %
                            </TableCell>
                            <TableCell>
                              {(
                                parseFloat(
                                  value.portfolio_implied_downside_volatility_without_shield
                                ) * 100
                              ).toFixed(2)}
                              %
                            </TableCell>
                            <TableCell>
                              {(
                                parseFloat(
                                  value.portfolio_implied_downside_volatility_with_shield
                                ) * 100
                              ).toFixed(2)}
                              %
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                    <TableFooter>
                      <TableRow>
                        <TableCell>Total:</TableCell>
                        <TableCell>
                          {totalShieldCost.toLocaleString("en-US", currObj)}
                        </TableCell>
                        <TableCell>
                          {totalCompoundedShieldCost.toLocaleString(
                            "en-US",
                            currObj
                          )}
                        </TableCell>
                        <TableCell>
                          {totalShieldPayout.toLocaleString("en-US", currObj)}
                        </TableCell>
                        <TableCell>
                          {totalCompoundedPayout.toLocaleString(
                            "en-US",
                            currObj
                          )}
                        </TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>{" "}
                      </TableRow>
                    </TableFooter>
                  </Table>
                </TableContainer>
              </TabPane>
            )} */}
              </TabContent>
            </TabContainer>
          </>
        )}
      </div>
    </div>
  );
};

export default BackTestV2Table;
