import React from 'react';
import { utils, writeFile } from 'xlsx';
import { addZeroes } from "../../Utilities/AddZeros";
import { formatContract } from '../../Utilities/FormatContract';

const ShoppingCart = () => {
    var currObj = {
        style: "currency",
        currency: "USD",
    };
    const marketShieldCart = JSON.parse(sessionStorage.getItem('marketShieldCart')) || [];
    const marketShieldPlusCart = JSON.parse(sessionStorage.getItem('marketShieldPlusCart')) || [];
    const callWritingCartItems = JSON.parse(sessionStorage.getItem('callWritingCartItems')) || [];
    const putBuyingCartItems = JSON.parse(sessionStorage.getItem('putBuyingCartItems')) || [];

    const handleDownload = (cart, needsFormat) => {
        let includePremium = cart.some(item => 'premium' in item);
    
        const csvRows = cart.map(item => {
            const displayContract = needsFormat ? formatContract(item.contract) || item.contract : item.contract;
            console.log('displayContract',displayContract)
            const formattedQuantity = item.quantity === '-' ? '' : item.quantity;
            let row = {'Contract':displayContract,'Quantity':formattedQuantity};
    
            if (includePremium && item.premium !== undefined) {
                const formattedPremium = item.premium ? item.premium.toFixed(2) : '';
                row['Premium'] = formattedPremium;
            }
    
            return row;
        })
        
        return csvRows;
    };

    const ShoppingCard = ({ cart, title }) => {
        const totalPremium = cart.reduce((acc, item) => acc + (item.premium || 0), 0);

        return (
            <div className='shopping-card'>
                <h4>{title}</h4>
                {cart.map((item, index) => {
                    const needsFormat = title !== "Covered Call" && title !== "Individual Put";
                    const callType = title === "Covered Call" ? 'CALL': 'PUT'
                    const displayContract = needsFormat && item.contract !== '-' ? formatContract(item.contract) : item.contract;
                    const displayPremium = item.premium ? `: ${Number(addZeroes(Number(parseFloat(item.premium).toFixed(2)))).toLocaleString("en-US", currObj)}` : '';

                    return (
                        <div key={index} className="item-row">
                            <span>{displayContract}</span>
                            <span>{callType}</span>
                            <span className="quantity-symbol">{item.quantity === '-' ? '' : 'x'}</span>
                            <span className="quantity">{item.quantity === '-' ? '' : item.quantity}</span>
                            <span className="premium">{displayPremium}</span>
                        </div>
                    );
                })}

                {totalPremium > 0 && <div className="premium">Total: {Number(addZeroes(Number(parseFloat(totalPremium).toFixed(2)))).toLocaleString("en-US", currObj)}</div>}
            </div>
        );
    };

    const handleAllDownload = ()=>{
        let s1 = handleDownload(marketShieldCart,false);
        let s2 = handleDownload(marketShieldPlusCart,false);
        let s3 = handleDownload(callWritingCartItems,true);
        let s4 = handleDownload(putBuyingCartItems,true);

        const workbook = utils.book_new();
        const ms = utils.json_to_sheet(s1);
        ms["!cols"] = [ { wch: 20 } ];
        utils.book_append_sheet(workbook, ms, "Market Shield");
        const msp = utils.json_to_sheet(s2);
        msp["!cols"] = [ { wch: 20 } ];
        utils.book_append_sheet(workbook, msp, "Market Shield Plus");
        const cw = utils.json_to_sheet(s3);
        cw["!cols"] = [ { wch: 20 } ];
        utils.book_append_sheet(workbook, cw, "Covered Call");
        const pb = utils.json_to_sheet(s4);
        pb["!cols"] = [ { wch: 20 } ];
        utils.book_append_sheet(workbook, pb, "Individual Put");
        writeFile(workbook, "Recommendations-Contracts.xlsx", { compression: true });
    }


    return (
        <>
            <div className="cards-group">
                {marketShieldCart.length > 0 && (
                    <ShoppingCard
                        cart={marketShieldCart}
                        title="Market Shield"
                        onClick={() => handleDownload(marketShieldCart, false)}
                    />
                )}
                {marketShieldPlusCart.length > 0 && (
                    <ShoppingCard
                        cart={marketShieldPlusCart}
                        title="Market Shield Plus"
                        onClick={() => handleDownload(marketShieldPlusCart, false)}
                    />
                )}
            </div>
            <div className="cards-group">
                {callWritingCartItems.length > 0 && (
                    <ShoppingCard
                        cart={callWritingCartItems}
                        title="Covered Call"
                        onClick={() => handleDownload(callWritingCartItems, true)}
                    />
                )}
                {putBuyingCartItems.length > 0 && (
                    <ShoppingCard
                        cart={putBuyingCartItems}
                        title="Individual Put"
                        onClick={() => handleDownload(putBuyingCartItems, true)}
                    />
                )}
            </div>
            <div className='center-button-container'>
                <button className="ai-btn solid blue" onClick={() => handleAllDownload()}>
                    Download
                </button>
            </div>
        </>
    );
};

export default ShoppingCart;
