import {
    TOGGLE_ITEM_SELECTION, CLEAR_SELECTIONS, SET_ALL_SELECTED, INITIALIZE_SELECTIONS,
    SET_GLOBAL_CAP_LEVEL, SET_EXP_DATE, SET_LOCAL_FILTERS, RESET_ALL_DASHBOARDS
} from '../actions/selectionActions';

const initialState = {
    CallWriting: {
        selectedItems: {},
        allSelected: true,
        GlobalCapLevel: 110,
        ExpDate: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().slice(0, 10),
        localFilters: {}
    },
    PutBuying: {
        selectedItems: {},
        allSelected: false,
        GlobalCapLevel: 90,
        ExpDate: new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().slice(0, 10),
        localFilters: {}
    }
};

function selectionReducer(state = initialState, action) {
    switch (action.type) {
        case RESET_ALL_DASHBOARDS:
            console.log('Resetting to initial state', initialState);
            return { ...initialState };

        default:
                const { tabType } = action.payload ?? {};
                if (!tabType) return state;
                
                switch (action.type) {
                    case TOGGLE_ITEM_SELECTION: {
                        const { ticker, isSelected } = action.payload;
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                selectedItems: {
                                    ...state[tabType].selectedItems,
                                    [ticker]: isSelected
                                },
                                allSelected: Object.values(state[tabType].selectedItems).every(t => t)
                            }
                        };
                    }
                    case SET_ALL_SELECTED: {
                        const { isSelected: allSelected } = action.payload;
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                selectedItems: Object.keys(state[tabType].selectedItems).reduce((acc, key) => {
                                    acc[key] = allSelected;
                                    return acc;
                                }, {}),
                                allSelected
                            }
                        };
                    }
                    case CLEAR_SELECTIONS:
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                selectedItems: {},
                                allSelected: false
                            }
                        };
                    case INITIALIZE_SELECTIONS:
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                selectedItems: action.payload.initialData,
                                allSelected: Object.values(action.payload.initialData).every(Boolean)
                            }
                        };
                    case SET_GLOBAL_CAP_LEVEL:
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                GlobalCapLevel: action.payload.level
                            }
                        };
                    case SET_EXP_DATE:
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                ExpDate: action.payload.date
                            }
                        };
                    case SET_LOCAL_FILTERS:
                        console.log('Local to initial state');
                        return {
                            ...state,
                            [tabType]: {
                                ...state[tabType],
                                localFilters: {
                                    ...state[tabType].localFilters,
                                    ...action.payload.filters
                                }
                            }
                        };
                    default:
                        return false
                }
        }
    }
//         case TOGGLE_ITEM_SELECTION:
//             const { ticker, isSelected } = action.payload;
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     selectedItems: {
//                         ...state[tabType].selectedItems,
//                         [ticker]: isSelected
//                     },
//                     allSelected: Object.values(state[tabType].selectedItems).every(t => t)
//                 }
//             };
//         case SET_ALL_SELECTED:
//             const { isSelected: allSelected } = action.payload;
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     selectedItems: Object.keys(state[tabType].selectedItems).reduce((acc, key) => {
//                         acc[key] = allSelected;
//                         return acc;
//                     }, {}),
//                     allSelected
//                 }
//             };
//         case CLEAR_SELECTIONS:
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     selectedItems: {},
//                     allSelected: false
//                 }
//             };
//         case INITIALIZE_SELECTIONS:
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     selectedItems: action.payload.initialData,
//                     allSelected: Object.values(action.payload.initialData).every(Boolean)
//                 }
//             };
//         case SET_GLOBAL_CAP_LEVEL:
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     GlobalCapLevel: action.payload.level
//                 }
//             };
//         case SET_EXP_DATE:
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     ExpDate: action.payload.date
//                 }
//             };
//         case SET_LOCAL_FILTERS:
//             console.log('Local to initial state');
//             return {
//                 ...state,
//                 [tabType]: {
//                     ...state[tabType],
//                     localFilters: {
//                         ...state[tabType].localFilters,
//                         ...action.payload.filters
//                     }
//                 }
//             };
//         case RESET_ALL_DASHBOARDS:
//             console.log('Resetting to initial state', initialState);
//             return {
//                 ...initialState
//             };
//         default:
//             return state;
//     }
// }


// export default selectionReducer;

export default selectionReducer;