import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import './AdvisorProfile.scss';
import { useDispatch, useSelector } from 'react-redux';
import { GetStripeSubscriptions } from '../../Apis/GetStripeSubscriptions';
import { CancelStripeSubscription } from '../../Apis/CancelStripeSubscription';
import { handleApiError, showConfirmNotification, showNotification } from '../../actions/notificationActions';
import UpdateProfile from '../UpdateAdvisorProfile/UpdateAdvisorProfile';
import Loader from '../../Components/Loader/Loader';
import { openDrawer } from '../../actions/drawerActions';
import { fetchProfile } from '../../actions/advisorProfileActions';
import Toaster from '../Toaster/Toaster';
import TagManager from "react-gtm-module";

function AdvisorProfile() {

    const dispatch = useDispatch();
    const history = useHistory();
    const [subscriptions, setSubscriptions] = useState([]);
    const [activePlan, setActivePlan] = useState(null);
    const advisorProfile = useSelector(state => state.advisorProfile.profileData);
    const [showUpdateProfile, setShowUpdateProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleLogOut = () => history.push("/");
    const activeDrawer = useSelector(state => state.drawer.activeDrawer) === "EditProfile";



    useEffect(() => {
        setIsLoading(true);
        fetchSubscriptions();
        if (Object.keys(advisorProfile).length === 0) {
            
            dispatch(fetchProfile()).then(()=>{
                setIsLoading(false)
            });
        }
    }, [dispatch, advisorProfile]);

    const fetchSubscriptions = () => {
        GetStripeSubscriptions().then((subscriptionsResponse) => {
            setIsLoading(false);
            if (subscriptionsResponse.status === 200) {
                setSubscriptions(subscriptionsResponse.result.data);
                setActivePlan(subscriptionsResponse.result.data.length > 0 ? subscriptionsResponse.result.data[0] : null);
            } else {
                handleApiError(subscriptionsResponse, dispatch);
            }
        });
    };

    const handleCancelSubscription = (subscriptionId) => {
        dispatch(showConfirmNotification("Are you sure you want to cancel your subscription plan?", () => cancelSubscription(subscriptionId)));
    };

    const cancelSubscription = (subscriptionId) => {
        setIsLoading(true)
        CancelStripeSubscription(subscriptionId).then((response) => {
            setIsLoading(false);
            if (response.status === 200) {
                TagManager.dataLayer({
                    dataLayer: {
                      event: 'Subscription Cancelled',
                      userType: sessionStorage.getItem("selectedAcType"),
                      user_id: sessionStorage.getItem("clientId")
                    },
                  });
                dispatch(showNotification("Subscription Cancelled Successfully","success","toaster"))
                handleLogOut();
                console.log(`Subscription ${subscriptionId} cancelled`);
            } else {
                handleApiError(response, dispatch);
            }
        });
    };

    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000);
        return date.toLocaleDateString("en-US");
    };

    const isSubscriptionActive = (subscription) => {
        const currentTimestamp = Math.floor(Date.now() / 1000);
        return subscription.current_period_end > currentTimestamp;
    };

    const toggleUpdateProfile = () => {
        dispatch(openDrawer("EditProfile", {
            firstName: advisorProfile.firstname,
            lastName: advisorProfile.lastname,
            emailid: advisorProfile.emailid,
            companyname: advisorProfile.companyname,
            crd_number: advisorProfile.crd_number,
            contactno: advisorProfile.contactno
        }));
        setShowUpdateProfile(!showUpdateProfile);
    };

    const getInitials = (firstName, lastName) => {
        return `${firstName ? firstName[0] : ''}${lastName ? lastName[0] : ''}`;
    };

    if (isLoading) {
        return (
            <div className="loading-overlay">
                <Loader/>
            </div>
        );
    }

    return (
        <>
            <div className='profiledata'>
            <div className="toast-container">
                <Toaster />
            </div>
                <div className='advisorcard'>
                    <a className="user-info" >
                        <span className="user-avatar">
                            {getInitials(advisorProfile.firstname, advisorProfile.lastname)}
                        </span>
                    </a>
                    <div className='username'>
                        <h4>{advisorProfile.firstname} {advisorProfile.lastname}</h4>
                        <div className='designations'>
                            <a><img src="./Assets/Mail.svg" alt="Email" /> <span>{advisorProfile.emailid}</span></a>
                            <a><img src="./Assets/Firm.svg" alt="Company" /> <span>{advisorProfile.companyname}</span></a>
                            <a><span>Contact Number: {advisorProfile.contactno}</span></a>
                            <a><span>CRD Number: {advisorProfile.crd_number}</span></a>
                        </div>
                    </div>
                </div>
                <div className='profileaction'>
                    {/* <button className='ai-btn blue solid'><img src="./Assets/Lock.svg" />Change Password</button> */}
                    <button className='ai-btn blue solid' onClick={toggleUpdateProfile}><img src="./Assets/EditUser.svg" />Edit Profile</button>
                </div>
            </div>
            <div className='profileoption'>
                {activePlan ? (
                    <div className='plan'>
                        <div className='planinfo'>
                            <h4>Active Plan</h4>
                            <span className='point'><img src="./Assets/Mail.svg" /> <span>{advisorProfile.emailid}</span></span>
                            <span className='point'>Auto-renewed On - {formatDate(activePlan.current_period_end)}</span>
                            <span className='point'>Amount - ${(activePlan.plan.amount / 100).toFixed(2)}</span>
                        </div>
                        {isSubscriptionActive(activePlan) && (
                            <button className='ai-btn red solid' onClick={() => handleCancelSubscription(activePlan.id)}>
                                Cancel Subscription
                            </button>
                        )}
                        {/* <button className='ai-btn primary solid'>Renew Now</button> */}
                    </div>
                ) : (
                    <p>No active subscription found.</p>
                )}
                {/* <div className='history'>
                    <h4>View Purchase history </h4>
                    <button className='ai-link'>View Purchase history</button>
                </div> */}
                {/* <span className='divider'></span> */}
                {/* <div className='history'>
                    <div className='closeacc'><h4>Closing your account </h4><span>Once you delete your account, there is no going back. Please be certain.</span></div>
                    <button className='ai-btn red solid'>Delete Account</button>
                </div> */}
            </div >
            {activeDrawer && <UpdateProfile />
            }
        </>
    );
}

export default AdvisorProfile;
