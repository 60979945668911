import React, { useState, useEffect, useRef, Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import './AnalyticsDrawer.scss';
import RiskContribution from '../../Components/RiskContribution/RiskContribution';
import SummaryAnalytics from '../SummaryAnalytics/SummaryAnalytics';
import FactorAnalysis from '../../Components/FactorAnalysis/FactorAnalysis';
import TerminalReturn from '../../Components/TerminalReturn/TerminalReturn';
import BackTestV2 from '../../Components/BackTestV2/BackTestV2';
import ForwardTest from '../../Components/ForwardTest/ForwardTest';
import Toaster from '../../Components/Toaster/Toaster';
import PdfComponent from '../PDFComponent/PdfComponent';
import { BeatLoader, SyncLoader } from 'react-spinners';
import Loader from '../../Components/Loader/Loader';
import { togglePortfolioSelection } from '../../actions/portfolioActions';

function AnalyticsDrawer() {

    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const analyticsData = useSelector(state => state.pdfData);
    const [isLoading, setIsLoading] = useState(false);
    const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
    const [activeTabLabel, setActiveTabLabel] = useState('Summary Analytics');
    const portfolioDetails = useSelector(state => state.portfolioDetails[drawerData.portfolioId].details);
    const advisorProfile = useSelector(state => state.advisorProfile.profileData);

    // const tabs = [
    //     { label: 'Risk Contribution', component: <RiskContribution portfolioId={drawerData.portfolioId} isEmpty={drawerData.isEmpty} /> },
    //     { label: 'Factor Analysis', component: <FactorAnalysis portfolioId={drawerData.portfolioId} isEmpty={drawerData.isEmpty} /> },
    //     // { label: 'Terminal Return', component: <TerminalReturn portfolioId={drawerData.portfolioId} isEmpty={drawerData.isEmpty} /> },
    //     { label: 'Shield Backtest', component: <BackTestV2 portfolioId={drawerData.portfolioId} isEmpty={drawerData.isEmpty} /> },
    //     { label: 'Shield Forward Test', component: <ForwardTest portfolioId={drawerData.portfolioId} isEmpty={drawerData.isEmpty} /> }
    // ];

    const tabs = [
        { label: 'Summary Analytics', key: 'summaryAnalytics', component: SummaryAnalytics },
        { label: 'Risk Contribution', key: 'riskContribution', component: RiskContribution },
        { label: 'Factor Analysis', key: 'factorAnalysis', component: FactorAnalysis },
        { label: 'Backtest', key: 'Backtest', component: BackTestV2 },
        { label: 'Forward Test', key: 'forwardTest', component: ForwardTest },
    ];

    const renderTabComponent = (tab) => {
        if (activeTabLabel === tab.label) {
            const Component = tab.component;
            return <Component
                portfolioId={drawerData.portfolioId}
                isEmpty={drawerData.isEmpty}
                isGeneratingPdf={isGeneratingPdf}
                activeTabLabel={activeTabLabel} />;
        }
        return null;
    };
    console.log(analyticsData)

    const isAllDataAvailable = () => {
        return analyticsData['Risk Contribution'] && analyticsData['Factor Analysis'] && analyticsData['Backtest'] && analyticsData['Forward Test'];
    };
    const isGeneratingPdfRef = useRef(false);

    useEffect(() => {
        if (isGeneratingPdf && isAllDataAvailable() && !isGeneratingPdfRef.current) {
            isGeneratingPdfRef.current = true;
            const generatePdf = async () => {
                analyticsData['portfolioDetails'] = portfolioDetails;
                analyticsData['advisorProfile'] = advisorProfile;
                const DownloadPdf = PdfComponent(analyticsData);
                await DownloadPdf();
                setIsGeneratingPdf(false);
                isGeneratingPdfRef.current = false;
            };
            generatePdf();
        }
    }, [isGeneratingPdf, analyticsData, portfolioDetails, advisorProfile]);

    const handleDownloadClick = () => {
        if (!isGeneratingPdfRef.current) {
            setIsGeneratingPdf(true);
        }
    };

    const handleCloseDrawer = (portfolioId) => {
        dispatch(togglePortfolioSelection(portfolioId));
        dispatch(closeDrawer());
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                    <>
                        <div className="drawer-header">
                            <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                            <h1>{drawerData.Name} / {drawerData.portfolio}</h1>
                            <div className="drawerbottom actions">
                                <button className='ai-btn secondary solid'
                                    onClick={() => handleDownloadClick()}
                                    disabled={isGeneratingPdf}>
                                    {isGeneratingPdf ? 'Generating PDF...' : 'Download Report'}
                                </button>
                                <button className='ai-btn primary solid'
                                    onClick={() => dispatch(openDrawer('Recommendation', { portfolioId: drawerData.portfolioId, Name: drawerData.Name, portfolio: drawerData.portfolio, isEmpty: drawerData.isEmpty }))}>Shield Recommendations</button>
                                <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>
                                {/* <button className='ai-btn secondary solid' onClick={() => handleDownloadClick()}>Download Report</button> */}                                
                            </div>
                        </div>
                        <div className="tab">
                            {tabs.map(tab => (
                                <button
                                    key={tab.label}
                                    onClick={() => setActiveTabLabel(tab.label)}
                                    className={activeTabLabel === tab.label ? 'active' : ''}
                                >
                                    {tab.label}
                                    {activeTabLabel === 'Forward Test' && tab.label === 'Forward Test' && (
                                        <span className="beta-v">Beta</span>
                                    )}
                                </button>
                            ))}
                        </div>
                        {tabs.map(tab => renderTabComponent(tab))}

                        {isGeneratingPdf && (
                            <div style={{ position: 'relative', left: '-10000px', top: '-10000px' }}>
                                {tabs.map(tab => {
                                    const Component = tab.component;
                                    return (
                                        <Component
                                            key={tab.key}
                                            portfolioId={drawerData.portfolioId}
                                            isEmpty={drawerData.isEmpty}
                                            isGeneratingPdf={true}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default AnalyticsDrawer;
