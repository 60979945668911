import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./App.scss";
import './global.css';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new Integrations.BrowserTracing()],
  maxBreadcrumbs: 10,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  tracesSampleRate: 1.0,
  relsease: 1.0,
  beforeBreadcrumb(breadcrumbs, hint) {
    if (breadcrumbs.category === "xhr" || breadcrumbs.category === "fetch") {
      return null
    }
    return breadcrumbs;
  }
});

const root = document.getElementById('root');
const reactRoot = createRoot(root);

reactRoot.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
