import React, { useState } from 'react';
import { addZeroes } from "../../Utilities/AddZeros";
import "./BackTestV2.scss";
import {
    ComposedChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid, Tooltip,
    ResponsiveContainer,
    Scatter
} from 'recharts';
import TooltipComponent from '../TooltipComponent/TooltipComponent';

const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        return (
            <div className="custom-tooltip">
                <p class="recharts-tooltip-label">{`${label}`}</p>
                {payload.map((pay, index) => {
                    const originalKey = `original${pay.name.replace(/\s/g, "")}`;
                    const valueToDisplay = pay.payload[originalKey] !== undefined ? pay.payload[originalKey] : pay.value;
                    if (pay.dataKey === 'payoff' || pay.dataKey === "compoundedShieldPayoff") {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${Number(
                                    addZeroes(Number(parseFloat(valueToDisplay).toFixed(2)))
                                ).toLocaleString("en-US", currObj)}`}
                            </p>
                        );
                    } else {
                        return (
                            <p key={index} style={{ color: `${payload[index].color}` }}>
                                {`${pay.name}: ${(valueToDisplay * 100).toFixed(2)}%`}
                            </p>
                        );
                    }
                })}
            </div>
        );
    }

    return null;
};


var currObj = {
    style: "currency",
    currency: "USD",
};

function BacktestV2Graph({ rawData, showPortfolioShield,summary}) {
    const [showPortfolio, setShowPortfolio] = useState(true);
    const [showLimit, setShowLimit] = useState(true);
    const [showMarketShield, setShowMarketShield] = useState(true);
    const [showSP500, setShowSP500] = useState(true);
    // const [showNamePrice, setShowNamePrice] = useState(true);
    // const [showLimitRisk, setShowLimitRisk] = useState(true);

    const riskReturnData = {
        'portfolioShield': 'Data1',
        'sp500': 'Data2',
        'portfolioWithoutShield': 'Data3',
        'riskEquivalent': 'Data4'
    };

    let currentYear = null;

    // Initialize data arrays
    let payoffDetailsMap = {};
    let payoffMSDetailsMap = {};
    let data = [{}, {}, {}, {}];;

    if (rawData && Object.keys(rawData).length > 0) {
        payoffDetailsMap = rawData['payout_details'] ? rawData['payout_details'].reduce((acc, item) => {
            acc[item.payoff_date] = item;
            return acc;
        }, {}) : {};
        payoffMSDetailsMap = rawData['ms_payout_details'] ? rawData['ms_payout_details'].reduce((acc, item) => {
            acc[item.payoff_date] = item;
            return acc;
        }, {}) : {};
        console.log(payoffMSDetailsMap)
        payoffDetailsMap = rawData['payout_details'] ? rawData['payout_details'].reduce((acc, item) => {
            acc[item.payoff_date] = item;
            return acc;
        }, {}) : {};
        const portfolioValues = rawData['portfolio-without-shield'] ? Object.values(rawData['portfolio-without-shield']) : [];
        const limitValues = rawData['limit-drawdown-shield'] ? Object.values(rawData['limit-drawdown-shield']) : [];
        const marketShieldValues = rawData['market-shield'] ? Object.values(rawData['market-shield']) : [];
        const sp500Values = rawData['S&P500'] ? Object.values(rawData['S&P500']) : [];

        const allValues = [...portfolioValues, ...limitValues, ...sp500Values, ...marketShieldValues];
        let minAllData = Math.min(...allValues);

        data = Object.keys(rawData['portfolio-without-shield']).map(date => {
            let item = {
                date: date,
                portfolio: rawData['portfolio-without-shield'][date],
                limit: rawData['limit-drawdown-shield'][date],
                sp500: rawData['S&P500'][date],
                marketShield: rawData['market-shield'][date],
            };

            if (payoffDetailsMap[date]) {
                item.originalPayoff = payoffDetailsMap[date].payoff;
                item.originalCompoundedShieldPayoff = payoffDetailsMap[date].compounded_shield_payoff;
                item.payoff = minAllData * 0.95;
                item.compoundedShieldPayoff = minAllData;
            }
            if (payoffMSDetailsMap[date]) {
                item.originalMSPayoff = payoffMSDetailsMap[date].payoff;
                item.originalMSCompoundedShieldPayoff = payoffMSDetailsMap[date].compounded_shield_payoff;
                item.MSpayoff = minAllData * 0.95;
                item.MScompoundedShieldPayoff = minAllData;
            }
            return item;
        });
    }

    const formatXAxis = (tickItem) => {
        const year = new Date(tickItem).getFullYear();
        const month = new Date(tickItem).getMonth();
        if (year !== currentYear) {
            currentYear = year;
            return year;
        }
        else {
            return '';
        }
    }

    const formatYAxis = (tick) => {
        return `${(tick * 100).toFixed(2)}%`;
    }

    function SimulationEndValues({ simulationEndValues }) {
        var dataKeyToValue = {
            "Backtest Starting Value": rawData["backtest-starting-value"],
            "Portfolio Without Shield": simulationEndValues['portfolio-without-shield'],
            "Portfolio With Market Shield": simulationEndValues['market_shield'],
            "Portfolio With Guaranteed Shield": simulationEndValues['limit-drawdown-shield'],
            "S&P 500": simulationEndValues['sp500'],
        };
        if (showPortfolioShield === false) {
            delete dataKeyToValue["Portfolio With Guaranteed Shield"]
        }

        return (
            <div style={{ padding: '10px' }}>
                {Object.entries(dataKeyToValue).map(([key, value]) => {
                    let formattedValue = Number(
                        Number(parseFloat(value).toFixed(2))
                    ).toLocaleString("en-US", currObj);

                    if (key === "S&P 500") {
                        return (
                            <p>{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${Number(parseFloat(value).toFixed(2)).toLocaleString("en-US")}`}</p>
                        );
                    } else {
                        return (
                            <p>{`${key.charAt(0).toUpperCase() + key.slice(1)}: ${formattedValue}`}</p>
                        );
                    }
                })}
            </div>
        );
    }

    return (
        <div>
            <div style={{ display: "flex", width: "100%" }}>
                <div id="backtestgraph" style={{ width: Object.keys(rawData).length > 0 ? "85%" : "90%", marginRight: "0px" }}>
                    <ResponsiveContainer width="100%" height={500}>
                        <ComposedChart
                            data={data}
                            margin={{
                                top: 5,
                                right: 30,
                                left: 20,
                                bottom: 5
                            }}
                        >
                            <CartesianGrid stroke="#ccc" vertical={false} />
                            <XAxis dataKey="date" tickFormatter={formatXAxis} interval='preserveStartEnd' tickLine={false} />
                            <YAxis yAxisId="left" tickFormatter={formatYAxis} axisLine={false} />
                            {/* <YAxis yAxisId="payoff" orientation="bottom" hide={true} domain={[minPayoff, maxPayoff]} /> */}
                            <Tooltip content={<CustomTooltip />} />
                            {showPortfolioShield && showLimit &&
                                <Line
                                    yAxisId="left"
                                    dot={false}
                                    type="monotone"
                                    dataKey="limit"
                                    name="Portfolio With Shield"
                                    stroke="#ED6663"
                                />}
                            {showPortfolio && <Line yAxisId="left" dot={false} type="monotone" dataKey="portfolio" name="Portfolio without Shield" stroke="#54C1FF" />}
                            {showMarketShield && <Line yAxisId="left" dot={false} type="monotone" dataKey="marketShield" name="Portfolio With Market Shield" stroke="#800000" />}
                            {showSP500 && <Line yAxisId="left" dot={false} type="monotone" dataKey="sp500" name="S&P 500" stroke="#FFA654" />}
                            {/* {showNamePrice && <Line yAxisId="left" dot={false} type="monotone" dataKey="name_your_price" name="Name-Your-Price Shield" stroke="#96BE25" />} */}
                            {/* {showLimitRisk && <Line yAxisId="left" dot={false} type="monotone" dataKey="limit_risk" name="Dial Down Risk" stroke="#EC4899" />} */}
                            <Scatter yAxisId="left" dataKey="MSpayoff" name="Market Shield Payoff" fill="#800000" />
                            <Scatter yAxisId="left" dataKey="MScompoundedShieldPayoff" name="Compounded Market Shield Payoff" fill="#ED6663" />
                            {showPortfolioShield &&
                                <><Scatter yAxisId="left" dataKey="payoff" name="Payoff" fill="#60034C" />
                                    <Scatter yAxisId="left" dataKey="compoundedShieldPayoff" name="Compounded Shield Payoff" fill="#E58B76" />
                                </>}
                        </ComposedChart>
                    </ResponsiveContainer>
                </div>
                { <div id="backtestsimulation" style={{ width: "10%"  }}>
                {Object.keys(rawData).length > 0 && <SimulationEndValues simulationEndValues={rawData['simulation-end-values']} />}
                </div>}
            </div>
            {summary && <div id='backtestcheckbox' style={{ textAlign: "center", padding: "20px 0" }}>
                <label style={{ color: "#800000", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#800000" }} checked={showMarketShield} onChange={(e) => setShowMarketShield(e.target.checked)} />
                    Portfolio With Market Shield
                </label>


                <label style={{ color: "#54C1FF", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#54C1FF" }} checked={showPortfolio} onChange={(e) => setShowPortfolio(e.target.checked)} />
                    Portfolio Without Shield
                </label>
                {showPortfolioShield && <label style={{ color: "#ED6663", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#ED6663" }} checked={showLimit} onChange={(e) => setShowLimit(e.target.checked)} />
                    Portfolio With Portfolio shield
                </label>}
                <label style={{ color: "#FFA654", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#FFA654" }} checked={showSP500} onChange={(e) => setShowSP500(e.target.checked)} />
                    S&P 500
                </label>
                {/* <label style={{ color: "#96BE25", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#96BE25" }} checked={showNamePrice} onChange={(e) => setShowNamePrice(e.target.checked)} />
                    Name Your Price Shield
                </label>
                <label style={{ color: "#EC4899", marginRight: "20px" }}>
                    <input type="checkbox" style={{ accentColor: "#EC4899" }} checked={showLimitRisk} onChange={(e) => setShowLimitRisk(e.target.checked)} />
                    Dial Down Risk
                </label> */}
            </div>
            /* <div id='backtestriskreturnratio' style={{ textAlign: "center", padding: "20px 0" }}>
                <table className="risk-return-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Portfolio With Market Shield</th>

                            <th>Portfolio Without Shield</th>
                            {showPortfolioShield && <th>Portfolio with Portfolio Shield</th>}
                            <th>S&P 500</th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                            <td>Annual Returns</td>
                            <td>{parseFloat(parseFloat(rawData['returns']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                            <td>{parseFloat(parseFloat(rawData['returns']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                            {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['returns']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                            <td>{parseFloat(parseFloat(rawData['returns']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                        </tr>

                        <tr>
                            <td>Downside Risk</td>
                            <td>{parseFloat(parseFloat(rawData['downside_risk']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                            <td>{parseFloat(parseFloat(rawData['downside_risk']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                            {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['downside_risk']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                            <td>{parseFloat(parseFloat(rawData['downside_risk']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                        </tr>
                        <tr>
                            <td>Return/Risk Ratio<TooltipComponent id={'Risk Return Ratio'} /></td>
                            <td>{parseFloat(rawData['return_ratio']['market_shielded_ratio']).toFixed(2)}</td>

                            <td>{parseFloat(rawData['return_ratio']['unshielded_ratio']).toFixed(2)}</td>
                            {showPortfolioShield && <td>{parseFloat(rawData['return_ratio']['shielded_ratio']).toFixed(2)}</td>}
                            <td>{parseFloat(rawData['return_ratio']['sp500_ratio']).toFixed(2)}</td>
                        </tr>
                    </tbody>
                </table>
            </div> */}
        </div>
    );
}

export default BacktestV2Graph;
