import "react-datepicker/dist/react-datepicker.css";
import "../../../node_modules/slick-carousel/slick/slick.css";
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import React, { useEffect, useState } from "react";
import { Tab, Tabs } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Slider from "react-slick";
import "./BackTestV2.scss";
import Loader from "../../Components/Loader/Loader";
import TagManager from "react-gtm-module";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { keyEvents } from "../../Utilities/BackTestEvents";
import BacktestV2RiskTable from "./BackTestV2RiskTable";
import BacktestV2Graph from "./BackTestV2Graph";
import BackTestV2Table from "./BackTestV2Table";
import { GetBacktestGraphData } from "../../Apis/GetBacktestGraphData";
import { useDispatch, useSelector } from "react-redux";
import {
  handleApiError,
  showConfirmNotification,
  showNotification,
} from "../../actions/notificationActions";
import { toPng } from "html-to-image";
import { updatePdfData } from "../../actions/pdfDataActions";
import TooltipComponent from "../TooltipComponent/TooltipComponent";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " ai-slide-next"}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className + " ai-slide-prev"}
      style={{ ...style, display: "block" }}
      onClick={onClick}
    ></div>
  );
}

export function BackTestV2({ portfolioId, isEmpty, isGeneratingPdf }) {
  const backtestApiData =
    useSelector((state) => state.pdfData["Backtest"]) || {};
  const events = {
    dots: false,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    autoplay: false,
    infinite: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const defaultValues = {
    btlevel: 0.9,
    period: 30,
    startDate: new Date(new Date().setMonth(new Date().getMonth() - 12)),
    endDate: new Date(new Date().setDate(new Date().getDate() - 7)),
    event: "",
  };

  const allData = backtestApiData.allData || {};

  const [Flag, setFlag] = React.useState(false);
  const [bdopen, setBdOpen] = React.useState(false);
  const [GotBTData, setGotBTData] = React.useState(false);
  const [BtData, setBtData] = React.useState([]);
  const [BtLevel, setBtLevel] = React.useState(
    allData.btlevel ?? defaultValues.btlevel
  );
  const [period, setPeriod] = React.useState(
    allData.period ?? defaultValues.period
  );
  const [startDate, setStartDate] = React.useState(
    new Date(allData.startDate ?? defaultValues.startDate)
  );
  const [endDate, setEndDate] = React.useState(
    new Date(allData.endDate ?? defaultValues.endDate)
  );

  const [popupState, setPopupState] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState("");
  const [alertType, setAlertType] = React.useState("");
  const [showEvents, setShowEvents] = React.useState(false);
  const [EventBtData, setEventBtData] = React.useState([]);
  const [PortfolioDetailsTable, setPortfolioDetailsTable] = React.useState([]);
  const [SimulationDetailsTable, setSimulationDetailsTable] = React.useState(
    []
  );
  const [PayoutDetailsTable, setPayoutDetailsTable] = React.useState([]);
  const [EventPortfolioDetailsTable, setEventPortfolioDetailsTable] =
    React.useState([]);
  const [EventSimulationDetailsTable, setEventSimulationDetailsTable] =
    React.useState([]);
  const [EventPayoutDetailsTable, setEventPayoutDetailsTable] = React.useState(
    []
  );
  const [selectedPortfolioTotalValue, setSelectedPortfolioTotalValue] =
    React.useState(0);
  const [PortfolioEmpty, setPortfolioEmpty] = React.useState(false);
  const [validationMessage, setValidationMessage] = React.useState("");
  const [alertMessage, setAlertMessage] = React.useState("");
  const [historicalEventSelected, setHistoricalEventSelected] = React.useState(
    allData.event ?? defaultValues.event
  );
  const [showPortfolio, setShowPortfolio] = React.useState(false);
  const [activePeriodBtn, setActivePeriodBtn] = React.useState("");

  const dispatch = useDispatch();
  console.log("backtestApiData", backtestApiData);

  const levels = [
    { percent: 0, level: "Select" },
    { percent: 0.8, level: "80%" },
    { percent: 0.85, level: "85%" },
    { percent: 0.9, level: "90%" },
    { percent: 0.95, level: "95%" },
    { percent: 1.0, level: "100%" },
  ];
  
  const periods = [
    { period_int: 0, period_value: "Select" },
    { period_int: 30, period_value: "1 Month" },
    { period_int: 60, period_value: "2 Months" },
    { period_int: 91, period_value: "3 Months" },
    { period_int: 182, period_value: "6 Months" },
    { period_int: 365, period_value: "Year" },
  ];
  const periodButtons = [
    {
      text: "1y",
      period_int: 365,
    },
    {
      text: "2y",
      period_int: 365 * 2,
    },
    {
      text: "3y",
      period_int: 365 * 3,
    },
    {
      text: "5y",
      period_int: 365 * 5,
    },
    {
      text: "10y",
      period_int: 365 * 10,
    },
  ];

  const changeLevel = (percent) => {
    setBtLevel(percent);
  };

  const changePeriod = (period_value) => {
    setPeriod(period_value);
  };
  useEffect(() => {
    if (period !== 0 && bdopen === true) {
      setAlertMessage(
        "Note: Protection period reduced to the length of time for the selected period."
      );
      runBackTest(startDate, endDate, false, true, "",""); // replace with your actual function
    }
  }, [period]);

  const captureAndDispatchData = async (
    data,
    period,
    BtLevel,
    startDate,
    endDate,
    title,
    periodSelected
  ) => {
    if (Object.keys(data).length > 0) {
      if (document.getElementById("backtestGraph")) {
        try {
          await new Promise((resolve) => setTimeout(resolve, 3000));
          const image1 = await toPng(document.getElementById("backtestGraph"));
          const pdf_data = {
            backTestImgData: image1,
            activeTab: "Backtest",
            simulationDetailsTable: data["simulation_details"],
            payoutDetailsTable: data["payout_details"],
          };
          dispatch(
            updatePdfData("Backtest", {
              pdf: pdf_data,
              allData: {
                btdata: data,
                period: period,
                btlevel: BtLevel,
                startDate: startDate,
                endDate: endDate,
                event: title,
                periodSelected:periodSelected
              },
              portfolioid: portfolioId,
              dataFlag: "Data Available",
            })
          );
        } catch (error) {
          console.error("Error capturing images:", error);
          dispatch(
            updatePdfData("Backtest", {
              portfolioid: portfolioId,
              dataFlag: "Data Not Available",
            })
          );
        }
      }
    } else {
      dispatch(
        updatePdfData("Backtest", {
          portfolioid: portfolioId,
          dataFlag: "Data Not Available",
        })
      );
    }
  };

  const validateInput = (datediff, periodAdjusted) => {
    if (!periodAdjusted) {
      if (portfolioId === 0) {
        setValidationMessage("Please select a valid Portfolio");
        setBdOpen(false);
        return false;
      }
      if (period === 0) {
        setValidationMessage(
          "Protection period not recognized. Please try again or contact support@adaptive-investments.com."
        );
        setBdOpen(false);
        return false;
      }
      if (BtLevel === 0) {
        setValidationMessage(
          "Protection level not recognized. Please try again or contact support@adaptive-investments.com."
        );
        setBdOpen(false);
        return false;
      }
      if (datediff < period) {
        // Change condition to strictly less than
        const eligiblePeriods = periods.filter(
          (p) => p.period_int <= datediff && p.period_int !== 0
        );
        if (eligiblePeriods.length === 0) {
          setValidationMessage(
            "Please select a wider backtest window than the protection period to re-run the Backtest."
          );
          setBdOpen(false);
          return false;
        }
        setPeriod(eligiblePeriods[eligiblePeriods.length - 1].period_int);
      }
      setValidationMessage("");
      setAlertMessage("");
      return true;
    } else {
      setValidationMessage("");
      return true;
    }
  };

  // function validateInput(datediff, periodAdjusted) {
  //     console.log(" valida input")
  //     console.log(periodAdjusted)
  //     if (periodAdjusted !== true) {
  //         if (portfolioId === 0) {
  //             setValidationMessage("Please select a valid Portfolio");
  //             setBdOpen(false);
  //             return false;
  //         }
  //         else if (period === 0) {
  //             console.log("date period")
  //             setValidationMessage("Protection period not recognized. Please try again or contact support@adaptive-investments.com.");
  //             setBdOpen(false);
  //             return false;
  //         }
  //         else if (BtLevel === 0) {
  //             console.log("date btlevel")
  //             setValidationMessage("Protection level not recognized. Please try again or contact support@adaptive-investments.com.");
  //             setBdOpen(false);
  //             return false;
  //         }
  //         else if (datediff <= period) {
  //             console.log("diff")
  //             const eligiblePeriods = periods.filter(period => period.period_int <= datediff && period.period_int !== 0);
  //             console.log(eligiblePeriods)
  //             // If there are no eligible periods, return undefined or some default value
  //             if (eligiblePeriods.length === 0) {
  //                 setValidationMessage("Please select a wider backtest window than the protection period to re-run the Backtest.");
  //                 setBdOpen(false)
  //                 return false;
  //                 // or return { period_int: 0, period_value: "Select" };
  //             }

  //             // Get the closest smaller period (the last one in the filtered array)

  //             setPeriod(eligiblePeriods[eligiblePeriods.length - 1].period_int);
  //         }
  //         //if start year < 2017 or end year < 2017 or start year > current year or end year > current year or start year > end year raise error
  //         // else if (startDate.getFullYear() > new Date().getFullYear() || endDate.getFullYear() > new Date().getFullYear() || startDate.getFullYear() > endDate.getFullYear()) {
  //         //     console.log("end")
  //         //     setValidationMessage("Cannot run backtest for the selected date range. Please limit your date range from 2017 to current date.");
  //         //     setBdOpen(false);
  //         //     return false;

  //         // }
  //         else {
  //             setValidationMessage("");
  //             setAlertMessage("")
  //             return true;
  //         }
  //     }
  //     else {
  //         setValidationMessage("");
  //         return true;
  //     }
  // }

  const setApiData = (data, date1, date2, datediff, title,periodSelected) => {
    setBtData([]);
    if (
      data === "Empty Portfolio" ||
      data === "Data not found for the given ticker(s)"
    ) {
      setPortfolioEmpty(true);
      setBdOpen(false);
      return;
    }
    // setSelectedPortfolioTotalValue(data.portfolio_details.reduce((sum,portfolio) => {
    //     return sum + portfolio['Market Value']
    // },0));
    if (data === false) {
      setGotBTData(true);
      setBdOpen(false);
      setPopupState(true);
      setFlag(false);
      dispatch(
        showNotification(
          "Something went wrong. Please try again",
          "error",
          "toaster"
        )
      );
      if (date1 >= date2) {
        setAlertType("warning");
        setPopupMessage(
          "Dates not recognized. Please try again or contact support@adaptive-investments.com."
        );
        return;
      } else if (datediff <= 30) {
        setAlertType("warning");
        setPopupMessage(
          "Backtest dates require at least one month. Please try again or contact support@adaptive-investments.com."
        );
        return;
      } else {
        setAlertType("error");
        setPopupMessage(
          "Unable to run backtest. Please try again or contact support@adaptive-investments.com."
        );
        return;
      }
    } else if (data === 0) {
      setBdOpen(false);
      setGotBTData(true);
      setPopupState(true);
      setPopupMessage(
        "Server issue. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      return;
    } else if (data === "Empty Portfolio") {
      setBdOpen(false);
      setGotBTData(true);
      setPopupState(true);
      setPopupMessage(
        "Your portfolio does not have any recognized tickers or is empty. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      return;
    } else if (data === "Data not found for the given ticker(s)") {
      setBdOpen(false);
      setGotBTData(true);
      setPopupState(true);
      setPopupMessage(
        "None of the tickers in your portfolio have data for the selected period. Please try again or contact support@adaptive-investments.com."
      );
      setAlertType("warning");
      return;
    } else {
      setGotBTData(true);

      setBtData(data);
      setPortfolioDetailsTable(data["portfolio_details"]);
      setSimulationDetailsTable(data["simulation_details"]);
      setPayoutDetailsTable(data["payout_details"]);
      // setEventBtData([]);
      // } else {
      setEventBtData(data);
      // setEventPortfolioDetailsTable(data["portfolio_details"]);
      setEventSimulationDetailsTable(data["simulation_details"]);
      setEventPayoutDetailsTable(data["payout_details"]);
      //     // setBtData([]);
      // }
      setBdOpen(false);
      setTimeout(() => {
        captureAndDispatchData(
          data,
          period,
          BtLevel,
          new Date(date1),
          new Date(date2),
          title,
          periodSelected
        );
      }, 1000);
      TagManager.dataLayer({
        dataLayer: {
          event: "Back Test",
          userType: sessionStorage.getItem("selectedAcType"),
          user_id: sessionStorage.getItem("clientId"),
        },
      });
    }
  };
  function runBackTest(start, end, showEventsGraph, periodAdjusted, title, periodSelected) {
    setBdOpen(true);
    let date1;
    let date2;
    let datediff;
    if (showEventsGraph !== false) {
      date1 = start;
      date2 = end;
      console.log(" date1", date1, date2);
      const diffTime = Math.abs(new Date(date2) - new Date(date1));
      datediff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    } else {
      date1 = startDate.toISOString().split("T")[0].toString();
      date2 = endDate.toISOString().split("T")[0].toString();
      setActivePeriodBtn("");
      setHistoricalEventSelected("");
      datediff = (
        (endDate.getTime() - startDate.getTime()) /
        (1000 * 3600 * 24)
      ).toFixed(0);
    }
    if (validateInput(datediff, periodAdjusted)) {
      if (
        backtestApiData &&
        backtestApiData.portfolioid === portfolioId &&
        period === backtestApiData.allData.period &&
        BtLevel === backtestApiData.allData.btlevel &&
        backtestApiData.dataFlag === "Data Available" &&
        date1 ===
          backtestApiData.allData.startDate
            .toISOString()
            .split("T")[0]
            .toString() &&
        date2 ===
          backtestApiData.allData.endDate.toISOString().split("T")[0].toString()
      ) {
        setPeriod(backtestApiData.allData.period);
        setBtLevel(backtestApiData.allData.btlevel);
        setStartDate(backtestApiData.allData.startDate);
        setEndDate(backtestApiData.allData.endDate);
        setHistoricalEventSelected(backtestApiData.allData.event);
        setActivePeriodBtn(backtestApiData.allData.periodSelected);
        setApiData(
          backtestApiData.allData.btdata,
          date1,
          date2,
          datediff,
          title
        );
      } else {
        GetBacktestGraphData(portfolioId, date1, date2, BtLevel, period).then(
          (data) => {
            setApiData(data, date1, date2, datediff, title,periodSelected);
          }
        );
      }
    }
  }

  useEffect(() => {
    if (!isEmpty && portfolioId !== undefined) {
      runBackTest(startDate, endDate, false, false, "","");
    }
  }, [portfolioId]);

  const historicalEvents = (start, end, title) => {
    let showEventsGraph = true;
    let newStart = new Date(start);
    let newEnd = new Date(end);
    setStartDate(newStart);
    setEndDate(newEnd);
    runBackTest(start, end, showEventsGraph, false, title,"");
    setHistoricalEventSelected(title);
    setActivePeriodBtn("");
  };
  const handlePeriodSelect = (periodInt) => {
    
    let showEventsGraph = true;

    let newStart = new Date(
      new Date().setDate(new Date().getDate() - periodInt - 5)
    );
    let newEnd = new Date(new Date().setDate(new Date().getDate() - 5));
    setStartDate(newStart);
    setEndDate(newEnd);
    setActivePeriodBtn(periodInt);
    setHistoricalEventSelected("");
    runBackTest(
      newStart.toISOString().split("T")[0].toString(),
      newEnd.toISOString().split("T")[0].toString(),
      showEventsGraph,
      false,
      "",
      periodInt
    );
  };
  return (
    <>
      {bdopen ? (
        <div className="loading-overlay">
          <Loader />
        </div>
      ) : (
        <div className="drawercontent">
          <div className="details_wrap">
            <div className="shieldtitle">
              <h3>Market Shield Backtest</h3>
              <span>
                <input
                  type="checkbox"
                  onChange={(e) => setShowPortfolio(e.target.checked)}
                  checked={showPortfolio}
                />{" "}
                Include Portfolio Shield
              </span>
            </div>
            <div className="infocard-wrap">
              <div id="selectprotectionlevel" className="infocard">
                <h3>
                  Protection Level <TooltipComponent id={"Protection Level"} />
                </h3>
                <Select
                  className="ai-form-value-bt"
                  onChange={(event) => changeLevel(event.target.value)}
                  value={BtLevel}
                >
                  {levels.map((value, i) => (
                    <MenuItem value={value.percent}>{value.level}</MenuItem>
                  ))}
                </Select>
                <span className="ai-backtest-select-arrow"></span>
              </div>
              <div id="selectprotectionperiod" className="infocard">
                <h3>Protection Period</h3>
                <Select
                  className="ai-form-value-bt"
                  onChange={(event) => changePeriod(event.target.value)}
                  value={period}
                >
                  {periods.map((value, i) => (
                    <MenuItem value={value.period_int}>
                      {value.period_value}
                    </MenuItem>
                  ))}
                </Select>
                <span className="ai-backtest-select-arrow"></span>
              </div>
            </div>
          </div>
          {/* <Tabs
                        defaultActiveKey="daterange"
                        id="uncontrolled-tab-example"
                        className="ai-tabs ai-backtest-tabs"
                    > */}
          {/* <Tab eventKey="daterange" title="Date Range"> */}
          <Slider {...events} className="ai-backtest-slider-bt">
            {keyEvents.map((value, i) => (
              <a
                className={
                  historicalEventSelected === value.title
                    ? "ai-backtest-event-bt active"
                    : "ai-backtest-event-bt"
                }
                onClick={() =>
                  historicalEvents(
                    value.eventStart,
                    value.eventEnd,
                    value.title
                  )
                }
                value={historicalEventSelected}
              >
                <p>{value.title}</p>
                <p className="ai-event-timestamp-bt">
                  {value.eventStart} to {value.eventEnd}
                </p>
              </a>
            ))}
          </Slider>
          {validationMessage && (
            <div className="validation-message" style={{ color: "red" }}>
              {validationMessage}
            </div>
          )}
          {alertMessage && (
            <div className="validation-message" style={{ color: "orange" }}>
              {alertMessage}
            </div>
          )}
          {
            <>
              {Object.keys(BtData).length > 0 && (
                <div id="backtestGraph" className="ai-backtest-graph">
                  <BacktestV2Graph
                    rawData={BtData}
                    showPortfolioShield={showPortfolio}
                  />
                  <div className="ai-date-selection">
                    <div className="ai-years">
                      {periodButtons.map((button) => (
                        <button
                          key={button.period_int} // Unique key for each button
                          className={
                            activePeriodBtn === button.period_int ? "active" : ""
                          }
                          onClick={() => handlePeriodSelect(button.period_int)}
                        >
                          {button.text}
                        </button>
                      ))}
                    </div>
                    <DatePicker
                      className="ai-form-value"
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      dateFormat="yyyy-MM-dd"
                      placeholderText="From Date"
                      // minDate={new Date("01-01-2017")}
                    />

                    <DatePicker
                      className="ai-form-value"
                      selected={endDate}
                      onChange={(date) => setEndDate(date)}
                      dateFormat="yyyy-MM-dd"
                      maxDate={new Date()}
                      placeholderText="To Date"
                    />

                    <button
                      id="runtestbutton"
                      className="ai-btn primary solid small"
                      onClick={() =>
                        runBackTest(startDate, endDate, showEvents, false, "","")
                      }
                      disabled={isEmpty}
                    >
                      Run Test
                    </button>
                    <br />
                    {isEmpty && (
                      <p>
                        {" "}
                        Portfolio is Empty. Please add stocks to run the test.
                      </p>
                    )}
                  </div>
                  <BacktestV2RiskTable
                    rawData={BtData}
                    showPortfolioShield={showPortfolio}
                  />
                </div>
              )}

              {Object.keys(BtData).length > 0 && (
                <BackTestV2Table
                  showPortfolioShield={showPortfolio}
                  PortfolioDetailsTable={PortfolioDetailsTable}
                  selectedPortfolioTotalValue={selectedPortfolioTotalValue}
                  SimulationDetailsTable={SimulationDetailsTable}
                  PayoutDetailsTable={PayoutDetailsTable}
                  ProtectionLevel={BtLevel * 100}
                  FinalBacktestPortfolioValue={
                    BtData["simulation-end-values"]["limit-drawdown-shield"]
                  }
                />
              )}
            </>
          }
          {/* </Tab> */}
          {/* <Tab eventKey="historyevents" title="Historical Events"> */}
          {/* 
                            {EventBtData.length !== 0 &&
                                <>
                                    <div className="ai-backtest-graph">
                                        <BacktestV2Graph rawData={EventBtData} />
                                    </div>
                                    <BackTestV2Table
                                        PortfolioDetailsTable={EventPortfolioDetailsTable}
                                        selectedPortfolioTotalValue={selectedPortfolioTotalValue}
                                        SimulationDetailsTable={EventSimulationDetailsTable}
                                        PayoutDetailsTable={EventPayoutDetailsTable}
                                    />
                                </>
                            } */}
          {/* </Tab> */}
          {/* </Tabs> */}
        </div>
      )}
    </>
  );
}
export default BackTestV2;
