import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleItemSelection,
  setAllSelected,
  initializeSelections,
  setGlobalCapLevel,
  setExpDate,
  setLocalFilters
} from '../../actions/selectionActions';
import './Dashboard.css';
import DashboardSection from '../ChainDashboard/ChainDashboard';
import { formatDate } from '../../Utilities/FormatContract';
import ShoppingCart from '../ShieldRecommendation/ShoppingCart';
import { addZeroes } from "../../Utilities/AddZeros";

function Dashboard({ portfolioId, isEmpty, portfolioDetails, tabType }) {

  var currObj = {
    style: "currency",
    currency: "USD",
  };

  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const [isLoading, setisLoading] = useState(true);
  // const [GlobalCapLevel, setGlobalCapLevel] = useState(tabType === 'CallWriting' ? 110 : 90);
  // const [ExpDate, setExpDate] = useState(new Date(new Date().setMonth(new Date().getMonth() + 1)).toISOString().slice(0, 10));
  const { selectedItems, allSelected, GlobalCapLevel, ExpDate, localFilters ={} } = useSelector(state => state.selection[tabType]);

  const [FilteredData, setFilteredData] = useState({});
  // const [localFilters, setLocalFilters] = useState(() => {
  //   const savedFilters = tabType === 'CallWriting' ? sessionStorage.getItem('localFiltersCallwriting') : sessionStorage.getItem('localFiltersPutBuying');
  //   return savedFilters ? JSON.parse(savedFilters) : {};
  // });
  const [TransfomedData, setTransformedData] = useState([]);
  const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
  const [totalPremium, setTotalPremium] = useState(0);
  const [showDashboardSection, setShowDashboardSection] = useState(false)
  const [priceToShow, setPriceToShow] = useState(tabType === 'CallWriting' ? 'Bid' : 'Ask');
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const { data } = useSelector(state => state.options);
  const [optionData, setOptionData] = useState({});
  const [shoppingFlag, setShoppingFlag] = useState(false)
  const [currentSelections, setCurrentSelections] = useState(() => {
    const savedSelections = sessionStorage.getItem('currentSelections');
    return savedSelections ? JSON.parse(savedSelections) : {};
  });
  const headingStyle = {
    textDecoration: 'underline',
    cursor: 'pointer'
  };
  // const [selectedItems, setSelectedItems] = useState({});
  const [selectedTicker, setSelectedTicker] = useState(null);
  // const allSelected = Object.values(selectedItems).every(Boolean);
  const parseDate = (dateStr) => new Date(dateStr);
  const formatDateToYYYYMMDD = (date) => {
    // console.log("Formatting date:", date);
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  };

  const changePriceToShow = (price) => {
    setPriceToShow(price);
    setDropdownVisible(false);
  };


  const findBestOption = (options, capLevelPercentage, targetDateStr, ticker) => {
    let bestOption = null;
    const targetDate = parseDate(targetDateStr);
    const sortedOptions = options.sort((a, b) => {
      const dateDiffA = Math.abs(parseDate(a.ExpirationDate) - targetDate);
      const dateDiffB = Math.abs(parseDate(b.ExpirationDate) - targetDate);

      if (dateDiffA !== dateDiffB) {
        return dateDiffA - dateDiffB;
      } else {
        const strikeDiffA = Math.abs(a.Strike - (a.ClosePrice * capLevelPercentage / 100));
        const strikeDiffB = Math.abs(b.Strike - (b.ClosePrice * capLevelPercentage / 100));
        return strikeDiffA - strikeDiffB;
      }
    });
    bestOption = sortedOptions[0];
    return bestOption;
  };


  const calculateTotalPremium = () => {
    return Object.entries(FilteredData).reduce((total, [ticker, [bestOption]]) => {
      if (selectedItems[ticker]) { // Check if the item is selected
        const portfolioEntry = TransfomedData[ticker];
        const shares = portfolioEntry ? portfolioEntry.quantity : 0;
        const noOfContracts = Math.floor(shares / 100);
        total += parseFloat(tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice) * 100 * noOfContracts // Adjust this line according to how you access the premium value
      }
      return total;
    }, 0);
  };


  const handleSelectionChange = (ticker) => {
    dispatch(toggleItemSelection(ticker, !selectedItems[ticker], tabType));
  };

  useEffect(() => {
    if (Object.keys(selectedItems).length === 0 && portfolioDetails.PortfolioLineItem.Hedgeable) {
      const initialData = portfolioDetails.PortfolioLineItem.Hedgeable.reduce((acc, item) => {
        acc[item.Ticker] = tabType === 'CallWriting';
        return acc;
      }, {});
      dispatch(initializeSelections(initialData, tabType));
    }
  }, [dispatch, portfolioDetails.PortfolioLineItem.Hedgeable, tabType]);

  useEffect(() => {
    let newTotalPremium = calculateTotalPremium();
    if (newTotalPremium !== totalPremium) {
      setTotalPremium(newTotalPremium);
    }
  }, [FilteredData, selectedItems]);

  const handleSelectAllChange = () => {
    dispatch(setAllSelected(!allSelected, tabType));
  };

  useEffect(() => {
    let newTotalValue = 0;
    let newTickerData = {};
    console.log(localFilters)
    Object.values(TransfomedData).forEach(stock => {
      newTotalValue += stock.quantity * stock.price;
    });

    setTotalPortfolioValue(newTotalValue);
    sessionStorage.setItem('totalPortfolioValue', JSON.stringify(newTotalValue));

    let newTotalPremium = 0;

    Object.entries(FilteredData).forEach(([ticker, [bestOption]]) => {
      if (bestOption) {
        console.log("localFilters[ticker].premium ", localFilters[ticker] )
        // Check if an entry exists in localFilters for this ticker and use its premium if available
        if (localFilters[ticker] && localFilters[ticker].premium !== undefined) {
          newTotalPremium += localFilters[ticker].premium;
          const noOfContracts = localFilters[ticker].noOfContracts;
          newTickerData[ticker] = {
            noOfContracts,
            premium: localFilters[ticker].premium,
            premiumPercentage: localFilters[ticker].premiumPercentage
          };
        } else {
          // Fallback to calculating premium if no entry exists in localFilters for this ticker
          const portfolioEntry = TransfomedData[ticker];
          const shares = portfolioEntry ? portfolioEntry.quantity : 0;
          const noOfContracts = Math.floor(shares / 100);
          const premium = tabType === 'CallWriting' ? parseFloat(bestOption.CloseBidPrice) * 100 * noOfContracts : parseFloat(bestOption.CloseAskPrice) * 100 * noOfContracts;
          newTotalPremium += premium;
          newTickerData[ticker] = {
            noOfContracts,
            premium: premium.toFixed(2),
            premiumPercentage: (premium / (shares * bestOption.ClosePrice)) * 100
          };
        }
      }
    });

    setTotalPremium(newTotalPremium);
    tabType === 'CallWriting' ? sessionStorage.setItem('totalPremium', JSON.stringify(newTotalPremium)) : sessionStorage.setItem('totalCost', JSON.stringify(newTotalPremium));
    console.log('newTickerData', newTickerData);
    sessionStorage.setItem('tickerData', JSON.stringify(newTickerData));

  }, [FilteredData, localFilters, TransfomedData]);


  const handleLocalFilterChange = (ticker, newCapLevel, newExpDate) => {
    const updatedCapLevel = newCapLevel || localFilters[ticker]?.capLevel || GlobalCapLevel;
    const updatedExpDate = newExpDate || localFilters[ticker]?.expDate || ExpDate;

    const newLocalFilters = {
      ...localFilters,
      [ticker]: {
        ...localFilters[ticker],
        capLevel: updatedCapLevel,
        expDate: updatedExpDate,
      }
    };

    dispatch(setLocalFilters(newLocalFilters, tabType));
    updateFilteredDataForTicker(ticker, updatedCapLevel, updatedExpDate);
  };

  const updateFilteredDataForTicker = (ticker, newCapLevel, newExpDate) => {
    const tickerOptions = optionData[ticker];
    if (!tickerOptions) return;

    const bestOption = findBestOption(tickerOptions, newCapLevel || GlobalCapLevel, newExpDate, ticker);
    if (bestOption) {
      setFilteredData(prevFilteredData => ({
        ...prevFilteredData,
        [ticker]: [bestOption]
      }));

      const parsedDate = parseDate(bestOption.ExpirationDate);
      const formattedDate = formatDateToYYYYMMDD(parsedDate);
      const updatedFilters = {
        ...localFilters,
        [ticker]: {
          ...localFilters[ticker],
          expDate: formattedDate,
          capLevel: newCapLevel
        }
      };
      dispatch(setLocalFilters(updatedFilters, tabType));
    }
  };

  const GoToOptionChainScreen = (ticker) => {
    setSelectedTicker(ticker);
    setShowDashboardSection(true);
  }

  const handleGlobalChange = (newCapLevel, newExpDate) => {
    if (newCapLevel !== undefined) {
      dispatch(setGlobalCapLevel(newCapLevel, tabType));
    }
    if (newExpDate !== undefined) {
      dispatch(setExpDate(newExpDate, tabType));
    }
    Object.entries(optionData).forEach(([ticker, options]) => {
      const bestOption = findBestOption(options, newCapLevel, newExpDate, ticker);
      if (bestOption) {
        setFilteredData(prevFilteredData => ({ ...prevFilteredData, [ticker]: [bestOption] }));
      }
    });
  };


  const filterData = (newCapLevel, newExpDate) => {
    if (!optionData) {
      console.error('Option data is not available for filtering');
      return;
    }

    const newData = {};
    let selectionsUsed = false;

    Object.entries(optionData).forEach(([ticker, options]) => {
      let bestOption;
      const selection = currentSelections[ticker];
      if (selection) {
        selectionsUsed = true;
        bestOption = options.find(opt => opt.Strike === selection.strike && opt.ExpirationDate === selection.expiry);
        if (bestOption) {
          const calculatedCapLevel = ((bestOption.Strike / bestOption.ClosePrice) * 100).toFixed(2);
          updateLocalFilters(ticker, calculatedCapLevel, selection.expiry);
        }
      } else {
        bestOption = findBestOption(options, newCapLevel, newExpDate, ticker);
      }
      if (bestOption) {
        newData[ticker] = [bestOption];
      }
    });
    setFilteredData(newData);

    if (selectionsUsed) {
      sessionStorage.removeItem('currentSelections');
      setCurrentSelections({});
    }
  };

  const updateLocalFilters = (ticker, newCapLevel, newExpDate) => {
    
    const existingFilters = localFilters[ticker] || {};
    console.log("00local existingFilters", existingFilters)
    const updatedFiltersForTicker = {
      ...existingFilters, // keep all existing filter properties
      capLevel: newCapLevel !== undefined ? newCapLevel : existingFilters.capLevel,
      expDate: newExpDate !== undefined ? newExpDate : existingFilters.expDate,
    };
  
    dispatch(setLocalFilters({
      ...localFilters,
      [ticker]: updatedFiltersForTicker
    }, tabType));
  };
  

  function transformPortfolioData(hedgeable) {
    return hedgeable.reduce((acc, item) => {
      acc[item.Ticker] = { quantity: item.Quantity, price: item.ClosePrice };
      return acc;
    }, {});
  }

  useEffect(() => {
    setisLoading(true);
    try {
      console.log("data", data)
      const relevantData = tabType === 'CallWriting' ? data.C : data.P;
      setOptionData(relevantData || {});
      const transformedData = transformPortfolioData(portfolioDetails.PortfolioLineItem.Hedgeable);
      setTransformedData(transformedData);
      handleGlobalChange(GlobalCapLevel, ExpDate);
      setisLoading(false);
    } catch (error) {
      console.error("Error loading options data:", error);
      setisLoading(false);
    }
  }, [tabType, data]);


  useEffect(() => {
    if (optionData) {
      filterData(GlobalCapLevel, ExpDate);
    }
  }, [optionData, GlobalCapLevel, ExpDate]);


  useEffect(() => {
    let newTotalPremium = 0;
    let newCartItems = [];

    Object.entries(FilteredData).forEach(([ticker, [bestOption]]) => {
      if (bestOption && selectedItems[ticker]) {
        const portfolioEntry = TransfomedData[ticker];
        const shares = portfolioEntry ? portfolioEntry.quantity : 0;
        const noOfContracts = localFilters[ticker]?.noOfContracts || Math.floor(shares / 100);
        // const noOfContracts = Math.floor(shares / 100);
        const premium = parseFloat(tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice) * 100 * noOfContracts;
        const formattedDate = formatDate(bestOption.ExpirationDate);
        const contractDisplay = `${ticker} ${formattedDate} $${bestOption.Strike}`;

        newCartItems.push({
          contract: contractDisplay,
          quantity: tabType === 'CallWriting' ? -noOfContracts : noOfContracts,
          premium: premium
        });

        newTotalPremium += premium;
      }
    });

    const cartItemsKey = tabType === 'CallWriting' ? 'callWritingCartItems' : 'putBuyingCartItems';
    sessionStorage.setItem(cartItemsKey, JSON.stringify(newCartItems));

    setTotalPremium(newTotalPremium);
    const totalValueKey = tabType === 'CallWriting' ? 'totalPremium' : 'totalCost';
    sessionStorage.setItem(totalValueKey, JSON.stringify(newTotalPremium));
  }, [FilteredData, selectedItems, TransfomedData, localFilters, portfolioId]);


  // useEffect(() => {
  //   console.log('localFilters', localFilters)
  //   const newTotalPremium = Object.entries(localFilters).reduce((acc, [ticker, { premium }]) => {
  //     console.log('premium', premium)
  //     return acc + (premium || 0);
  //   }, 0);
  //   setTotalPremium(newTotalPremium);
  // }, [localFilters]);


  if (isLoading) {
    return (
      <div className="loading-overlay">
        <div className="dot-flashing"></div>
      </div>
    );
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const handleGlobalFilterChange = (newCapLevel, newExpDate) => {
    const updatedCapLevel = newCapLevel ?? GlobalCapLevel;
    const updatedExpDate = newExpDate ?? ExpDate;

    dispatch(setGlobalCapLevel(updatedCapLevel, tabType));
    dispatch(setExpDate(updatedExpDate, tabType));
    filterData(updatedCapLevel, updatedExpDate);

    const newLocalFilters = Object.keys(localFilters).reduce((acc, ticker) => {
      acc[ticker] = {
        ...localFilters[ticker],
        capLevel: updatedCapLevel,
        expDate: updatedExpDate
      };
      return acc;
    }, {});

    dispatch(setLocalFilters(newLocalFilters, tabType));
  };

  function handleContractChange(ticker, newNoOfContracts, shares, price) {
    const newNoOfContractsInt = parseInt(newNoOfContracts, 10);
    if (isNaN(newNoOfContractsInt) || newNoOfContractsInt < 1) return; // Early return on invalid input

    // Assuming price is either CloseBidPrice or CloseAskPrice based on tabType
    const newPremium = price * 100 * newNoOfContractsInt;
    const newPremiumPercentage = (newPremium / (shares * price)) * 100; // Assuming price here is the same used for premium calculation

    // Update the state with the new number of contracts and the recalculated premium
    const newFiltersForTicker = {
      ...localFilters[ticker],
      noOfContracts: newNoOfContractsInt,
      premium: newPremium,
      premiumPercentage: newPremiumPercentage
    };
  
    dispatch(setLocalFilters({ [ticker]: newFiltersForTicker }, tabType));
  }

  function ColumnSelector({ onSelect, isVisible, toggleVisibility }) {
    const menuItemStyle = {
      cursor: 'pointer',
      padding: '8px',
      borderRadius: '4px'
    };

    const handleMouseEnter = (event) => {
      event.target.style.backgroundColor = '#f0f0f0'; // Light background on hover
      event.target.style.color = '#60034C'; // Change text color on hover
    };

    const handleMouseLeave = (event) => {
      event.target.style.backgroundColor = '#fff'; // Back to normal background
      event.target.style.color = 'black'; // Back to normal text color
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (isVisible && !event.target.closest('.dropdown-content')) {
          toggleVisibility(false);
        }
      };

      if (isVisible) {
        document.addEventListener('mousedown', handleClickOutside);
      }

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [isVisible, toggleVisibility]);

    const handleItemClick = (columnType) => {
      onSelect(columnType);
      toggleVisibility(false);
    };

    const dropdownStyle = {
      display: isVisible ? 'block' : 'none',
      position: 'absolute',
      zIndex: 100,
      backgroundColor: '#fff',
      boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
      padding: '12px',
      borderRadius: '4px',
      width: 'max-content'
    };

    return (
      <div className="dropdown-content" style={dropdownStyle}>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Bid')}>Bid</div>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Ask')}>Ask</div>
        <div style={menuItemStyle} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleItemClick('Bid/Ask')}>Bid/Ask</div>
      </div>
    );
  }

  return (
    <>
      {!isEmpty ? (
        <>
          {(!showDashboardSection) ? (
            <>
              {(!shoppingFlag) ? (
                <div className="dashboard-sec">
                  <div className="dashboard-inner">
                    <div className="dashboard-top">
                      <ul className="dash-list p0">
                        <li><h4>List of Symbols</h4></li>
                        <li>
                          <label>
                            {tabType === "CallWriting" ? "Global Cap Level" : "Protection Level"}
                            <input type="text" id="text" placeholder="90%" value={GlobalCapLevel} onChange={(e) => handleGlobalFilterChange(e.target.value, null)} />
                          </label>
                        </li>
                        <li>
                          <label>
                            Exp Date
                            <input type="date" id="date" placeholder="2023/12/22" value={ExpDate} onChange={(e) => handleGlobalFilterChange(null, e.target.value)} />
                          </label>
                        </li>
                        <li>
                          <p>Portfolio Holdings: <span className="txt-clr">{portfolioDetails.PortfolioLineItem.Hedgeable.length + portfolioDetails.PortfolioLineItem.Unhedgeable.length}</span></p>
                        </li>
                        <li>
                          <p>{tabType === 'CallWriting' ? "Available to write Calls:" : "Available to write Puts:"} <span className="txt-clr">{Object.keys(FilteredData).length}</span></p>
                        </li>
                        <li>
                          <p>Portfolio Value: <span className="txt-clr">${totalPortfolioValue.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</span></p>
                        </li>
                        <li>
                          <p>{tabType === 'CallWriting' ? "Premium Total:" : "Cost Total:"} <span className="txt-clr">${totalPremium.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ({(totalPremium / totalPortfolioValue * 100).toFixed(2)}%)</span></p>
                        </li>
                      </ul>
                    </div>

                    <div className="dashboard-table">
                      <div className="table-responsive">
                        <table className="table table-striped callwriting">
                          <thead>
                            <tr>
                              <th className="checkbox-column">
                                <input
                                  type="checkbox"
                                  checked={allSelected}
                                  onChange={handleSelectAllChange}
                                />
                              </th>
                              <th>SYMBOL <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                              <th>SHARES <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                              <th>no contracts</th>
                              <th>cap level <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                              <th>Exp date <img src={`${process.env.PUBLIC_URL}/Arrow-icon.svg`} alt="Arrow-icon" /></th>
                              <th className="hoverable-selector" style = {headingStyle} onMouseOver={() => setDropdownVisible(true)}>
                                <span className="selector">{priceToShow} <img src="Assets/c_down.svg" alt="toggle" /></span>
                                <ColumnSelector
                                  onSelect={changePriceToShow}
                                  isVisible={dropdownVisible}
                                  toggleVisibility={setDropdownVisible}
                                />
                              </th>
                              {/* <th>bid Price</th> */}
                              <th>{tabType === 'CallWriting' ? 'PREMIUM $' : 'COST $'}</th>
                              <th>% Stock Value</th>
                              <th>% Portfolio Value</th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            {Object.entries(FilteredData).map(([ticker, [bestOption]]) => {
                              const portfolioEntry = TransfomedData[ticker];
                              if (bestOption && portfolioEntry) {
                                const shares = portfolioEntry.quantity;
                                const noOfContracts = Math.floor(shares / 100);
                                let userEnteredContracts = localFilters[ticker]?.noOfContracts || noOfContracts;
                                userEnteredContracts = Math.max(1, Math.min(userEnteredContracts, noOfContracts));
                                var premium = 0;
                                //if local filter exists for this ticker, take the premium and premium percentage from there
                                premium = tabType === 'CallWriting' ? parseFloat(bestOption.CloseBidPrice) * 100 * userEnteredContracts : parseFloat(bestOption.CloseAskPrice) * 100 * userEnteredContracts;
                                const premiumPercentage = (premium / (shares * bestOption.ClosePrice)) * 100;
                                const portfolioPercentage = (premium / totalPortfolioValue) * 100;
                                const expirationDate = bestOption.ExpirationDate ? formatDateToYYYYMMDD(parseDate(bestOption.ExpirationDate)) : "";
                                return (
                                  <tr key={ticker}>
                                    <td className="checkbox-column">
                                      <input
                                        type="checkbox"
                                        checked={!!selectedItems[ticker]} // Use double bang to ensure boolean
                                        onChange={() => handleSelectionChange(ticker)}
                                      />
                                    </td>
                                    <td>{ticker}</td>
                                    <td>{shares}</td>
                                    <td>
                                      <input
                                        type="number"
                                        min="1"
                                        max={noOfContracts}
                                        value={userEnteredContracts}
                                        onChange={(e) => handleContractChange(
                                          ticker,
                                          e.target.value,
                                          shares,
                                          tabType === 'CallWriting' ? bestOption.CloseBidPrice : bestOption.CloseAskPrice
                                        )}
                                      />

                                    </td>
                                    <td>
                                      <input
                                        type="text"
                                        value={localFilters[ticker]?.capLevel || GlobalCapLevel}
                                        onChange={(e) => handleLocalFilterChange(ticker, e.target.value, null)}
                                      />
                                    </td>
                                    <td>
                                      <input
                                        type="date"
                                        value={expirationDate}
                                        onChange={(e) => handleLocalFilterChange(ticker, null, e.target.value)}
                                      />
                                    </td>
                                    <td>{
                                      priceToShow === 'Bid' ? parseFloat(bestOption.CloseBidPrice).toFixed(2) :
                                        priceToShow === 'Ask' ? parseFloat(bestOption.CloseAskPrice).toFixed(2) :
                                          `${parseFloat(bestOption.CloseBidPrice).toFixed(2)}/${parseFloat(bestOption.CloseAskPrice).toFixed(2)}`
                                    }</td>
                                    <td>{Number(addZeroes(Number(parseFloat(premium).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                    <td>{premiumPercentage.toFixed(3)}%</td>
                                    <td>{portfolioPercentage.toFixed(3)}%</td>
                                    <td>
                                      <button onClick={() => GoToOptionChainScreen(ticker)}>
                                        {tabType === 'CallWriting' ? 'Load Option Chain' : 'Put Option Chain'}
                                      </button>
                                    </td>
                                  </tr>
                                );
                              }
                              return null;
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="cart-btn">
                      <a href="#" onClick={(e) => {
                        e.preventDefault();
                        setShoppingFlag(true);
                      }}>
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                          {/* SVG content */}
                        </svg>
                        Add To Shopping Cart
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="shopping-cart">
                  <button className="ai-btn line secondary"
                    onClick={() => setShoppingFlag(false)}> Back </button>
                  <h3> Shopping Cart</h3>
                  <div className="shopping-card-container">
                    {shoppingFlag && <ShoppingCart />}
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              <DashboardSection
                portfolioDetails={portfolioDetails}
                tabType={tabType}
                ticker={selectedTicker}
              />
            </>
          )
          }
        </>) : (
        <div className="drawercontent">
          <p>Portfolio is Empty. Please add stock to proceed</p>
        </div>
      )}
    </>
  );
}

export default Dashboard;