import { CheckSessionTimeout } from "./CheckSessionTimeout";
export async function GetMarketShieldPlus(portfolio_id, protection_period) {

    const Token = sessionStorage.getItem("Token");
    const header = { Authorization: Token };

    var raw = JSON.stringify({
        session_id: Token,
        portfolio_id: portfolio_id,
        protection_period: protection_period

    });

    var requestOptions = {
        method: "POST",
        body: raw,
        headers: header,
        redirect: "follow"
    };
    if (!CheckSessionTimeout(Token)){
        try {
            const response = await fetch(
                process.env.REACT_APP_MARKETSHIELDPLUS_API,
                requestOptions
            );
            const message = await response.json();
            if (!response.ok) {
                return { status: response.status, error: message.error || message };
            } else {
                return { status: response.status, result: message.result };
            }
        } catch (error) {
            return { status: 0, error: error.message };
        }
    } else {
        return { status: 401 };
    }
}
