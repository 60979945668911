import React, { useEffect, useState, useRef } from 'react';
import { Redirect } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { BeatLoader, CircleLoader } from 'react-spinners';
import Loader from '../../Components/Loader/Loader';
import PortfolioDetails from './PortfolioDetails';
import './CustomerPortfolio.scss';
import { fetchPortfolio, togglePortfolioSelection, toggleClientSelection, selectPortfolio, selectClient } from '../../actions/portfolioActions';
import { openDrawer } from '../../actions/drawerActions';
import AddPortfolioDrawer from '../AddPortfolioDrawer/AddPorfolioDrawer';
import ClonePortfolio from '../ClonePortfolio/ClonePortfolio';
import Spinner from '../Spinner/Spinner';
import ModalPortfolio from '../ModalPortfolio/ModalPortfolio';
import EditPortfolio from '../EditPortfolio/EditPortfolio';
import AnalyticsDrawer from "../../Components/AnalyticsDrawer/AnalyticsDrawer";
import RecommendationDrawer from '../RecommendationDrawer/RecommendationDrawer';
import { fetchPortfolioDetailsIfNeeded } from '../../actions/portfolioDetailsActions';
// import { usePlaidLink } from "react-plaid-link";
// import { useCallback } from "react";
// import { fetchPlaidLinkToken, selectPlaidCustomer, sendPlaidAccessToken } from '../../actions/plaidActions';
// import PlaidImportDrawer from '../PlaidImportDrawer/PlaidImportDrawer';
// import { GetPlaidPublicToken } from "../../Apis/GetPlaidPublicToken";
// import { SendPlaidAccessToken } from "../../Apis/SendPlaidAccessToken";
import { addZeroes } from "../../Utilities/AddZeros";
// import Switch from '@mui/material/Switch';

const DRAWER_COMPONENTS = {
    AddNewPortfolio: AddPortfolioDrawer,
    Recommendation: RecommendationDrawer,
    ClonePortfolio: ClonePortfolio,
    ModalPortfolio: ModalPortfolio,
    EditPortfolio: EditPortfolio,
    Analytics: AnalyticsDrawer,
    // PlaidImport: PlaidImportDrawer,
};

function CustomerPortfolio() {
    const dispatch = useDispatch();
    const { customers, selectedPortfolioIds, isLoading, error, isEmpty, selectedClientIds , selectedScrollId} = useSelector(state => state.portfolio);
    const portfolioDetailsState = useSelector(state => state.portfolioDetails);
    const activeDrawer = useSelector(state => state.drawer.activeDrawer);
    const portfolioAdded = useSelector(state => state.portfolio.portfolioAdded);
    const DrawerComponent = DRAWER_COMPONENTS[activeDrawer];
    const modalRef = useRef();
    const subscriptionStatus = sessionStorage.getItem("subscriptionStatus");

    const [openPortfolios, setOpenPortfolios] = useState({});
    const [globalLoading, setGlobalLoading] = useState(true);
    const [pendingDrawer, setPendingDrawer] = useState(null);
    const [loading, setLoading] = useState({});
    // const plaidLink = useSelector(state => state.plaid.linkToken);
    // const plaidLoading = useSelector(state => state.plaid.isLoading);
    // const plaidError = useSelector(state => state.plaid.error);
    const [checkLoading, setCheckLoading] = useState(false);
    const [openClients, setOpenClients] = useState({});  

    const toggleClients = (CustomerId) => {
        setOpenClients(prevOpenClients => ({
            ...prevOpenClients,
            [CustomerId]: !prevOpenClients[CustomerId]
        }));
    };

    var currObj = {
        style: "currency",
        currency: "USD",
      };

    const toggleTable = (portfolioId) => {
        setOpenPortfolios(prevOpenPortfolios => ({
            ...prevOpenPortfolios,
            [portfolioId]: !prevOpenPortfolios[portfolioId]
        }));
    };
    // const onSuccess = useCallback((token, metadata) => {
    //     setCheckLoading(true)
    //     dispatch(sendPlaidAccessToken(token, showModal.clientUsername)).then(
    //         () => {
    //             setCheckLoading(false)
    //             console.log("accesstoken sent");
    //             if (plaidError == null) {
    //                 dispatch(openDrawer('PlaidImport', { clientUsername: showModal.clientUsername }));
    //             }
    //         }
    //     )
    // }, []);
    // const config = {
    //     token: plaidLink,
    //     onSuccess,
    // };
    // console.log(plaidLink)
    // useEffect(() => {
    //     if ((plaidLink === null) && (plaidLoading === false) && (plaidError === null)) {
    //         dispatch(fetchPlaidLinkToken())
    //     }
    // })

    // const { open, ready, plaid_error } = usePlaidLink(config);
    useEffect(() => {
        dispatch(fetchPortfolio()).finally(() => setGlobalLoading(false));
    }, [dispatch, portfolioAdded]);

    const handlePortfolioClick = (portfolioId,action,CustomerId) => {
        dispatch(togglePortfolioSelection(portfolioId,action));
        dispatch(fetchPortfolioDetailsIfNeeded(portfolioId));
        toggleTable(portfolioId);
        //dispatch(selectPortfolio(portfolioId,action));
        dispatch(selectClient(CustomerId));
    };

    const handleClientClick = (CustomerId) => {
        dispatch(toggleClientSelection(CustomerId,'toggle'));
        dispatch(selectClient(CustomerId));
        dispatch(selectPortfolio(null));
        //toggleClients(CustomerId);
    };

    if(selectedScrollId){
        let element = document.getElementById(selectedScrollId);
        if(element !== null){
            element.scrollIntoView({align:true,behavior: "smooth", block: "nearest", inline: "nearest"})
        }
    }


    useEffect(() => {
        if (pendingDrawer) {
            const details = portfolioDetailsState[pendingDrawer.portfolioId];
            if (details && !details.isLoading && !details.needsUpdate) {
                const isEmpty = !(details.details?.PortfolioLineItem?.Hedgeable?.length > 0);
                dispatch(openDrawer(pendingDrawer.type, {
                    portfolioId: pendingDrawer.portfolioId,
                    Name: pendingDrawer.name,
                    portfolio: pendingDrawer.portfolioName,
                    isEmpty: isEmpty
                }));
                setPendingDrawer(null);
            }
        }
    }, [portfolioDetailsState, pendingDrawer, dispatch]);

    const [showModal, setShowModal] = useState({
        visible: false,
        clientUsername: null,
        position: { x: 0, y: 0 }
    });

    const handleOpenModal = (e, clientUsername) => {
        setShowModal({
            visible: true,
            clientUsername: clientUsername,
            position: { x: e.clientX, y: e.clientY }
        });
    };

    useEffect(() => {
        function handleClickOutside(event) {
            if (modalRef.current && !modalRef.current.contains(event.target)) {
                setShowModal({ ...showModal, visible: false });
            }
        }
        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showModal, modalRef]);

    if (globalLoading) {
        return (
            <div className="global-loading-overlay">
                <Loader/>
            </div>
        );
    }

    console.log('subscriptionStatus', subscriptionStatus, isEmpty)


    if (subscriptionStatus !== 'active') {
        return <Redirect to={{ pathname: "/OnboardScreen", state: { stage: 1 } }} />;
    } else if (isEmpty) {
        return <Redirect to={{ pathname: "/OnboardScreen", state: { stage: 3 } }} />;
    }
    if (isLoading || checkLoading) {
        return (
            <div className="loading-overlay">
                <Loader/>
            </div>
        );
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const openDrawerWithCheck = (drawerType, portfolio, customer) => {

        if (activeDrawer === drawerType) {
            return;
        }
    
        const loadingKey = `${portfolio.PortfolioId}-${drawerType}`;
        const details = portfolioDetailsState[portfolio.PortfolioId];
    
        const startLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: true }));
        const stopLoading = () => setLoading(prev => ({ ...prev, [loadingKey]: false }));
    
        if (!details || details.needsUpdate || details.isLoading) {
            startLoading();
            dispatch(fetchPortfolioDetailsIfNeeded(portfolio.PortfolioId))
                .finally(stopLoading);
            setPendingDrawer({
                type: drawerType,
                portfolioId: portfolio.PortfolioId,
                name: `${customer.FirstName} ${customer.LastName}`,
                portfolioName: portfolio.PortfolioName
            });
        } else {
            const isEmpty = !(details.details?.PortfolioLineItem?.Hedgeable?.length > 0);
            dispatch(openDrawer(drawerType, {
                portfolioId: portfolio.PortfolioId,
                Name: `${customer.FirstName} ${customer.LastName}`,
                portfolio: portfolio.PortfolioName,
                isEmpty: isEmpty
            }));
        }
    };

    const handleDrawerClick = (drawer,portfolio,customer) =>{
        openDrawerWithCheck(drawer, portfolio, customer);
        handlePortfolioClick(portfolio.PortfolioId,'drawer');
        dispatch(selectClient(customer.CustomerId));
        //dispatch(selectPortfolio(portfolio.PortfolioId));
    }

    const handleCloneClick = (portfolio,customer) =>{
        dispatch(openDrawer('ClonePortfolio', { portfolioId: portfolio.PortfolioId, portfolio: portfolio.PortfolioName, customers: customers, client: customer.FirstName + ' ' + customer.LastName }))
        handlePortfolioClick(portfolio.PortfolioId,'drawer');
    }

    return (
        <div className="tb-container">
            <div className="customer-portfolio">
                {customers.map((customer, index) => (
                    <div id ={customer.CustomerId} key={customer.CustomerId} className={selectedClientIds.includes(customer.CustomerId) ? 'customer active' : 'customer'}>
                        <div className='portfoliohead'>
                            <span className='icon' onClick={() =>handleClientClick(customer.CustomerId)}>
                                {selectedClientIds.includes(customer.CustomerId) ? <img src="Assets/c_up.svg" alt="toggle" /> : <img src="Assets/c_down.svg" alt="toggle" />}
                            </span>
                            <h2>{customer.FirstName} {customer.LastName}</h2>
                            <div className="portfolio-button-modal-container">
                                {!customer.customer_has_schwab_master_account_no && (
                                    <button className='newportfolio' onClick={(e) => handleOpenModal(e, customer.Username)}>
                                        <img src="Assets/portfolio.svg" alt="toggle" /> Add New Portfolio
                                    </button>
                                )}

                                {showModal.visible && showModal.clientUsername === customer.Username && (
                                    <div
                                        ref={modalRef}
                                        className="portfolio-modal"
                                    >
                                        <button
                                            onClick={() => {
                                                dispatch(openDrawer('AddNewPortfolio', { clientUsername: showModal.clientUsername }));
                                            }}><span>Enter Manually</span> <img className="product_card_img" src='./Assets/Typing.svg'></img></button>
                                        <button
                                            onClick={() => {
                                                dispatch(openDrawer('ImportCSVPortfolioDrawer', { clientUsername: showModal.clientUsername, openedFromSidebar: false, customerId: customer.CustomerId }));
                                            }}><span>Import CSV</span> <img className="product_card_img" src='./Assets/CSV.svg'></img></button>
                                        <button
                                            onClick={() => {
                                                dispatch(openDrawer('ModalPortfolio', { clientUsername: showModal.clientUsername }));
                                            }}><span>Import Model Portfolio</span> <img className="product_card_img" src='./Assets/CSV.svg'></img></button>
                                        {/* <button
                                            onClick={() => {
                                                open()
                                                dispatch(selectPlaidCustomer(customer.Username));
                                            }} disabled={!ready}


                                        ><span>Import from Brokerage Account</span><img src="./Assets/Firm.svg" /></button> */}
                                    </div>
                                )}
                            </div>
                        </div>
                        {selectedClientIds.includes(customer.CustomerId) && (
                            <div className={selectedClientIds.includes(customer.CustomerId) ? 'portfolios' : 'portfolios'}>
                                {customer.Portfolio.map((portfolio) => (
                                    <>
                                        {selectedClientIds.includes(customer.CustomerId) && (
                                            <div id={portfolio.PortfolioId} className={openPortfolios[portfolio.PortfolioId] ? 'portfoliocard' : 'portfoliocard'} key={portfolio.PortfolioId}>
                                                <div className={openPortfolios[portfolio.PortfolioId] ? 'portlist active' : 'portlist'}>
                                                    <div className={openPortfolios[portfolio.PortfolioId] ? 'listhead' : 'listhead'}>
                                                        <h3 title={portfolio.PortfolioName}>
                                                            {portfolio.PortfolioName}
                                                        </h3>
                                                        <h3 className="portfolio-stocks">
                                                            HOLDINGS:{" "}
                                                            {portfolio.hFlag?portfolio.Unhedgable:portfolio.Hedgable}
        
                                                        </h3>
                                                        <h3 className="portfolio-value">
                                                            PORTFOLIO VALUE:{" "}
                                                            {Number(
                                                            addZeroes(
                                                                Number(
                                                                parseFloat(
                                                                    portfolio.TotalPortfolioValue ?? "0"
                                                                ).toFixed(2)
                                                                )
                                                            )
                                                            ).toLocaleString("en-US", currObj)}
                                                        </h3>
                                                        <div className='barbutton'>
                                                            <button title={selectedPortfolioIds.includes(portfolio.PortfolioId) ? '': 'Portfolio Analytics'} className="mhide secondary"
                                                                onClick={() => handleDrawerClick('Analytics', portfolio, customer)}>
                                                                {loading[`${portfolio.PortfolioId}-Analytics`] ? <Spinner /> : <img src="Assets/graph.svg" alt="" />}
                                                            </button>
                                                            <button title={selectedPortfolioIds.includes(portfolio.PortfolioId) ? '' :'Shield Recommendations'} className="mhide primary"
                                                                onClick={() => handleDrawerClick('Recommendation', portfolio, customer)}>
                                                                {loading[`${portfolio.PortfolioId}-Recommendation`] ? <Spinner /> : <img src="Assets/shield.svg" alt="" />}
                                                            </button>
                                                        </div>
                                                        <button className='icon' onClick={() => handlePortfolioClick(portfolio.PortfolioId,'toggle',customer.CustomerId)}>
                                                            {selectedPortfolioIds.includes(portfolio.PortfolioId) ? <img src="Assets/c_up.svg" alt="toggle" /> : <img src="Assets/c_down.svg" alt="toggle" />}
                                                        </button>
                                                    </div>
                                                    <div className='clientdata'>
                                                        {selectedPortfolioIds.includes(portfolio.PortfolioId) && <PortfolioDetails portfolioId={portfolio.PortfolioId} portfolioName={portfolio.PortfolioName} hasSchwabAccount={portfolio.HasSchwabAccountId} portfolio={portfolio} customer={customer} allCustomers={customers} hedgeableFlag={portfolio.hFlag} />}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                    </>
                                ))}

                            </div>
                        )}
                    </div>
                ))}
            </div>
            {DrawerComponent && <DrawerComponent />}
        </div>
    );
}

export default CustomerPortfolio;
