import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { closeDrawer, openDrawer } from '../../actions/drawerActions';
import ShieldRecommendation from '../ShieldRecommendation/ShieldRecommendation';
import Dashboard from '../Dashboard/Dashboard';
import Loader from '../../Components/Loader/Loader';
import Toaster from '../Toaster/Toaster';
import { fetchOptionsDataIfNeeded } from '../../actions/OptionDataAction';
import { togglePortfolioSelection } from '../../actions/portfolioActions';

function RecommendationDrawer() {

    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const { isLoading, error, data } = useSelector(state => state.options);
    const [activeTabLabel, setActiveTabLabel] = useState('Shield Recommendation');
    const portfolioDetails = useSelector(state => state.portfolioDetails[drawerData.portfolioId]?.details);
    const isPortfolioLoading = useSelector(state => state.portfolioDetails[drawerData.portfolioId]?.isLoading);
    const advisorProfile = useSelector(state => state.advisorProfile.profileData);

    useEffect(() => {
        if (portfolioDetails?.PortfolioLineItem?.Hedgeable) {
            const portfolioData = portfolioDetails.PortfolioLineItem.Hedgeable.map(item => ({
                ticker: item.Ticker,
                quantity: item.Quantity,
                price: item.ClosePrice || 0,
            }));
            dispatch(fetchOptionsDataIfNeeded(portfolioData, drawerData.portfolioId));
        }
    }, [dispatch, portfolioDetails, drawerData.portfolioId]); 
    
    const tabs = [
        { label: 'Shield Recommendation', key: 'ShieldRecommendation', component: ShieldRecommendation },
        { label: 'Call Writing', key: 'CallWriting', component: Dashboard },
        { label: 'Put Buying', key: 'PutBuying', component: Dashboard },
    ];

    const renderTabComponent = (tab) => {
        if (activeTabLabel === tab.label) {
            const Component = tab.component;
            return (
                <Component
                    portfolioId={drawerData.portfolioId}
                    isEmpty={drawerData.isEmpty}
                    portfolioDetails={portfolioDetails}
                    advisorProfile={advisorProfile}
                    tabType={tab.key}
                    optionsData={data}
                />
            );
        }
        return null;
    };

    const handleCloseDrawer = (portfolioId) => {
        dispatch(togglePortfolioSelection(portfolioId));
        dispatch(closeDrawer());
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
                {isLoading || isPortfolioLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                    <>
                        <div className="drawer-header">
                            <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                            <h1>{drawerData.Name} / {drawerData.portfolio}</h1>
                            <div className="drawerbottom actions">
                                <button className='ai-btn primary solid'
                                    onClick={() => dispatch(openDrawer('Analytics', { portfolioId: drawerData.portfolioId, Name: drawerData.Name, portfolio: drawerData.portfolio, isEmpty: drawerData.isEmpty }))}>Risk Analytics</button>
                                    <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>
                            </div>
                        </div>
                        <div className="tab">
                            {tabs.map(tab => (
                                <button key={tab.label} onClick={() => setActiveTabLabel(tab.label)} className={activeTabLabel === tab.label ? 'active' : ''}>
                                    {tab.label}
                                </button>
                            ))}
                        </div>
                        {tabs.map(tab => renderTabComponent(tab))}
                    </>
                )}
            </div>
        </>
    );
};

export default RecommendationDrawer;
