import React from "react";
import TagManager from "react-gtm-module";
import { addZeroes } from "../../Utilities/AddZeros";
import MediumFont from "../../fonts/Inter-Medium.woff";
import SemiBoldFont from "../../fonts/Inter-SemiBold.woff";
import BoldFont from "../../fonts/Inter-Bold.woff";
import RegularFont from "../../fonts/Inter-V.ttf";
import {
  Page,
  Text,
  Document,
  StyleSheet,
  PDFDownloadLink,
  pdf,
  Font,
  View,
  Image as PDFImage,
  Svg,
} from "@react-pdf/renderer";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import * as elements from "./PdfElements";
import { useSelector, useDispatch } from 'react-redux';

export default function PdfComponent(props) {
    const styles = StyleSheet.create({
      body: {
        paddingTop: 35,
        paddingBottom: 65,
        paddingHorizontal: 35,
      },
      image: {
        marginVertical: 10,
        textAlign: "left",
        height: 25,
        width: 120,
      },
      footer: {
        position: "absolute",
        fontSize: 8,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "left",
        color: "#3E4968",
        paddingLeft: 30,
        paddingRight: 30,
      }
    });

    const DownloadPdf = async () => {
        await new Promise(resolve => {
          const intervalId = setInterval(() => {
            if (props !== undefined) {
              clearInterval(intervalId);
              resolve();
            }
          }, 1000);  
        });

        const header = ReactDOMServer.renderToStaticMarkup(elements.headerElement(props.portfolioDetails.PortfolioName,props.portfolioDetails.PortfolioLineItem.Hedgeable.length, props.portfolioDetails.TotalPortfolioValue))
        const MyDocument = () => (
            <Document>
              <Page size='A3' style={styles.body} wrap>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.coverPageElement(props['advisorProfile'], props.portfolioDetails.PortfolioName))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              <Page size="A3" style={styles.body} wrap>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.RiskContributionGraphElement(props['Risk Contribution']))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              <Page size="A3" style={styles.body} wrap>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.RiskContributionTableElement(props['Risk Contribution']))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              <Page size="A3" style={styles.body} wrap>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.FactorAnalysisGraphElement(props['Factor Analysis']))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              <Page size="A3" style={styles.body}>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.BacktestGraphElement(props['Backtest']))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              {/* <Page size="A3" style={styles.body}>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.BacktestSimulationResultsElement(props['Backtest'].pdf))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
              <Page size="A3" style={styles.body}>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                <Html resetStyles>{header}</Html>
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.BacktestSimulationTableElement(props['Backtest'].pdf))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page> */}
              <Page size="A3" style={styles.body}>
                <PDFImage style={styles.image} src="Assets/adaptive-name.png" fixed />
                {/* <Html resetStyles>{header}</Html> */}
                <Html resetStyles>{ReactDOMServer.renderToStaticMarkup(elements.ForwardTestGraphElement(props['Forward Test']))}</Html>
                <Text style={styles.footer} fixed>{elements.footerText}</Text>
              </Page>
            </Document>
        );
        const blob = await pdf(<MyDocument />).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = 'Portfolio-Report.pdf';
        link.click();
        TagManager.dataLayer({
            dataLayer: {
            event: 'Analytics Report Downloaded',
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId")
            },
        });
    }

    return DownloadPdf;

}