import React from 'react';
import "./BackTestV2.scss";
import TooltipComponent from '../TooltipComponent/TooltipComponent';

function BacktestV2RiskTable({ rawData, showPortfolioShield }) {
    return (
        <div id='backtestriskreturnratio' style={{ textAlign: "center", padding: "20px 0" }}>
            <table className="risk-return-table">
                <thead>
                    <tr>
                        <th></th>
                        <th>Portfolio With Market Shield</th>

                        <th>Portfolio Without Shield</th>
                        {showPortfolioShield && <th>Portfolio with Portfolio Shield</th>}
                        <th>S&P 500</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>Annual Returns</td>
                        <td>{parseFloat(parseFloat(rawData['returns']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                        <td>{parseFloat(parseFloat(rawData['returns']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                        {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['returns']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                        <td>{parseFloat(parseFloat(rawData['returns']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                    </tr>

                    <tr>
                        <td>Downside Risk</td>
                        <td>{parseFloat(parseFloat(rawData['downside_risk']['market_shield']).toFixed(4) * 100).toFixed(2)}%</td>

                        <td>{parseFloat(parseFloat(rawData['downside_risk']['unshielded']).toFixed(4) * 100).toFixed(2)}%</td>
                        {showPortfolioShield && <td>{parseFloat(parseFloat(rawData['downside_risk']['shielded']).toFixed(4) * 100).toFixed(2)}%</td>}
                        <td>{parseFloat(parseFloat(rawData['downside_risk']['sp500']).toFixed(4) * 100).toFixed(2)}%</td>
                    </tr>
                    <tr>
                        <td>Return/Risk Ratio<TooltipComponent id={'Risk Return Ratio'} /></td>
                        <td>{parseFloat(rawData['return_ratio']['market_shielded_ratio']).toFixed(2)}</td>

                        <td>{parseFloat(rawData['return_ratio']['unshielded_ratio']).toFixed(2)}</td>
                        {showPortfolioShield && <td>{parseFloat(rawData['return_ratio']['shielded_ratio']).toFixed(2)}</td>}
                        <td>{parseFloat(rawData['return_ratio']['sp500_ratio']).toFixed(2)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
}

export default BacktestV2RiskTable;
