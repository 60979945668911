import React, { useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import Loader from '../../Components/Loader/Loader';
import Toaster from '../Toaster/Toaster';
import { handleApiError, showNotification } from '../../actions/notificationActions';
import { updateProfile } from '../../actions/advisorProfileActions';
import TagManager from 'react-gtm-module';

const UpdateProfile = () => {
    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const [editData, setEditData] = useState({})
    const [firstName, setFirstName] = useState(drawerData.firstName);
    const [lastName, setLastName] = useState(drawerData.lastName);
    const [email, setEmail] = useState(drawerData.emailid);
    const [companyName, setCompanyName] = useState(drawerData.companyname);
    const [contactNumber, setContactNumber] = useState(drawerData.contactno);
    const [crdNumber, setCrdNumber] = useState(drawerData.crd_number);
    const [isLoading,setIsLoading] = useState(false);

    const handleSubmit = async () => {
        setIsLoading(true)
        dispatch(updateProfile(editData)).then(
            (error) => {
                setIsLoading(false)
                if (error === null) {
                    dispatch(showNotification("Profile Updated Successfully","success","toaster"))
                    TagManager.dataLayer({
                        dataLayer: {
                          event: 'User Profile Updated',
                          userType: sessionStorage.getItem("selectedAcType"),
                          user_id: sessionStorage.getItem("clientId")
                        },
                      });
                    dispatch(closeDrawer())
                } else {
                    handleApiError(error, dispatch);
                }
            })
    };

    const handleInputChange = (e) => {
        setEditData({
            ...editData,
            [e.target.name]: e.target.value
        })
    }
    const handleCloseDrawer = (portfolioId) => {
        dispatch(closeDrawer());
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className='drawerbackdrop'></div>
            <div className="drawer popout">
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>
                ) : (
                    <>
                        <div className="drawer-header">
                            <h1>Edit Profile</h1>
                            <div className="actions">
                                <button className="close-drawer" onClick={() => handleCloseDrawer(drawerData.portfolioId)}>✕</button>
                            </div>
                        </div>
                        <div className='drawercontent'>
                            <div className='formdata'>
                                <div className='ai-form-group'>
                                    <label>First Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        name='firstname'
                                        placeholder="First Name"
                                        value={firstName}
                                        onChange={(e) => {
                                            setFirstName(e.target.value)
                                            handleInputChange(e)
                                        }
                                        }
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>Last Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Last Name"
                                        name='lastname'
                                        value={lastName}
                                        onChange={(e) => {
                                            setLastName(e.target.value)
                                            handleInputChange(e)
                                        }}
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>Company Name</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Company Name"
                                        name='companyname'
                                        value={companyName}
                                        onChange={(e) => {
                                            setCompanyName(e.target.value)
                                            handleInputChange(e)
                                        }
                                        }
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>Contact Number</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        placeholder="Contact Number"
                                        name='contactno'
                                        value={contactNumber}
                                        onChange={(e) => {
                                            setContactNumber(e.target.value)
                                            handleInputChange(e)
                                        }
                                        }
                                    />
                                </div>
                                <div className='ai-form-group'>
                                    <label>CRD Number</label>
                                    <input
                                        className='ai-form-value'
                                        type="text"
                                        name='crd_number'
                                        placeholder="CRD Number"
                                        value={crdNumber}
                                        onChange={(e) => {
                                            setCrdNumber(e.target.value)
                                            handleInputChange(e)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='drawerbottom actions'>
                                <button className='ai-btn red solid' onClick={() => dispatch(closeDrawer())}><img src="./Assets/close-icon.svg"/>Close</button>
                                <button className='ai-btn primary solid' onClick={handleSubmit}>Save</button>
                        </div>
                    </>
                )}
            </div>
        </>
    );
};

export default UpdateProfile;