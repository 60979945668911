import React, { useState } from 'react';
import { UpdatePortfolio } from '../../Apis/UpdatePortfolio';
import { useDispatch, useSelector } from 'react-redux';
import { closeDrawer } from '../../actions/drawerActions';
import { portfolioAdded } from '../../actions/portfolioActions';
import Loader from '../../Components/Loader/Loader';
import { fetchPortfolioDetails } from '../../actions/portfolioDetailsActions';
import { handleApiError } from '../../actions/notificationActions';
import Toaster from '../../Components/Toaster/Toaster';
import TagManager from "react-gtm-module";

const EditablePortfolio = () => {

    const dispatch = useDispatch();
    const drawerData = useSelector(state => state.drawer.data);
    const portfolioDetails = drawerData.portfolioDetails;
    const [isLoading, setIsLoading] = useState(false);
    const [portfolioName, setPortfolioName] = useState(portfolioDetails.PortfolioName);
    const [portfolioId, setPortfolioId] = useState(portfolioDetails.PortfolioId)
    const [portfolioNameError, setPortfolioNameError] = useState("");
    const [quantityError, setQuantityError] = useState([]);
    const [pliDataFlag, setPliDataFlag] = useState(true);
    const [pliData, setPliData] = useState([]);

    if (pliDataFlag && portfolioDetails?.PortfolioLineItem?.Hedgeable) {
        let lyst = [];
        if (Array.isArray(portfolioDetails.PortfolioLineItem.Hedgeable)) {
            for (const v of portfolioDetails.PortfolioLineItem.Hedgeable) {
                if (v?.Ticker && v?.Quantity) {
                    lyst.push({
                        'ticker': v.Ticker,
                        'quantity': v.Quantity,
                        'companyname': v.CompanyName
                    });
                }
            }
        }
        setPliData(lyst);
        setPliDataFlag(false);
    }

    const handleInputChange = (index, key, value) => {
        const newData = [...pliData];
        newData[index][key] = value;
        setPliData(newData);
    };

    const validateEditPortfolioName = () => {
        let validFlag = true;
        let error = [];
        let l = [];
        let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
        if (!portfolioName) {
            validFlag = false;
            error.portfolioName = "Please enter a portfolio name";
        }
        if (pliData.length !== 0) {
            pliData.map((row, index) => {
                if (!row.quantity) {
                    l[index] = "Please enter quantity";
                    validFlag = false;
                }
                if (!row.quantity && !pattern.test(row.quantity)) {
                    l[index] = "Please enter valid input";
                    validFlag = false;
                }
            })

        }
        setQuantityError(l);
        setPortfolioNameError(error.portfolioName);
        return validFlag
    }

    const editPortfolio = async () => {
        if (validateEditPortfolioName()) {
            setIsLoading(true);
            const payload = pliData.map(({ companyname, ...rest }) => rest);
            const response = await UpdatePortfolio(portfolioId, portfolioName, payload);
            if (response.status === 200) {
                    dispatch(portfolioAdded());
                    dispatch(fetchPortfolioDetails(portfolioId));
                    dispatch(closeDrawer());
                    TagManager.dataLayer({
                        dataLayer: {
                          event: 'Update Portfolio Details',
                          userType: sessionStorage.getItem("selectedAcType"),
                          user_id: sessionStorage.getItem("clientId")
                        },
                      });
            } else {
                handleApiError(response, dispatch);
            }
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="toast-container">
                <Toaster />
            </div>
            <div className="drawer">
                {isLoading ? (
                    <div className="loading-overlay">
                        <Loader/>
                    </div>) : (
                    <>
                        <div className="drawer-header">
                            <button className="ai-btn mr16" onClick={() => dispatch(closeDrawer())}><img src="./Assets/home.svg"/>Dashboard</button>
                            <h1>Edit Portfolio</h1>
                            <div className="actions">
                            <button className="close-drawer" onClick={() => dispatch(closeDrawer())}>✕</button>
                            </div>
                        </div>
                        <div className='drawercontent'>
                            <div className='portfolioname'>
                                <p>Portfolio Name</p>
                                <input
                                    className='ai-form-value'
                                    type="text"
                                    placeholder="Portfolio Name"
                                    value={portfolioName}
                                    onChange={(e) => setPortfolioName(e.target.value)}
                                />
                                <p className='ai-error-message'>{portfolioNameError}</p>
                            </div>

                            <div className='tickercon'>
                                <div className="editable-table">
                                    <table>
                                        <thead>
                                            <tr>
                                                <th>#</th>
                                                <th>Symbol</th>
                                                <th>Position Name</th>
                                                <th>Quantity</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pliData.length > 0 ? (
                                                pliData.map((row, index) => (
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {row.ticker}
                                                        </td>
                                                        <td>
                                                            {row.companyname}
                                                        </td>
                                                        <td>
                                                            {row.quantity}
                                                        </td>
                                                        <td>
                                                            <input
                                                                type="text"
                                                                value={row.quantity}
                                                                onChange={e => handleInputChange(index, 'quantity', e.target.value)}
                                                            />
                                                            <p className='ai-error-message'>{quantityError[index]}</p>
                                                        </td>
                                                    </tr>
                                                ))
                                            ) : (
                                                <tr>
                                                    <td colSpan="4">Portfolio is Empty</td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className='drawerbottom actions'>
                                <button className='ai-btn red solid' onClick={() => dispatch(closeDrawer())}>X Close</button>
                                <button className='ai-btn primary solid' onClick={editPortfolio}>Save</button>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </>
    )
};

export default EditablePortfolio;
