import React, { useState, useEffect, useRef } from 'react';
import styles from './DashboardSection.module.css';
import DataTable from '../OptionChainTable/OptionChainTable';
import { useSelector } from 'react-redux';
import { setContractData } from '../../actions/ContractDataActions';
import Dashboard from '../Dashboard/Dashboard';

function DashboardSection({ portfolioId, isEmpty, portfolioDetails, tabType, ticker }) {

    const { data: optionsData, isLoading, error } = useSelector(state => state.options);
    const optionData = tabType === 'CallWriting' ? optionsData.C : optionsData.P;

    const defaultTicker = optionData ? ticker : '';
    const [selectedTicker, setSelectedTicker] = useState(defaultTicker);
    const [expirationDates, setExpirationDates] = useState([]);
    const [selectedExpirationDate, setSelectedExpirationDate] = useState('');
    const [strikePrices, setStrikePrices] = useState([]);
    const [selectedStrikePrice, setSelectedStrikePrice] = useState('');
    const [totalPortfolioValue, setTotalPortfolioValue] = useState(0);
    const [tickerData, setTickerData] = useState({});
    const [premium, setPremium] = useState();
    const [premiumPercentage, setPremiumPercentage] = useState();
    const [noOfContracts, setNoOfContracts] = useState();
    const [showDashboard, setShowDashboard] = useState(false)

    const dataTableRef = useRef();
    const handleSaveSelection = () => {
        if (dataTableRef.current) {
            const selections = dataTableRef.current.getCurrentSelections();
            console.log("selections", selections);
            sessionStorage.setItem('currentSelections', JSON.stringify(selections));
            setShowDashboard(true);
        }
    };

    const handleClearSelection = () => {
        if (dataTableRef.current) {
            dataTableRef.current.clearCurrentSelections();
            sessionStorage.removeItem('currentSelections');
            setShowDashboard(true);
        }
    };

    const handleDropDownChange = (ticker, strike, expiry) => {
        const newTicker = ticker || selectedTicker;
        const newStrike = strike || selectedStrikePrice;
        const newExpiry = expiry || selectedExpirationDate;

        setSelectedTicker(newTicker);
        setSelectedStrikePrice(newStrike);
        setSelectedExpirationDate(newExpiry);
        const optionsForTicker = optionData[newTicker] || [];
        const matchingOption = optionsForTicker.find(option =>
            new Date(option.ExpirationDate).toDateString() === new Date(newExpiry).toDateString() &&
            parseFloat(option.Strike) === parseFloat(newStrike)
        );

        if (matchingOption) {
            console.log("matchingOption", matchingOption);
            const premium = parseFloat(matchingOption.CloseBidPrice * 100 * noOfContracts).toFixed(2);
            setPremium(premium);
            const premiumPercentage = (premium / totalPortfolioValue) * 100;
            setPremiumPercentage(premiumPercentage.toFixed(3));
        } else {
            setPremium(undefined);
            setPremiumPercentage(undefined);
        }
        if (tickerData && tickerData[newTicker]) {
            const noOfContracts = tickerData[newTicker].noOfContracts || 0;
            setNoOfContracts(noOfContracts);
        }
    };


    useEffect(() => {
        const savedTotalPortfolioValue = sessionStorage.getItem('totalPortfolioValue');
        const savedTickerData = sessionStorage.getItem('tickerData');
        console.log("PORTFOLIO DETAILS", portfolioDetails);
        if (savedTotalPortfolioValue) {
            setTotalPortfolioValue(JSON.parse(savedTotalPortfolioValue));
        }
        if (savedTickerData) {
            let data = JSON.parse(savedTickerData);
            setTickerData(data);
            setPremium(data[selectedTicker].premium);
            setPremiumPercentage((data[selectedTicker].premiumPercentage).toFixed(3));
            setNoOfContracts(data[selectedTicker].noOfContracts);
        }

    }, []);

    useEffect(() => {
        if (optionData) {
            const initialTicker = selectedTicker || ticker;
            setSelectedTicker(initialTicker);
            const tickerOptions = optionData[initialTicker];
            const uniqueDates = Array.from(new Set(tickerOptions.map(option => option.ExpirationDate))).sort((a, b) => new Date(a) - new Date(b));
            setExpirationDates(uniqueDates);
            const oneMonthFromNow = new Date(new Date().setMonth(new Date().getMonth() + 1));
            const closestDate = uniqueDates.reduce((prev, curr) =>
                Math.abs(new Date(curr) - oneMonthFromNow) < Math.abs(new Date(prev) - oneMonthFromNow) ? curr : prev
            );
            setSelectedExpirationDate(closestDate);
            const uniqueStrikes = Array.from(new Set(tickerOptions.map(option => option.Strike))).sort((a, b) => a - b);
            setStrikePrices(uniqueStrikes);
            const currentPrice = tickerOptions[0].ClosePrice;
            const aboveCurrentPriceStrikes = uniqueStrikes.filter(strike => strike > currentPrice);
            const closestStrike = aboveCurrentPriceStrikes.length > 0 ? aboveCurrentPriceStrikes.reduce((prev, curr) =>
                (!prev || curr < prev) ? curr : prev
            ) : uniqueStrikes[0];
            setSelectedStrikePrice(closestStrike);
            const selectedOption = tickerOptions.find(option => option.Strike === closestStrike && new Date(option.ExpirationDate).toDateString() === new Date(closestDate).toDateString());
            if (selectedOption) {
                const premium = parseFloat(selectedOption.CloseBidPrice * 100).toFixed(2);
                setPremium(premium);
            }
        }
    }, [selectedTicker, optionData]);

    const handleCellSelect = (strike, expiry, premium, premiumPercentage) => {
        setSelectedStrikePrice(strike);
        setSelectedExpirationDate(expiry);
        setPremium((premium * noOfContracts).toFixed(2));
        setPremiumPercentage((premiumPercentage * noOfContracts).toFixed(3));
    };

    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    const tickers = Object.keys(optionData);


    if (isLoading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }


    return (
        <>
            {(!showDashboard) ? (
                <div className={styles.dashboardSec}>
                    <div className={styles.dashboardInner}>
                        <div className={styles.callHeading}>
                            <h3>{tabType === 'CallWriting' ? 'Call Option Chain' : 'Put Option Chain'}</h3>
                        </div>
                        <div className={styles.dashboardTop}>
                            <ul className={styles.dashList}>
                                <li className={styles.formGroup}>
                                    <label htmlFor="ticker">Select Ticker</label>
                                    <select id="ticker" name="ticker" value={selectedTicker} onChange={(e) => handleDropDownChange(e.target.value, null, null)}>
                                        {tickers.map(ticker => (
                                            <option key={ticker} value={ticker}>{ticker}</option>
                                        ))}
                                    </select>
                                </li>
                                <li className={styles.formGroup}>
                                    <label htmlFor="date">Select Expiration</label>
                                    <select id="expirationDate" name="expirationDate" value={selectedExpirationDate} onChange={(e) => handleDropDownChange(null, null, e.target.value)}>
                                        {expirationDates.map(date => (
                                            <option key={date} value={date}>{new Date(date).toLocaleDateString()}</option>
                                        ))}
                                    </select>

                                </li>
                                <li className={styles.formGroup}>
                                    <label htmlFor="Strick">Select Strike</label>
                                    <select id="strikePrice" name="strikePrice" value={selectedStrikePrice} onChange={(e) => handleDropDownChange(null, e.target.value, null)}>
                                        {strikePrices.map(strike => (
                                            <option key={strike} value={strike}>{strike}</option>
                                        ))}
                                    </select>
                                </li>
                                <li className={styles.formGroupt}>
                                    <label htmlFor="contracts">No. of Contracts</label>
                                    <p id="contracts">{noOfContracts}</p>
                                </li>
                                <li className={styles.formGroupt}>
                                    <label htmlFor="premium">Premium</label>
                                    <p id="premium">${premium}</p>
                                </li>
                                <li className={styles.formGroupt}>
                                    <label htmlFor="percentPremium">% of Premium</label>
                                    <p id="percentPremium">{premiumPercentage}%</p>
                                </li>
                                <li className={styles.buttonGroup}>
                                    <button className={styles.saveBtn} onClick={handleSaveSelection}>Save Selection</button>
                                    <button className={styles.cancelBtn} onClick={handleClearSelection}>Cancel</button>
                                </li>
                            </ul>
                        </div>
                        <DataTable data={optionData[selectedTicker] || []} totalPortfolioValue={totalPortfolioValue} selectedStrike={selectedStrikePrice} selectedExpiry={selectedExpirationDate} ticker={selectedTicker} onCellSelect={handleCellSelect} ref={dataTableRef} />
                    </div>
                </div>) : (
                <Dashboard
                    portfolioDetails={portfolioDetails}
                    tabType={tabType}
                />
            )}
        </>
    );
};

export default DashboardSection;