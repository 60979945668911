// Loader.js
import React from 'react';
import './Loader.scss';

const Loader = () => {
  return (
    <div className="loader">
      <svg className="loader-svg" viewBox="0 0 100 100">
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" style={{ stopColor: "#60034c", stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: "#e58b76", stopOpacity: 1 }} />
          </linearGradient>
        </defs>
        <circle className="loader-circle" cx="50" cy="50" r="45" stroke-linecap="round" />
      </svg>
    </div>
  );
};

export default Loader;
