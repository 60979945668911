import React from "react";
import { addZeroes } from "../../Utilities/AddZeros";
import MediumFont from "../../fonts/Inter-Medium.ttf";
import SemiBoldFont from "../../fonts/Inter-SemiBold.ttf";
import BoldFont from "../../fonts/Inter-Bold.ttf";
import RegularFont from "../../fonts/Inter-Regular.ttf";
import Html from "react-pdf-html";
import ReactDOMServer from "react-dom/server";
import {
    Page,
    Text,
    Document,
    StyleSheet,
    PDFDownloadLink,
    pdf,
    Font,
    View,
    Image as PDFImage,
    Svg,
  } from "@react-pdf/renderer";
import { height } from "@mui/system";

    Font.register({ family: "Inter-Bold", src: BoldFont });
    Font.register({ family: "Inter-V", src: RegularFont });
    Font.register({ family: "Inter-SemiBold", src: SemiBoldFont });
    Font.register({ family: "Inter-Medium", src: MediumFont });

    var currObj = {
        style: "currency",
        currency: "USD",
    };

    const styles = StyleSheet.create({
        body: {
          paddingTop: 35,
          paddingBottom: 65,
          paddingHorizontal: 35,
        },
        image: {
          marginVertical: 15,
          textAlign: "left",
          height: 40,
          width: 150,
        },
        header: {
          fontSize: 12,
          marginBottom: 20,
          textAlign: "center",
          color: "grey",
        },
        footer: {
          position: "absolute",
          fontSize: 8,
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: "left",
          color: "#3E4968",
          paddingLeft: 30,
          paddingRight: 30,
        },
        summaryHeader: {
          color: "#3E4968",
          fontSize: 18,
          textAlign: "left",
          fontWeight: "bold",
          marginVertical: 15,
          fontFamily: "Inter-Bold",
        },
        tableheader: {
          backgroundColor: "#ffffff",
          borderRadius: 5,
          color: "#60034c",
          fontSize: 8,
          padding: 10,
          fontFamily: "Inter-Bold",
        },
    
        tablebody: {
          fontSize: 8,
          padding: 8,
          textAlign: "left",
          fontFamily: "Inter-V",
          color: '#333333',
          textTransform: 'capitalize',
        },
        tableRow: {
          flexDirection: "row",
          height: 25,
          '&:nthOfType(even)': {
            backgroundColor: '#d3d3d3',
          },
          textTransform: 'capitalize',
        },
    
        detailsHeader: {
          backgroundColor: 'rgba(96, 3, 76, 0.1)',
          padding: 10,
          color: "#60034c",
          fontSize: 10,
          fontFamily: "Inter-Bold",
        },
    
        summaryFooter: {
          backgroundColor: 'rgba(96, 3, 76, 0.1)',
          padding: 10,
          color: "#3E4968",
          fontSize: 8,
          fontFamily: "Inter-Bold",
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'align-items',
          alignItems: 'center',
        },
        portfolioinfoBar: {
          border: '0.5px solid #60034C',
          borderRadius: 5,
          padding: 10,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          fontFamily: "Inter-V",
          marginBottom: 20,
          fontSize: 8,
        },
    
        Footnotes: {
          fontSize: 8,
          color: "#60034C",
          textAlign: "left",
          fontFamily: "Inter-Medium",
          marginTop: 20,
          display: 'flex',
        },
        tagcontent:{
            fontSize: 12,
            color: "#000000",
            textAlign: "left",
            fontFamily: "Inter-Medium",
            flexDirection: 'row',
            display: 'flex',
        },
        tagimg:{
            height: "auto",
            height: 150,
        },
        tagtitle:{
            fontSize: 16,
            color: "#60034C",
            textAlign: "left",
            fontFamily: "Inter-Medium",
            marginTop: 20,
            marginBottom: 20,
            flexDirection: 'row',
            display: 'flex',
        },
        tagspan:{
            backgroundColor: 'rgba(96, 3, 76, 0.1)',
            padding: 20,
            color: "#60034c",
            fontSize: 10,
            fontFamily: "Inter-Bold",
            display: 'flex',
            flexDirection: 'row',
            columnGap: 32,
        },
        Row: {
          flexDirection: 'row',
          padding: 10,
        },
        RowContainer: {
          width: '50%',
          padding: 5,
        },
        content: {
          objectFit: 'cover',
          width: '100%',
          height: '100%',
        },
        pagetitle:{
            padding: 20,
            color: "#60034c",
            fontSize: 50,
            fontFamily: "Inter-Bold",
            display: 'flex',
            textAlign:"center",
            flexDirection: 'row',
            columnGap: 32,
            marginTop: 100,
            marginBottom: 50,
        },
        subtitlte:{
            padding: 20,
            color: "#000000",
            fontSize: 24,
            textAlign:"center",
            fontFamily: "Inter-Bold",
            display: 'flex',
            flexDirection: 'row',
            columnGap: 32,
            marginBottom: 50,
        },
        backtestEvent: {
            fontFamily: "Inter-V",
            fontSize: 8,
          },
      });

    const periods = [
        { period_int: 0, period_value: "Select"},
        { period_int: 30, period_value: "1 Month"},
        { period_int: 60, period_value: "2 Months"},
        { period_int: 91, period_value: "3 Months"},
        { period_int: 182, period_value: "6 Months"},
        { period_int: 365, period_value: "Year"},
        { period_int: 365*2, period_value: "2 Years"},
        { period_int: 365*3, period_value: "3 Years"},
        { period_int: 365*5, period_value: "5 Years"},
        { period_int: 365*10, period_value: "10 Years"},
    ];

    const levels = [
        { percent: 0, level: "Select" },
        { percent: 0.80, level: "80%" },
        { percent: 0.85, level: "85%" },
        { percent: 0.90, level: "90%" },
        { percent: 0.95, level: "95%" },
        { percent: 1.0, level: "100%" },
    ];

    const simulationPeriod = [
        { simulation_period: 0, simulation_value: "Select" },
        { simulation_period: 0.09, simulation_value: "1 Month" },
        { simulation_period: 0.17, simulation_value: "2 Months" },
        { simulation_period: 0.25, simulation_value: "3 Months" },
        { simulation_period: 0.5, simulation_value: "6 Months" },
        { simulation_period: 1, simulation_value: "1 Year" },
        { simulation_period: 2, simulation_value: "2 Years" },
        { simulation_period: 3, simulation_value: "3 Years" },
        { simulation_period: 4, simulation_value: "4 Years" },
        { simulation_period: 5, simulation_value: "5 Years" },
        { simulation_period: 6, simulation_value: "6 Years" },
        { simulation_period: 7, simulation_value: "7 Years" },
        { simulation_period: 8, simulation_value: "8 Years" },
        { simulation_period: 9, simulation_value: "9 Years" },
        { simulation_period: 10, simulation_value: "10 Years" },
    ];
    
    const NumberOfPaths = [
    { path_no: 0, path_value: "Select" },
    { path_no: 100, path_value: "100" },
    { path_no: 500, path_value: "500" },
    { path_no: 1000, path_value: "1,000" },
    { path_no: 2000, path_value: "2,000" },
    { path_no: 5000, path_value: "5,000" },
    { path_no: 10000, path_value: "10,000" }
    ];

    export function coverPageElement(props,portfolioName){
        let currentDate = new Date();
        return(
            <html>
                <body>
                    <div>
                        <h1 style={styles.pagetitle}>Adaptive Portfolio Report</h1>
                        <h2 style={styles.subtitlte}>Prepared for {`${props.firstname.toUpperCase()} ${props.lastname.toUpperCase()}, ${props.companyname}`}</h2>
                        <h2 style={styles.subtitlte}>Report As Of Date : {`${currentDate.toLocaleString('default', { month: 'long' })} ${currentDate.getDate()}, ${currentDate.getFullYear()}`}</h2>
                        <h2 style={styles.subtitlte}>Portfolio: {portfolioName.toUpperCase()}</h2>
                    </div>
                </body>
            </html>
        )
    }
    
    export function headerElement(portfolioName,totalPortfolioHoldings,totalPortfolioValue){
        return (
            <html>
                <body>
                    <div style={styles.portfolioinfoBar}>
                        <p>Portfolio: {portfolioName} </p>
                        <p>Holdings: {totalPortfolioHoldings}</p>
                        <p>Portfolio Value: ${" "}{Number(addZeroes(Number(parseFloat(totalPortfolioValue).toFixed(2)))).toLocaleString()}</p>
                    </div>
                </body>
            </html>
    )}

    export function RiskContributionGraphElement(props){
        return (
            <html>
                {props.dataFlag === "Data Available" ? (
                    <body>
                        <div>
                                <img src={props.pdf.activeTab === 'Risk Contribution' && props.pdf.riskContriImgData} />
                        </div>
                    </body>
                ) : (
                    <body>
                        <div>
                            <div style={styles.detailsHeader}>
                                <p>Risk Contribution</p>
                            </div>
                            <div>
                                <h4 style={styles.tagcontent}>Risk Contribution data not available.</h4>
                            </div>
                        </div>
                    </body>
                )}
            </html>
        )
    }

    export function RiskContributionTableElement(props){
        const totalSums = props.pdf.riskContributionTableData.reduce((acc, curr) => {
            acc.marketValue += curr.market_value;
            acc.marketWeight += (curr.market_value / props.pdf.totalPortfolioValue) * 100;
            acc.marketContribution += curr.market_contribution;
            acc.idiosyncraticContribution += curr.idiosyncratic_portion;
            acc.totalRiskContribution += curr.risk_contributions;
            acc.diversifiedRisk += curr.isolated_risk;
            return acc;
          }, { marketValue: 0, marketWeight: 0, marketContribution: 0, idiosyncraticContribution: 0, totalRiskContribution: 0, diversifiedRisk: 0 });

        return (
            <html>
                {props.dataFlag === "Data Available" ? (
                <body>
                    <div>
                        <div style={styles.detailsHeader}>
                            <p>Portfolio Details</p>
                        </div>
                        <table>
                            <thead style={styles.tableheader}>
                            <tr>
                                {props.pdf.activeTab === 'Risk Contribution' && props.pdf.risk_contribution_headers.map((col,index)=>(
                                <th style={col==='MARKET VALUE' ? {paddingLeft: 10} : {}} key={index}>{col}</th>
                                ))}
                            </tr>
                            </thead>
                            <tbody style={styles.tablebody}>
                            {props.pdf.activeTab === 'Risk Contribution' && props.pdf.riskContributionTableData.map((row, index) => (
                                <tr key={index} style={styles.tableRow}>
                                    <td>{row.sr}</td>
                                    <td>{row.symbol}</td>
                                    <td>{row.position_name}</td>
                                    <td>{(row.stock_volatility).toFixed(2)}</td>
                                    <td>{Number(addZeroes(Number(parseFloat(row.market_value).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                    <td>{((row.market_value / props.pdf.marketRisk) * 100).toFixed(2)}%</td>
                                    <td>{row.market_contribution}</td>
                                    <td>{row.idiosyncratic_portion}</td>
                                    <td>{row.risk_contributions}</td>
                                    <td>{row.isolated_risk}</td>
                               </tr>
                            ))}
                            </tbody>
                        </table>
                        <div style={styles.summaryFooter}>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 0}}>Portfolio Total: </p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>${" "}{Number(addZeroes(Number(parseFloat(props.pdf.totalPortfolioValue).toFixed(2)))).toLocaleString()}</p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>100 %</p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>{totalSums.marketContribution.toFixed(3)}</p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>{totalSums.idiosyncraticContribution.toFixed(4)}</p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>{Number(parseFloat(props.pdf.portfolioRisk).toFixed(3))}</p>
                            <p style={{ fontSize: 8, color: '#60034C', marginLeft: 68}}>{totalSums.diversifiedRisk.toFixed(3)}</p>
                            {/* <p style={{ fontSize: 8, color: '#60034C', marginLeft: 60}}>{props.pdf.activeTab === 'Risk Contribution' && props.pdf.totalIndividualRiskContribution.toFixed(2)}%</p> */}
                        </div>
                        <div style={styles.Footnotes}>
                            <p style={{ fontSize: 8, color: '#60034C' }}>Risk analysis as of market close for previous trading day.</p>
                            <p style={{ fontSize: 8, color: '#60034C' }}>Portfolio Risk - Downside volatility of a portfolio as implied by options markets.</p>
                            <p style={{ fontSize: 8, color: '#60034C' }}>Risk Contribution - Percentage of total portfolio risk attributable to individual holdings.</p>
                            <p style={{ fontSize: 8, color: '#60034C' }}>Position Risk - Downside volatility of an individual holding as implied by option prices.</p>
                        </div>
                    </div>
                </body>
                ) : (
                <body>
                    <div>
                        <div>
                        <div style={styles.detailsHeader}>
                            <p>Risk Contribution</p>
                        </div>
                        </div>
                        <div>
                            <h4 style={styles.tagcontent}>Risk Contribution data not available.</h4>
                        </div>
                    </div>
                </body>
                )}
            </html>
        )
    }

    export function FactorAnalysisGraphElement(props){
        if(props.dataFlag === "Data Available"){
            const updatedData = props.pdf.activeTab === 'Factor Analysis' ? Object.entries(props.pdf.factorAnalysisTable).map(([key, value]) => {
                const updatedValue = Object.entries(value).reduce((acc, [k, v]) => {
                  const newKey = k === "imp_vol" ? "Implied Volatility" : k === "hist_vol" ? "Historical Volatility" : k === "symbol" ? "Symbol" : k;
                  if (newKey === "Implied Volatility" || newKey === "Historical Volatility") {
                    v = v === "N.A." ? "N.A." : (parseFloat(v) * 100).toFixed(2) + "%";
                  }
                  return { ...acc, [newKey]: v };
                }, {});
                const newValue = { Symbol: value.Symbol || props.portfolioName, ...updatedValue }
                return { [key]: newValue };
            }):"";
            
            const uniqueKeys = props.pdf.activeTab === 'Factor Analysis' ? Array.from(new Set(updatedData.flatMap(obj => Object.keys(obj[Object.keys(obj)[0]])))):"";
            return (    
                <html>
                    <body>
                        <style></style>
                        <div style={styles.detailsHeader}>
                            <p>Benchmark & Factor Analysis</p>
                        </div>
                        <div style={styles.Row}>
                            <div style={styles.RowContainer}>
                                <div className='trackingerror'>
                                <h3 style={styles.tagtitle}>Benchmark Portfolio</h3>
                                {/* <div style={styles.tagimg}>
                                    <img src={props.activeTab === 'Factor Analysis' && props.benchmarkImgData}/>
                                </div> */}
                                <div className='errolist'>
                                    <div style={styles.tagspan}>
                                        <h4 style={styles.tagcontent}>R-Squared</h4>
                                        <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.benchmarkData[0]}</span>
                                    </div>
                                    <div style={styles.tagspan}>
                                        <h4 style={styles.tagcontent}>Tracking Error</h4>
                                        <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.benchmarkData[1]}</span>
                                    </div>
                                    <div style={styles.tagspan}>
                                        <h4 style={styles.tagcontent}>Beta</h4>
                                        <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.benchmarkData[2]}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                            <div style={styles.RowContainer}>
                                <div className='trackingerror'>
                                <h3 style={styles.tagtitle}>Factor Portfolio</h3>
                                {/* <div id='factGraph' style={styles.tagimg}>
                                    <img src={props.activeTab === 'Factor Analysis' && props.factorImgData}/>
                                </div> */}
                                <div className='errolist'>
                                    <div style={styles.tagspan}>
                                    <h4 style={styles.tagcontent}>R-Squared</h4>
                                    <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.factorData[0]}</span>
                                    </div>
                                    <div style={styles.tagspan}>
                                    <h4 style={styles.tagcontent}>Tracking Error</h4>
                                    <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.factorData[1]}</span>
                                    </div>
                                    <div style={styles.tagspan}>
                                    <h4 style={styles.tagcontent}>Beta</h4>
                                    <span style={styles.tagcontent}>{props.pdf.activeTab === 'Factor Analysis' && props.pdf.factorData[2]}</span>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                        <div style={styles.Row}>
                            <div style={styles.RowContainer}>
                                <div style={styles.detailsHeader}>
                                <p>Factor Analysis</p>
                                </div>
                                <table>
                                    <thead style={styles.tableheader}>
                                        <tr>
                                        {props.pdf.activeTab === 'Factor Analysis' && props.pdf.factorAnalysisHeaders.map((col,index) => (
                                        <th key={index}>{col}</th>
                                        ))}
                                        </tr>
                                    </thead>
                                    <tbody style={styles.tablebody}>
                                    {props.pdf.activeTab === 'Factor Analysis' && uniqueKeys.map((key, index) => (
                                        <tr key={index} style={styles.tableRow}>
                                        <td>{key}</td>
                                        {updatedData.map((obj, i) => {
                                        const subObj = obj[Object.keys(obj)[0]];
                                        return <td key={i}>{subObj[key]}</td>;
                                        })}
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={styles.RowContainer}>
                                <div style={styles.detailsHeader}>
                                    <p>Factor</p>
                                </div>
                                <table>
                                    <thead style={styles.tableheader}>
                                        <tr>
                                        {props.pdf.activeTab === 'Factor Analysis' && props.pdf.factor_headers.map((col,index) => (
                                            <th key={index}>{col}</th>
                                        ))}
                                        </tr>
                                    </thead>
                                    <tbody style={styles.tablebody}>
                                        {props.pdf.activeTab === 'Factor Analysis' && Object.values(props.pdf.factorTable).map((item, index) => (
                                        <tr key={index} style={styles.tableRow}>
                                        <td>{index + 1}</td>
                                            <td>{item.StockTicker}</td>
                                            <td>{(parseFloat(item.Weight) * 100).toFixed(2) + "%"}</td>
                                            <td>{item.Description}</td>
                                        </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </body>
                </html>
            )
        } else{
            return(
                <html>
                    <body>
                        <div style={styles.detailsHeader}>
                            <p>Benchmark & Factor Analysis</p>
                        </div>
                        <div><h4 style={styles.tagcontent}>Factor Analysis data not available.</h4></div>
                    </body>
                </html>
            )
        }
        
        
    }

    export function PortfolioDetailsElement(props){
        const portfolioDetailsTableHeaders = [
            { title: "#" },
            { title: "SYMBOL" },
            { title: "POSITION NAME" },
            { title: "QUANTITY", tooltip: "Number of shares held for each stock" },
            { title: "CLOSING PRICE", tooltip: "Price of a security at the EOD previous market close" },
            { title: "MARKET VALUE", tooltip: "Market value of each security based on last price" },
            { title: "WEIGHT" }
        ];

        return (
            <html>
                <body>
                    <div>
                        <div style={styles.detailsHeader}>
                        <p>Portfolio Details</p> 
                        </div>
                        <table>
                        <thead style={styles.tableheader}>
                            <tr>
                                {portfolioDetailsTableHeaders.map((col,index) => (
                                <th key={index}>{col.title}</th>
                                ))}
                            </tr>
                        </thead>
                        <tbody style={styles.tablebody}>
                            {(props && props.PortfolioLineItem && Array.isArray(props.PortfolioLineItem.Hedgeable) && props.PortfolioLineItem.Hedgeable.length > 0) && props.PortfolioLineItem.Hedgeable.map((item, index) => (
                            <tr key={index} style={styles.tableRow}>
                                <td>{index + 1}</td>
                                <td>{item.Ticker}</td>
                                <td>{item.CompanyName}</td>
                                <td>{item.Quantity}</td>
                                <td style = {{paddingLeft: 10}}>{Number(addZeroes(Number(parseFloat(item["ClosePrice"]).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                <td>{Number(addZeroes(Number(parseFloat(item["TotalValue"]).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
                                <td>{(parseFloat(item["TotalValue"]) / props.TotalPortfolioValue * 100).toFixed(2)}%</td>
                            </tr>
                            ))}
                        </tbody>
                        </table>
                        <div style={styles.summaryFooter}>
                        <p>Portfolio Total: </p>
                        {/* <p style={{ fontSize: 8, color: '#60034C', marginLeft: 475}}>100%</p> */}
                        <p style={{ fontSize: 8, color: '#60034C', marginLeft: 480}}>${" "}{Number(addZeroes(Number(parseFloat(props.TotalPortfolioValue).toFixed(2)))).toLocaleString()}</p>
                        </div>
                    </div>
                </body>
            </html>
        )
    }

    export function BacktestGraphElement(props){
        return (
            <html>
                {props.dataFlag === 'Data Available' ?(
                    <body>
                        <div style={styles.detailsHeader}>
                            <p> Shield Back Test </p>
                        </div>
                        <br/>
                            <p style={styles.backtestEvent}>{props.allData.event?`Event: ${props.allData.event}`:''}</p>
                        <br/>
                        <div style={styles.portfolioinfoBar}>
                            <p>Protection Level: {levels.filter(level=>level.percent === props.allData.btlevel).map(level => level.level)}</p>
                            <p>Protection Period: {periods.filter(period => period.period_int === props.allData.period).map(period => period.period_value)} </p>
                            <p>Start Date: {props.allData.startDate.toISOString().split("T")[0].toString()}</p>
                            <p>End Date:{props.allData.endDate.toISOString().split("T")[0].toString()} </p>
                        </div>
                        <br/><br/>
                        <div>
                            <img src={props.pdf.activeTab === 'Backtest' && props.pdf.backTestImgData} />
                        </div>
                    </body>
                ): (
                    <body>
                        <div style={styles.detailsHeader}>
                            <p> Shield Back Test </p>
                        </div>
                        <div><h4 style={styles.tagcontent}>Backtest data not available.</h4></div>
                    </body>
                )}
            </html>
        )
    }

    // export function BacktestSimulationResultsElement(props){
    //     const ResultsTableHeaders = [
    //         { title: "Date" },
    //         { title: "Shield Cost($)" },
    //         { title: "Compounded Shield Cost($)" },
    //         { title: "Shield Payout($)" },
    //         { title: "Compounded Shield Payout($)" },
    //         { title: "Portfolio Value($)" },
    //         { title: "Shield Cost(%)" },
    //         { title: "Payout(%)" },
    //         { title: "Protection Level" },
    //         { title: "Portfolio Implied Downside Volatility (without Shield)" },
    //         { title: "Portfolio Implied Downside Volatility(with Shield)" },
    //     ];

    //     return(
    //         <html>
    //             <body>
    //                 <div>
    //                     <div style={styles.detailsHeader}>
    //                     <p>Simulation Results</p>
    //                     </div>
    //                     <table>
    //                     <thead style={styles.tableheader}>
    //                         <tr>
    //                         {props.pdf.activeTab === 'Backtest' && ResultsTableHeaders.map((col,index) =>(
    //                             <th key={index}>{col.title}</th>
    //                         ))}
    //                         </tr>
    //                     </thead>
    //                     <tbody style={styles.tablebody}>
    //                         {props.pdf.activeTab === 'Backtest' && props.pdf.simulationDetailsTable.map((value, index) => (
    //                             <tr key={index} style={styles.tableRow}>
    //                                 <td>{value.date}</td>
    //                                 <td>{Number(addZeroes(Number(parseFloat(value.shield_cost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                                 <td>{Number(addZeroes(Number(parseFloat(value.compounded_shield_cost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                                 <td>{Number(addZeroes(Number(parseFloat(value.shield_payoff).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                                 <td>{Number(addZeroes(Number(parseFloat(value.compounded_shield_payoff).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                                 <td>{Number(addZeroes(Number(parseFloat(value.portfolio_value).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                                 <td>{parseFloat(value.shield_cost_percent * 100).toFixed(2)}%</td>
    //                                 <td>{parseFloat(value.shield_payoff / value.protected_value * 100).toFixed(2)}%</td>
    //                                 <td>{value.protection_level}</td>
    //                                 <td>{(parseFloat(value.portfolio_implied_downside_volatility_without_shield)*100).toFixed(2)}%</td>
    //                                 <td>{(parseFloat(value.portfolio_implied_downside_volatility_with_shield)*100).toFixed(2)}%</td>
    //                             </tr>
    //                         ))}
    //                     </tbody>
    //                     </table>
    //                 </div>
    //             </body>
    //         </html>
    //     )
    // }

    // export function BacktestSimulationTableElement(props){
    //     const SimulationDetailsTableHeaders = [
    //         { title: "Date" },
    //         { title: "Shield Cost($)" },
    //         { title: "Shield Payout" },
    //         { title: "Portfolio Value" },
    //         { title: "Protection Level" },
    //         { title: "Protected Value" },
    //         { title: "Portfolio Value At Shield Expiration" },
    //     ];
    //     return(
    //         <html>
    //             <body>
    //                 <div>
    //                     <div style={styles.detailsHeader}>
    //                     <p>Simulation Details</p>
    //                     </div>
    //                     <table>
    //                     <thead style={styles.tableheader}>
    //                         <tr>
    //                         {props.pdf.activeTab === 'Backtest' && SimulationDetailsTableHeaders.map((col,index) =>(
    //                             <th key={index}>{col.title}</th>
    //                         ))}
    //                         </tr>
    //                     </thead>
    //                     <tbody style={styles.tablebody}>
    //                         {props.pdf.activeTab === 'Backtest' && props.pdf.simulationDetailsTable.map((value, index) => (
    //                         <tr key={index} style={styles.tableRow}>
    //                             <td>{value.date}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.shield_cost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.shield_payoff).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.portfolio_value).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{value.protection_level}%</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.protected_value * value.protection_level / 100).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.simulation_end_portfolio_values).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                         </tr>
    //                         ))}
    //                     </tbody>
    //                     </table>
    //                 </div>
    //             </body>
    //         </html>
    //     )
    // }

    export function ForwardTestGraphElement(props){
        return(
            <html>
                {props.dataFlag === 'Data Available' ? (
                    <body>
                        <div style={styles.detailsHeader}>
                            <p> Shield Forward Test </p>
                        </div>
                        <br/>
                        <div style={styles.portfolioinfoBar}>
                            <p>Simulation Length: {simulationPeriod.filter(simulation => simulation.simulation_period === props.allData.simulation).map(simulation => simulation.simulation_value)}</p>
                            <p>Protection Period: {periods.filter(period => period.period_int === props.allData.period).map(period => period.period_value)} </p>
                            <p>Protection Level: {levels.filter(level =>level.percent === props.allData.level).map(level => level.level)}</p>
                            <p>Number of Paths: {NumberOfPaths.filter(path=>path.path_no === props.allData.path).map(path=>path.path_value)} </p>
                        </div>
                        <div style={styles.detailsHeader}>
                            <p>Relative Performance</p>
                        </div>
                        <div>
                            <img src={props.pdf.activeTab === 'Forward Test' && props.pdf.relativePerformanceReturnImgData}/>    
                        </div>
                    </body>
                ): (
                    <body>
                        <div style={styles.detailsHeader}>
                            <p> Shield Forward Test </p>
                        </div>
                        <div><h4 style={styles.tagcontent}>Forward Test data not available.</h4></div>
                    </body>
                )}
            </html>
        )
    }

    // export function ForwardTestSimulationTableElement(props){
    //     return(
    //         <html>
    //             <body>
    //                 <div>
    //                     <div style={styles.detailsHeader}>
    //                     <p>Simulation Details</p>
    //                     </div>
    //                     <table>
    //                     <thead style={styles.tableheader}>
    //                         <tr>
    //                         {props.pdf.activeTab === 'Forward Test' && props.pdf.simulationDetailsTableHeaders.map((col,index) =>(
    //                             <th key={index}>{col.title}</th>
    //                         ))}
    //                         </tr>
    //                     </thead>
    //                     <tbody style={styles.tablebody}>
    //                         {props.pdf.activeTab === 'Forward Test' && props.pdf.simulationDetailsTableData.map((value, index) => (
    //                         <tr key={index} style={styles.tableRow}>
    //                             <td>{value.path_number + 1}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.portfolio_value).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.shielded_portfolio_value).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.total_shield_cost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.total_shield_payoff).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.average_shield_cost).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{Number(addZeroes(Number(parseFloat(value.average_shield_payoff).toFixed(2)))).toLocaleString("en-US", currObj)}</td>
    //                             <td>{parseFloat(value.percentage_shield_cost).toFixed(2)}%</td>
    //                             <td>{parseFloat(value.percentage_shield_payoff).toFixed(2)}%</td>
    //                         </tr>
    //                         ))}
    //                     </tbody>
    //                     </table>
    //                 </div>
    //             </body>
    //         </html>
    //     )
    // }

    export const footerText = 'Adaptive funds are not held in a brokerage account and are not protected by SIPC. Brokerage assets are held by Adaptive Investment Services, a division of Adaptive Investments LLC.';

    