export const TOGGLE_ITEM_SELECTION = 'TOGGLE_ITEM_SELECTION';
export const CLEAR_SELECTIONS = 'CLEAR_SELECTIONS';
export const SET_ALL_SELECTED = 'SET_ALL_SELECTED';
export const INITIALIZE_SELECTIONS = 'INITIALIZE_SELECTIONS';
export const SET_GLOBAL_CAP_LEVEL = 'SET_GLOBAL_CAP_LEVEL';
export const SET_EXP_DATE = 'SET_EXP_DATE';
export const SET_LOCAL_FILTERS = 'SET_LOCAL_FILTERS';
export const RESET_ALL_DASHBOARDS = 'RESET_ALL_DASHBOARDS';

export const toggleItemSelection = (ticker, isSelected, tabType) => ({
    type: TOGGLE_ITEM_SELECTION,
    payload: { ticker, isSelected, tabType }
});

export const clearSelections = (tabType) => ({
    type: CLEAR_SELECTIONS,
    payload: { tabType }
});

export const setAllSelected = (isSelected, tabType) => ({
    type: SET_ALL_SELECTED,
    payload: { isSelected, tabType }
});

export const initializeSelections = (initialData, tabType) => ({
    type: INITIALIZE_SELECTIONS,
    payload: { initialData, tabType }
});

export const setGlobalCapLevel = (level, tabType) => ({
    type: SET_GLOBAL_CAP_LEVEL,
    payload: { level, tabType }
});

export const setExpDate = (date, tabType) => ({
    type: SET_EXP_DATE,
    payload: { date, tabType }
});

export const setLocalFilters = (filters, tabType) => ({
    type: SET_LOCAL_FILTERS,
    payload: { filters, tabType }
});

export const resetAllDashboards = () => ({
    type: RESET_ALL_DASHBOARDS
});
