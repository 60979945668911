import React, { useState } from "react";
import Loader from "../../Components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import { closeDrawer } from "../../actions/drawerActions";
import { portfolioAdded } from "../../actions/portfolioActions";
import { AddClientPortfolio } from "../../Apis/AddClientPortfolio";
import { handleApiError } from "../../actions/notificationActions";
import Toaster from "../../Components/Toaster/Toaster";
import TagManager from "react-gtm-module";

function AddPortfolioDrawer() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [portfolioName, setPortfolioName] = useState("");
  const [lineItems, setLineItems] = useState([{ symbol: "", quantity: "" }]);
  const drawerData = useSelector((state) => state.drawer.data);
  const [PliError, setPliError] = useState([{ symbol: "", quantity: "" }]);
  const [portfolioNameError, setPortfolioNameError] = useState("");
  const allAvailableTickers = useSelector(
    (state) => state.availableTickers.availableTickers
  );

  const handleLineItemChange = (e, index, field) => {
    const newLineItems = [...lineItems];
    newLineItems[index][field] = e.target.value.toUpperCase();
    setLineItems(newLineItems);
  };

  const checkTickerRecognition = (tickerData) => {
    const symbolsEntered = tickerData.map((ticker) => ticker.symbol);
    console.log("symbolsEntered", symbolsEntered);
    let error = [...PliError];
    const validTIckersData = tickerData.map((tickerObj, index) => {
      const referenceTicker = allAvailableTickers.find(
        (refTicker) => refTicker.symbol === tickerObj.symbol
      );
      console.log(" ref ticker", referenceTicker);
      if (!referenceTicker) {
        error[index]["symbol"] = "Ticker not recognized";
        error[index]["quantity"] = "";
      } else if (symbolsEntered.indexOf(tickerObj.symbol) !== index) {
        error[index]["symbol"] = "This ticker has already been added";
        error[index]["quantity"] = "";
      } else if (
        tickerObj.symbol.trim() !== "" &&
        tickerObj.quantity.trim() === ""
      ) {
        error[index]["quantity"] = "Please add quantity";
        error[index]["symbol"] = "";
      } else {
        error[index]["symbol"] = "";
        error[index]["quantity"] = "";
      }
      setPliError(error);
    });
  };

  const handleAddLineItem = () => {
    checkTickerRecognition([...lineItems]);
    setLineItems([...lineItems, { symbol: "", quantity: "" }]);
    setPliError([...PliError, { symbol: "", quantity: "" }]);
  };

  const handleDeleteLineItem = (index) => {
    const newLineItems = [...lineItems];
    newLineItems.splice(index, 1);
    setLineItems(newLineItems);
    const newPliError = [...PliError];
    newPliError.splice(index, 1);
    setPliError(newPliError);
  };

  const validatePortfolio = () => {
    let validFlag = true;
    let error = [...PliError];
    let pattern = new RegExp(/^-?[0-9]\d*\.?\d*$/);
    if (!portfolioName) {
      validFlag = false;
      setPortfolioNameError("Please enter a portfolio name");
    }
    if (lineItems.length !== 0) {
      lineItems.map((row, index) => {
        if (!row.symbol) {
          error[index]["symbol"] = "Please enter ticker";
          validFlag = false;
        } else {
          const referenceTicker = allAvailableTickers.find(
            (refTicker) => refTicker.symbol === row.symbol
          );
          if (row.symbol && !/^[A-Za-z\-.$^&]+$/.test(row.symbol)) {
            error[index]["symbol"] = "Invalid ticker";
            validFlag = false;
          } else if (!referenceTicker) {
            error[index]["symbol"] = "Ticker not recognized";
            validFlag = true;
          } else {
            error[index]["symbol"] = "";
          }
        }

        if (!row.quantity) {
          error[index]["quantity"] = "Please enter quantity";
          validFlag = false;
        } else {
          if (row.quantity && !pattern.test(row.quantity)) {
            error[index]["quantity"] = "Please enter valid quantity";
            validFlag = false;
          } else {
            error[index]["quantity"] = "";
          }
        }
      });
    }
    setPliError(error);
    return validFlag;
  };

  const handleSubmit = async () => {
    if (validatePortfolio()) {
      setIsLoading(true);
      const response = await AddClientPortfolio(
        drawerData.clientUsername,
        portfolioName,
        lineItems.map((item) => ({
          ticker: item.symbol,
          quantity: item.quantity,
        }))
      );
      if (response.status === 200) {
        dispatch(portfolioAdded());
        dispatch(closeDrawer());
        TagManager.dataLayer({
          dataLayer: {
            event: "Add Portfolio",
            userType: sessionStorage.getItem("selectedAcType"),
            user_id: sessionStorage.getItem("clientId"),
            creation_method: "Manually",
          },
        });
      } else {
        handleApiError(response, dispatch);
      }
      setIsLoading(false);
    }
  };

  return (
    <>
      <div className="toast-container">
        <Toaster />
      </div>
      <div className="drawer">
        {isLoading ? (
          <div className="loading-overlay">
            <Loader />
          </div>
        ) : (
          <>
            <div className="drawer-header">
              <button
                className="ai-btn mr16"
                onClick={() => dispatch(closeDrawer())}
              >
                Dashboard
              </button>
              <h1>Add Portfolio</h1>
              <div className="actions"></div>
            </div>
            <div className="drawercontent">
              <div className="portfolioname">
                <p>Portfolio Name</p>
                <input
                  className="ai-form-value"
                  type="text"
                  placeholder="Portfolio Name"
                  value={portfolioName}
                  onChange={(e) => setPortfolioName(e.target.value)}
                />
                <p className="ai-error-message">{portfolioNameError}</p>
              </div>
              <div className="tickercon">
                <div className="tickerlist">
                  {lineItems.map((lineItem, index) => (
                    <div key={index} className="addticker">
                      <div className="tickerdata">
                        <input
                          className="ai-form-value"
                          type="text"
                          placeholder="Symbol"
                          value={lineItem.symbol}
                          onChange={(e) =>
                            handleLineItemChange(e, index, "symbol")
                          }
                        />
                        <p className="ai-error-message">
                          {PliError[index]["symbol"]}
                        </p>
                      </div>
                      <div className="tickerdata">
                        <input
                          className="ai-form-value"
                          type="text"
                          placeholder="Quantity"
                          value={lineItem.quantity}
                          onChange={(e) =>
                            handleLineItemChange(e, index, "quantity")
                          }
                        />
                        <p className="ai-error-message">
                          {PliError[index]["quantity"]}
                        </p>
                      </div>
                      <button onClick={() => handleDeleteLineItem(index)}>
                        <img src="./Assets/trash.svg" alt="toggle" />
                      </button>
                    </div>
                  ))}
                </div>
                <span className="addticker">
                  <button onClick={handleAddLineItem}>+</button>
                </span>
              </div>
              <div className="drawerbottom mt32">
                <button className="ai-btn primary solid" onClick={handleSubmit}>
                  Save
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}

export default AddPortfolioDrawer;
